import { SspState } from './SspState';

export const defaultSspSliceState = {
    buyersState: {},
    sellersState: {},
    outcomeState: {
        output: '',
        message: '',
    },
} as SspState;
