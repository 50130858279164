import { ObjectState } from '../ObjectState';
import { AvailabilityStatus } from './AvailabilityStatus';
import { DailyLogEntry } from './DailyLogEntry';
import { DailyLogType } from './DailyLogType';
import { DailyLog } from './DailyLog';
import { Daypart } from './Daypart';
import { IdGenerator } from './IdGenerator';
import { ParseExtension } from './extensions/ParseExtension';
import { StringsExtension } from './extensions/StringsExtension';

export class DailyLogsParser {
    private excelOneSecondValue: number = 0.000011574074074074073;
    private dateMarker: string = 'Date';
    private hourMarker: string = 'Hour';
    private networkNameMarker: string = 'Network';
    private daypartMarker: string = 'Daypart';
    private hoursStartMarker: string = 'Hours Start';
    private hoursEndMarker: string = 'Hours End';
    private noMarker: string = 'No.';
    private typeMarker: string = 'Type';
    private offsetMarker: string = 'Offset';
    private lengthMarker: string = 'Length';
    private nameMarker: string = 'Name';
    private inventoryCodeMarker: string = 'Inventory Code';
    private statusMarker: string = 'Status';
    private stringsExtension = new StringsExtension();
    private parseExtensions = new ParseExtension(this.cells);

    constructor(private cells: (string | number)[][]) {}

    parse(): DailyLog {
        const networkName = this.parseExtensions
            .getHeadValue(this.networkNameMarker)
            .toString();
        const daypart: Daypart = this.stringsExtension.stringsEqual(
            this.parseExtensions.getHeadValue(this.daypartMarker),
            'Daytime'
        )
            ? Daypart.Daytime
            : Daypart.Unknown;
        const records = this.parseLogRecords(this.cells);
        const result: DailyLog = {
            id: IdGenerator.generate(),
            networkName,
            daypart,
            hoursStart: this.parseExtensions.parseHoursStartEnd(
                this.parseExtensions.getHeadValue(this.hoursStartMarker)
            ),
            hoursEnd: this.parseExtensions.parseHoursStartEnd(
                this.parseExtensions.getHeadValue(this.hoursEndMarker)
            ),
            dateStart: records.dateStart,
            dateEnd: records.dateEnd,
            logs: records.logs,
            objectState: ObjectState.Added,
        };
        return result;
    }

    private parseLogRecords(rows: (string | number)[][]): {
        dateStart: string;
        dateEnd: string;
        logs: DailyLogEntry[];
    } {
        const headers = [
            this.dateMarker,
            this.hourMarker,
            this.noMarker,
            this.typeMarker,
            this.offsetMarker,
            this.lengthMarker,
            this.nameMarker,
            this.inventoryCodeMarker,
            this.statusMarker,
        ];

        const firstRowIndex = rows.findIndex((cells) => {
            return !this.parseExtensions
                .getIndexes(headers, cells)
                .includes(-1);
        });
        if (firstRowIndex < 0) {
            return { dateStart: '', dateEnd: '', logs: [] };
        }
        const result: DailyLogEntry[] = [];

        let indexes = this.parseExtensions.getIndexes(
            headers,
            rows[firstRowIndex]
        );

        for (let i = firstRowIndex + 1; i < rows.length; i++) {
            const row = rows[i];
            if (row.length === 0) {
                continue;
            }
            const newIndexes = this.parseExtensions.getIndexes(headers, row);
            if (!newIndexes.includes(-1)) {
                indexes = newIndexes;
                continue;
            }
            const date: string = this.parseDate(
                row[indexes[headers.indexOf(this.dateMarker)]]
            );
            const hourBreak: string =
                row[indexes[headers.indexOf(this.hourMarker)]]
                    ?.toString()
                    .trim() ?? '';
            const no = parseFloat(
                row[indexes[headers.indexOf(this.noMarker)]].toString()
            );
            const inventoryCode =
                row[indexes[headers.indexOf(this.inventoryCodeMarker)]]
                    ?.toString()
                    .trim() ?? '';
            const name =
                row[indexes[headers.indexOf(this.nameMarker)]]
                    ?.toString()
                    .trim() ?? '';
            const status = this.parseAvailabilityStatus(
                row[indexes[headers.indexOf(this.statusMarker)]]
                    ?.toString()
                    .trim() ?? ''
            );
            const logType = this.parseDailyLogType(
                row[indexes[headers.indexOf(this.typeMarker)]]
                    ?.toString()
                    .trim() ?? ''
            );
            const offset = this.parseTimeLengthAsString(
                row[indexes[headers.indexOf(this.offsetMarker)]]?.toString()
            );
            const length = this.parseTimeLength(
                row[indexes[headers.indexOf(this.lengthMarker)]]
            );
            const log: DailyLogEntry = {
                date,
                no,
                hourBreak,
                inventoryCode,
                name,
                offset,
                length,
                logType,
                status,
            };
            result.push(log);
        }

        const convertMonthDayYearToYearMonthDay = (date: string) => {
            const parts = date.split('/');
            if (parts.length === 3) {
                return `${parts[2]}/${parts[0]}/${parts[1]}`;
            }
            return parts.join('/');
        };

        const convertYearMonthDayToMonthDayYear = (date: string) => {
            const parts = date.split('/');
            if (parts.length === 3) {
                return `${parts[1]}/${parts[2]}/${parts[0]}`;
            }
            return parts.join('/');
        };

        const sortedDates = Array.from(
            new Set<string>(result.map((r) => r.date))
        )
            .filter((r) => r !== '')
            .map((r) => convertMonthDayYearToYearMonthDay(r))
            .sort();
        const dateStart = convertYearMonthDayToMonthDayYear(
            sortedDates?.[0]?.toString()
        );
        const dateEnd =
            sortedDates?.length > 0
                ? convertYearMonthDayToMonthDayYear(
                      sortedDates?.[sortedDates.length - 1]?.toString()
                  )
                : '';
        return { dateStart: dateStart, dateEnd: dateEnd, logs: result };
    }

    private parseTimeLengthAsString(value:string | undefined): number {
        if (typeof value !== 'string') {
            return 0;
        }
        let timeParts = value
            .trim()
            .split(':')
            .filter((value) => value !== '')
            .map((value) => parseInt(value));
        while(timeParts.length<3){
            timeParts=[0,...timeParts];
        }
        if (timeParts.length > 3 || timeParts.includes(NaN)) {
            throw new Error(
                `Cannot convert value '${value}' to the time duration in seconds.`
            );
        }
        const secondsMultiplier = [1, 60, 3600];
        const result = timeParts
            .reverse()
            .reduce(
                (
                    previousValue: number,
                    currentValue: number,
                    currentIndex: number
                ) =>
                    currentValue * secondsMultiplier[currentIndex] +
                    previousValue
            );
        return result;
    }
    private parseTimeLength(value: number | string | undefined): number {
        if (typeof value === 'number') {
            return Math.round(value / this.excelOneSecondValue);
        }
        return this.parseTimeLengthAsString(value);
    }

    private parseAvailabilityStatus(
        value: number | string | undefined
    ): AvailabilityStatus {
        if (this.stringsExtension.stringsEqual(value, 'Available')) {
            return AvailabilityStatus.Available;
        }
        if (this.stringsExtension.stringsEqual(value, 'Not Available')) {
            return AvailabilityStatus.NotAvailable;
        }
        if ('' === (value?.toString() ?? '').trim()) {
            return AvailabilityStatus.Unspecified;
        }
        return AvailabilityStatus.Unknown;
    }

    private parseDailyLogType(
        value: number | string | undefined
    ): DailyLogType {
        if (this.stringsExtension.stringsEqual(value, 'AD')) {
            return DailyLogType.Ad;
        }
        if (
            this.stringsExtension.stringsEqual(value, 'BRK') ||
            this.stringsExtension.stringsEqual(value, 'BREAK')
        ) {
            return DailyLogType.Break;
        }

        return DailyLogType.Unknown;
    }

    private parseDate(value: number | string): string {
        if (typeof value === 'number') {
            const serial = value;
            const utcDays = Math.floor(serial - 25569);
            const utcValue = utcDays * 86400;
            const dateInfo = new Date(utcValue * 1000);

            const fractionalDay = serial - Math.floor(serial) + 0.0000001;

            let totalSeconds = Math.floor(86400 * fractionalDay);

            const seconds = totalSeconds % 60;

            totalSeconds -= seconds;

            const year = dateInfo.getFullYear();
            const month = dateInfo.getMonth() + 1;
            const day = dateInfo.getDate();
            return `${month.toString().padStart(2, '0')}/${day}/${year}`;
        }
        if (typeof value === 'string') {
            return value.trim();
        }
        return '';
    }
}
