import { AvailabilityStatus } from '../AvailabilityStatus';
import { AvailabilityStatusResponse } from '../contracts/seller/AvailabilityStatusResponse';

export class AvailabilityStatusResponseMapper {
    mapEnumAvailabilityStatusResponse = (
        availabilityStatusResponse: AvailabilityStatusResponse
    ): AvailabilityStatus => {
        switch (availabilityStatusResponse) {
            case AvailabilityStatusResponse.NotAvailable:
                return AvailabilityStatus.NotAvailable;
            case AvailabilityStatusResponse.Available:
                return AvailabilityStatus.Available;
            case AvailabilityStatusResponse.Unspecified:
                return AvailabilityStatus.Unspecified;
            default:
                return AvailabilityStatus.Unknown;
        }
    };
}
