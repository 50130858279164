import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    addSeller,
    importDailyLogs,
    selectSspStatus,
    selectSspSellers,
    deleteDailyLog,
} from '../../features/ssp/slice-extensions/ssp-slice';
import { DailyLog } from '../../features/ssp/DailyLog';
import { LoadingStatus } from '../../features/ssp/LoadingStatus';
import { UserRoles } from '../../features/authentication/UserRoles';
import RequireRoles from '../../components/require-roles/RequireRoles';
import UploadDailyLogs from '../../components/upload-daily-logs/UploadDailyLogs';
import SellerCancelSaveButtons from '../../components/seller-cancel-save-buttons/SellerCancelSaveButtons';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectAuthenticatedRole } from '../../features/authentication/authenticationSlice';
import { loadSellersStateAsync } from '../../features/ssp/slice-extensions/sellers/seller-state-slice-extensions';
import { ObjectState } from '../../features/ObjectState';
import { SellerForm } from '../../components/seller-form/SellerForm';
import { Box, Button, Chip, Typography, useMediaQuery } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const SellerHome = () => {
    const sellersState = useAppSelector(selectSspSellers);
    const sspStatus = useAppSelector(selectSspStatus);
    const dispatch = useAppDispatch();
    const params = useParams();
    const role = useAppSelector(selectAuthenticatedRole);
    const isMobile = useMediaQuery('(max-width:920px)');

    useEffect(() => {
        if (
            role === UserRoles.Admin &&
            params.id !== '' &&
            typeof params.id !== 'undefined'
        ) {
            dispatch(loadSellersStateAsync(params.id));
        }
    }, [dispatch, role, params.id]);

    const isDisabled = sspStatus === LoadingStatus.Loading;
    const handleUploadDailyLogs = (dailyLogs: DailyLog, sellerId: number) => {
        dispatch(importDailyLogs({ dailyLogs, sellerId }));
    };
    const addSellerClicked = () => {
        dispatch(addSeller({}));
    };

    const getDatesRange = (startDate: string, endDate: string): string => {
        if (startDate === endDate) {
            return startDate;
        }
        if (startDate === '' || endDate === '') {
            return `${startDate}${endDate}`;
        }
        return `${startDate} - ${endDate}`;
    };

    const handleDeleteDailyLog = (id: number) => {
        dispatch(deleteDailyLog({ id }));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
            }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 2,
                }}>
                <Typography
                    variant="h4"
                    component="h1"
                    sx={{ fontSize: (theme) => theme.spacing(4) }}>
                    DenaliTV Ad Supply Optimization
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    {typeof params.id === 'undefined' && (
                        <RequireRoles roles={UserRoles.Admin}>
                            <Button
                                sx={{
                                    fontWeight: 600,
                                    fontSize: (theme) => theme.spacing(2),
                                }}
                                onClick={addSellerClicked}
                                disabled={
                                    sellersState.individualSellers?.length >=
                                        5 || isDisabled
                                }
                                startIcon={<AddCircleOutlineIcon />}>
                                Add New Seller
                            </Button>
                        </RequireRoles>
                    )}
                </Box>
            </Box>
            {!sellersState.individualSellers?.length && (
                <Typography variant="h5" sx={{ my: 4 }}>
                    No sellers available
                </Typography>
            )}
            {sellersState.individualSellers?.map(
                (individualSeller, sellerIndex) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                        key={sellerIndex}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: isMobile ? 'column' : 'row',
                            }}>
                            {' '}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    '& .MuiButtonBase-root': {
                                        ml: 0,
                                        padding: (theme) =>
                                            `${theme.spacing(
                                                1.25
                                            )} ${theme.spacing(1)}`,
                                        fontSize: (theme) => theme.spacing(2),
                                        fontWeight: 600,
                                        lineHeight: (theme) => theme.spacing(2),
                                        height: (theme) => theme.spacing(4.5),
                                        display: 'flex',
                                        gap: 1,
                                    },
                                    '& .MuiButton-icon': {
                                        mr: 0,
                                        ml: 0,
                                    },
                                }}>
                                <Typography variant="h5">
                                    {individualSeller?.seller?.name}
                                </Typography>
                                <UploadDailyLogs
                                    onUploaded={(dailyLogs) => {
                                        handleUploadDailyLogs(
                                            dailyLogs,
                                            individualSeller?.seller?.id
                                        );
                                    }}
                                    disabled={
                                        isDisabled
                                    }></UploadDailyLogs>{' '}
                            </Box>
                            {sellerIndex === 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}>
                                    <SellerCancelSaveButtons />
                                </Box>
                            )}
                        </Box>
                        <Box>
                            {individualSeller.dailyLogsList?.filter(
                                (dailyLog) =>
                                    dailyLog.objectState !== ObjectState.Deleted
                            ).length > 0 && (
                                <Box>
                                    Daily logs:{' '}
                                    {individualSeller.dailyLogsList
                                        .filter(
                                            (dailyLog) =>
                                                dailyLog.objectState !==
                                                ObjectState.Deleted
                                        )
                                        .map((dailyLog, index) => (
                                            <Chip
                                                key={index}
                                                deleteIcon={
                                                    <HighlightOffIcon />
                                                }
                                                label={`${dailyLog.networkName} 
                                            ${getDatesRange(
                                                dailyLog.dateStart,
                                                dailyLog.dateEnd
                                            )}, 
                                            ${dailyLog.hoursStart} - ${
                                                    dailyLog.hoursEnd
                                                }`}
                                                sx={{
                                                    mb: isMobile ? 0.5 : 0,
                                                    ml: 1,
                                                    background: (theme) =>
                                                        theme.palette.background
                                                            .paper,
                                                }}
                                                onDelete={() =>
                                                    handleDeleteDailyLog(
                                                        dailyLog.id
                                                    )
                                                }
                                            />
                                        ))} 
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Box>
                                {!individualSeller?.priceFloors.length && (
                                    <Box
                                        sx={{
                                            background: (theme) =>
                                                theme.palette.background.paper,
                                            p: 2,
                                            borderRadius: 2,
                                            mt: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: (theme) =>
                                                theme.spacing(1.75),
                                            height: (theme) =>
                                                theme.spacing(13),
                                            gap: 2,
                                            opacity: 0.75,
                                        }}>
                                        Upload a daily log to enter price floors
                                        and save
                                    </Box>
                                )}
                                <SellerForm
                                    individualSeller={
                                        individualSeller
                                    }></SellerForm>
                            </Box>
                        </Box>
                    </Box>
                )
            )}
            {sellersState.individualSellers?.length > 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                    <SellerCancelSaveButtons />
                </Box>
            )}
        </Box>
    );
};

export default SellerHome;
