import { API_BASE_URL } from '../../app/config';
import { StringsExtension } from '../ssp/extensions/StringsExtension';
import AuthenticationResponse from './authenticationReponse';

export function authenticate(email: string, password: string) {
    return new Promise<{ data: string }>(async (resolve, reject) => {
        try {
            const stringsExtensions = new StringsExtension();
            const authenticationRequest = {
                email: stringsExtensions.trimWhiteSpaces(email),
                password,
            };
            const response = await fetch(
                `${API_BASE_URL}/api/v1/user-accounts/token`,
                {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(authenticationRequest),
                }
            );

            if (response.ok) {
                const data: AuthenticationResponse = await response.json();
                resolve({ data: data.token });
            } else {
                reject();
            }
        } catch (error) {
            reject(error);
        }
    });
}
