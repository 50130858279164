import { Box, useMediaQuery } from '@mui/material';
import { OutcomeSummaryDemand } from '../../features/ssp/OutcomeSummaryDemand';
import CampaignSummaryBox from './CampaignSummaryBox';
import { StringsExtension } from '../../features/ssp/extensions/StringsExtension';
import { OutcomeSummaryDemandRow } from '../../features/ssp/OutcomeSummaryDemandRow';

interface CampaignSummaryBoxesProps {
    outcomeSummaryDemand: OutcomeSummaryDemand;
    campaignId: number;
}

interface CampaignSummaryData {
    title: string;
    value: number;
    subTitleValue: number | undefined;
    valueInBrackets?: number;
    subTitleType: TitleType;
    valueType: TitleType;
    isValueSignVisible: boolean;
    valueInBracketsType: TitleType;
    isArrowVisible: boolean;
}

export enum TitleType {
    Number,
    Percent,
    Text,
    Decimal,
}

const CampaignSummaryBoxes = (props: CampaignSummaryBoxesProps) => {
    const stringsExtension = new StringsExtension();

    const getFilteredRows = () => {
        const grandTotalRow = props.outcomeSummaryDemand.rows.find(
            (x) => x.product === 'Grand Total'
        );
        const filteredRows = props.outcomeSummaryDemand.rows.filter(
            (x) => x.campaignId === props.campaignId
        );
        const totalBudget = filteredRows.reduce(
            (acc, row) => acc + row.budget,
            0
        );
        const totalBidAmount = filteredRows.reduce(
            (acc, row) => acc + row.totalBidAmount,
            0
        );
        const totalRemainingBudget = filteredRows.reduce(
            (acc, row) => acc + row.remainingBudget,
            0
        );
        if (grandTotalRow) {
            filteredRows.push({
                ...grandTotalRow,
                budget: totalBudget,
                totalBidAmount: totalBidAmount,
                remainingBudget: totalRemainingBudget,
            });
        }

        return filteredRows;
    };

    const getGrandTotalRow = (): OutcomeSummaryDemandRow | undefined => {
        return getFilteredRows().find((row) => {
            return stringsExtension.stringsEqual(row.product, 'Grand Total');
        });
    };

    const grandTotalRow = getGrandTotalRow();
    const averageAds = grandTotalRow?.noOfAdsAllocated || 0;

    const getBudgetSpendToCap = grandTotalRow
        ? parseFloat(
              (
                  (grandTotalRow.totalBidAmount / grandTotalRow.budget) *
                  100
              ).toFixed(2)
          )
        : 0;

    const getOutcomeAverage = () => {
        let rows = getFilteredRows();
        let totalCount = 0;
        let totalPerformanceEstimatePerAd = 0;
        rows.forEach((row) => {
            if (row.avgPerformanceEstimatePerAd === null) {
                return;
            }
            totalPerformanceEstimatePerAd +=
                row.avgPerformanceEstimatePerAd || 0;
            totalCount++;
        });
        if (totalCount === 0) {
            return 0;
        }
        const result = totalPerformanceEstimatePerAd / totalCount;
        return result;
    };

    const getInputEstimateAverage = () => {
        let rows = getFilteredRows();
        let totalCount = 0;
        let totalAvgInputPerformanceEstimate = 0;
        rows.forEach((row) => {
            if (row.budget && row.product !== 'Grand Total') {
                const averageEstimatePerCampaign =
                    row.avgInputPerformanceEstimate;
                if (averageEstimatePerCampaign !== undefined) {
                    totalCount++;
                    totalAvgInputPerformanceEstimate +=
                        averageEstimatePerCampaign;
                }
            }
        });
        const avgInputPerformanceEstimate = totalCount
            ? totalAvgInputPerformanceEstimate / totalCount
            : 0;
        return avgInputPerformanceEstimate;
    };

    const getAveragePerformancePercent = () => {
        const inputEstimateAverage = getInputEstimateAverage();

        if (inputEstimateAverage === 0) {
            return 0;
        }

        const result = (getOutcomeAverage() * 100) / inputEstimateAverage;
        return Math.round(result * 100) / 100;
    };

    const campaignSummaryData: CampaignSummaryData[] = [
        {
            title: 'Average Performance',
            value: getAveragePerformancePercent() - 100,
            subTitleValue: Math.round(getOutcomeAverage() * 100) / 100,
            valueType: TitleType.Percent,
            subTitleType: TitleType.Decimal,
            valueInBracketsType: TitleType.Decimal,
            valueInBrackets: Math.round(getInputEstimateAverage() * 100) / 100,
            isValueSignVisible: true,
            isArrowVisible: true,
        },
        {
            title: 'Number of Ads',
            value: averageAds,
            subTitleValue: undefined,
            valueType: TitleType.Text,
            subTitleType: TitleType.Text,
            valueInBracketsType: TitleType.Text,
            valueInBrackets: undefined,
            isValueSignVisible: false,
            isArrowVisible: false,
        },
        {
            title: 'Budget spend to cap',
            value: getBudgetSpendToCap,
            subTitleValue: grandTotalRow?.totalBidAmount,
            valueType: TitleType.Percent,
            subTitleType: TitleType.Number,
            valueInBracketsType: TitleType.Number,
            valueInBrackets: grandTotalRow?.budget,
            isValueSignVisible: false,
            isArrowVisible: false,
        },
    ];
    const isLessThan1200 = useMediaQuery('(max-width:1200px)');
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'flex-end',
                flexWrap: isLessThan1200 ? 'wrap' : 'nowrap',
                gap: (theme) => theme.spacing(3),
            }}>
            {campaignSummaryData.map((summary, i) => (
                <CampaignSummaryBox
                    key={i}
                    title={summary.title}
                    value={summary.value}
                    subTitleValue={summary.subTitleValue}
                    isArrowVisible={summary.isArrowVisible}
                    isValueSignVisible={summary.isValueSignVisible}
                    valueType={summary.valueType}
                    subTitleType={summary.subTitleType}
                    valueInBracketsType={summary.valueInBracketsType}
                    index={i}
                    valueInBrackets={summary.valueInBrackets}
                />
            ))}
        </Box>
    );
};

export default CampaignSummaryBoxes;
