import { DailyLog } from '../../features/ssp/DailyLog';
import { DailyLogsParser } from '../../features/ssp/DailyLogsParser';
import UploadDocument from '../upload-document/UploadDocument';
export interface UploadDailyLogsProps {
    onUploaded: (dailyLogs: DailyLog) => void;
    disabled?: boolean;
}

const UploadDailyLogs = (props: UploadDailyLogsProps) => {
    const onDocumentUploaded = (cells: (string | number)[][]) => {
        try {
            const dailyLogs = new DailyLogsParser(cells).parse();
            props.onUploaded(dailyLogs);
        } catch (error) {
            alert(error);
        }
    };
    return (
        <UploadDocument
            buttonText="Upload Daily Logs"
            onUploaded={onDocumentUploaded}
            disabled={props.disabled}></UploadDocument>
    );
};
export default UploadDailyLogs;
