import { ObjectState } from '../ObjectState';
import { IdGenerator } from './IdGenerator';
import { PerformanceEstimate } from './PerformanceEstimate';
import { TimePerformanceEstimate } from './TimePerformanceEstimate';
import { ParseExtension } from './extensions/ParseExtension';
import { StringsExtension } from './extensions/StringsExtension';

export class PerformanceEstimatesParser {
    constructor(private cells: (string | number)[][]) {}

    private stringsExtension = new StringsExtension();
    private parseExtensions = new ParseExtension(this.cells);
    private networkNameMarker: string = 'Network';
    private daypartMarker: string = 'Daypart';
    private hoursMarker: string = 'Hours ';
    private clientNameMarker: string = 'Client ';
    private kpiMarker: string = 'KPI';
    private kpiValueMarker: string = 'KPI Value';
    private budgetPercentageMarker: string = 'Budget Allocation Percentage';

    parse(): PerformanceEstimate[] {
        const result: PerformanceEstimate[] = [];
        const allRowIndexesWithNetworkMarker =
            this.getAllRowIndexesWithNetworkMarker();

        if (allRowIndexesWithNetworkMarker.length === 0) {
            return result;
        }

        for (let i = 0; i < allRowIndexesWithNetworkMarker.length; i++) {
            const markerIndex = allRowIndexesWithNetworkMarker[i];
            const nextMarkerIndex =
                allRowIndexesWithNetworkMarker[i + 1] || this.cells.length;
            const performanceEstimate = this.parsePerformanceEstimateSection(
                markerIndex,
                nextMarkerIndex
            );
            if (performanceEstimate) result.push(performanceEstimate);
        }

        return result;
    }

    private getAllRowIndexesWithNetworkMarker(): number[] {
        const result: number[] = [];
        this.cells.forEach((row, rowIndex) => {
            if (
                this.stringsExtension.stringsEqual(
                    row[0],
                    this.networkNameMarker
                )
            )
                result.push(rowIndex + 1);
        });
        return result;
    }

    private parsePerformanceEstimateSection(
        startIndex: number,
        nextMarkerIndex: number
    ): PerformanceEstimate | null {
        const networkName = this.parseExtensions
            .getHeadValueInRange(
                this.networkNameMarker,
                startIndex,
                nextMarkerIndex
            )
            .toString();
        const daypart = this.parseExtensions
            .getHeadValueInRange(
                this.daypartMarker,
                startIndex,
                nextMarkerIndex
            )
            .toString();
        const hours = this.parseExtensions
            .getHeadValueInRange(this.hoursMarker, startIndex, nextMarkerIndex)
            .toString();
        const client = this.parseExtensions
            .getHeadValueInRange(
                this.clientNameMarker,
                startIndex,
                nextMarkerIndex
            )
            .toString();
        const kpi = this.parseExtensions
            .getHeadValueInRange(this.kpiMarker, startIndex, nextMarkerIndex)
            .toString();

        const kpiValue = this.parseExtensions.getHeadValueInRange(
            this.kpiValueMarker,
            startIndex,
            nextMarkerIndex
        );
        const budgetPercentage = this.parseExtensions.getHeadValueInRange(
            this.budgetPercentageMarker,
            startIndex,
            nextMarkerIndex
        );

        const parsedKpiValue = this.parseNumber(kpiValue);
        const parsedBudgetPercentage = this.parseNumber(budgetPercentage) ?? 0;

        const id = IdGenerator.generate();

        const startHoursIndex = this.getStartIndexesOfTimePerformanceEstimate(
            hours,
            startIndex,
            nextMarkerIndex
        );

        let estimateSchedules: TimePerformanceEstimate[] = [];
        if (typeof startHoursIndex !== 'undefined') {
            estimateSchedules = this.getTimePerformanceEstimate(
                startHoursIndex,
                nextMarkerIndex
            );
        }

        const performanceEstimate: PerformanceEstimate = {
            networkName: networkName,
            daypart: daypart,
            hours: hours,
            client: client,
            kpi: kpi,
            kpiValue: parsedKpiValue,
            id: id,
            budgetPercentage: parsedBudgetPercentage,
            estimateSchedules: estimateSchedules,
            objectState: ObjectState.Added,
        };

        return performanceEstimate;
    }

    private parseNumber(value: string | number): number | undefined {
        if (typeof value === 'number') {
            return value;
        }

        if (typeof value === 'string') {
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                return parsedValue;
            }
        }

        return undefined;
    }

    private getTimePerformanceEstimate(
        startIndex: number,
        nextMarkerIndex: number
    ): TimePerformanceEstimate[] {
        const result: TimePerformanceEstimate[] = [];
        for (startIndex; startIndex < nextMarkerIndex; startIndex++) {
            const row = this.cells[startIndex];
            if (row.length === 0) {
                continue;
            }
            if (
                row[0].toString().trim().toLowerCase() ===
                this.networkNameMarker.toLowerCase().trim()
            ) {
                break;
            }

            const timePerformanceEstimate: TimePerformanceEstimate =
                this.parseTimePerformanceEstimate(row);

            if (timePerformanceEstimate) {
                result.push(timePerformanceEstimate);
            }
        }
        return result;
    }

    private getStartIndexesOfTimePerformanceEstimate(
        hoursRange: string,
        startIndexRange: number,
        endIndexRange: number
    ): number | undefined {
        const startTime = this.getStartTimeRange(hoursRange);

        for (let i = startIndexRange; i <= endIndexRange; i++) {
            if (typeof this.cells[i]?.[0] === 'undefined') {
                continue;
            }
            const cell = this.cells?.[i]?.[0];

            const rowValue = cell.toString().trim().toLowerCase();

            if (rowValue === startTime.trim().toLowerCase()) {
                return i;
            }
        }

        return undefined;
    }

    private getStartTimeRange(timeRange: string): string {
        const trimmedTimeRange = timeRange.trim();

        const timeParts = trimmedTimeRange.split(/\s*-\s*/);
        if (timeParts.length > 1) {
            return timeParts[0].trim();
        }
        return '';
    }

    private parseTimePerformanceEstimate(
        row: (string | number)[]
    ): TimePerformanceEstimate {
        const time: string = row.length > 0 ? String(row[0]) : '';
        let estimate: number | undefined = undefined;
        if (typeof row[1] !== 'undefined') {
            if (typeof row[1] === 'number') {
                estimate = row[1];
            } else if (typeof row[1] === 'string') {
                const parsedEstimate = parseInt(String(row[1]));
                estimate = isNaN(parsedEstimate) ? undefined : parsedEstimate;
            }

            if (estimate === 0) {
                estimate = undefined;
            }
        }
        return {
            time: time,
            estimate: estimate,
        };
    }
}
