import { Box, Typography, useMediaQuery } from '@mui/material';

interface TitleLabelAndTextProps {
    title: string;
    text: string;
}

const TitleLabelAndText = ({ title, text }: TitleLabelAndTextProps) => {
    const isMobile = useMediaQuery('(max-width:920px)');
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: (theme) => theme.spacing(1),
            }}>
            <Typography
                typography={'overline'}
                sx={{
                    height: (theme) => (isMobile ? 'auto' : theme.spacing(1)),
                    textTransform: 'none',
                }}>
                {title}
            </Typography>
            <Typography
                typography={'p'}
                sx={{
                    height: (theme) => (isMobile ? 'auto' : theme.spacing(3)),
                    textAlign: 'left',
                }}>
                {text}
            </Typography>
        </Box>
    );
};
export default TitleLabelAndText;
