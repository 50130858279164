import React from 'react';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string | undefined} }) => void;
    name: string;
    value: string;
}

const NumericThouthandSeparator = React.forwardRef<
    NumericFormatProps,
    CustomProps
>(function NumericFormatThouthandSeparator(props, ref) {
    const { onChange, value, ...other } = props;

    const numericFormatValueLimit = (values: NumberFormatValues): boolean => {
        const parsedValue = parseFloat(values.value);
        if (!isNaN(parsedValue) && Math.abs(parsedValue) <= 999999999999.0) {
            return true;
        }
        if (!values.value) {
            return true;
        }
        return false;
    };

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            allowNegative={false}
            value={value}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isAllowed={numericFormatValueLimit}
            thousandSeparator
            decimalScale={2}
        />
    );
});
export default NumericThouthandSeparator;
