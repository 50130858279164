import { UserRoles } from '../../authentication/UserRoles';
import { UserRolesRequest } from '../contracts/UserRolesRequest';

export class UserRolesRequestMapper {
    mapUserRolesRequestMapper = (
        role: UserRoles
    ): UserRolesRequest | undefined => {
        switch (role) {
            case 'Admin':
                return UserRolesRequest.Admin;
            case 'Seller':
                return UserRolesRequest.Seller;
            case 'Buyer':
                return UserRolesRequest.Buyer;
            default:
                return undefined;
        }
    };
}
