import { Box, Typography, TextField, Button, InputLabel } from '@mui/material';
import { useState } from 'react';
import axios, { HttpStatusCode } from 'axios';
import axiosInstance from '../../features/axios/axiosInstance';
import AccountSettingsDrawer from '../../components/account-settings-drawer/AccountSettingsDrawer';

const ChangeAccountPassword = () => {
    const inputWidth = 182;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [currentPasswordError, setCurrentPasswordError] = useState<boolean>();

    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordError, setNewPasswordError] = useState<boolean>();

    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [repeatPasswordError, setRepeatPasswordError] = useState<boolean>();

    const [passwordMatchingError, setPasswordMatchingError] =
        useState<boolean>();

    const clearAllPasswordField = () => {
        setCurrentPassword('');
        setCurrentPasswordError(false);
        setNewPassword('');
        setNewPasswordError(false);
        setRepeatPassword('');
        setRepeatPasswordError(false);
        setIsLoading(false);
    };

    const changePasswordAsync = async (): Promise<boolean | undefined> => {
        try {
            const body = {
                currentPassword: currentPassword,
                newPassword: newPassword,
            };
            const response = await axiosInstance.put(
                `/api/v1/user-accounts/change/password`,
                body
            );

            return response.status === HttpStatusCode.Ok;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === HttpStatusCode.Unauthorized) {
                    return false;
                }
                if (error.response?.status === HttpStatusCode.BadRequest) {
                    setPasswordMatchingError(true);
                    return false;
                }
                return false;
            }
            return false;
        }
    };

    const saveAsync = async () => {
        setIsLoading(true);

        let isRequestSuccessful: boolean | undefined = false;

        if (currentPassword !== '') {
            isRequestSuccessful = await changePasswordAsync();

            if (typeof isRequestSuccessful !== 'undefined') {
                if (isRequestSuccessful) {
                    clearAllPasswordField();
                }
            }
        }
        setIsLoading(false);
    };

    const isButtonsDisabled = () => {
        return (
            currentPassword === '' &&
            newPassword === '' &&
            repeatPassword === '' &&
            !isLoading
        );
    };

    return (
        <Box sx={{ my: 1 }}>
            <Box
                sx={{
                    mb: 2,
                }}>
                <Typography variant="h4" component="h4">
                    Account Settings
                </Typography>
            </Box>

            <Box
                sx={{
                    '& .MuiTextField-root': {
                        mr: 1,
                        mt: 0.5,
                        width: inputWidth,
                    },
                    my: 4,
                    width: 'max-content',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <AccountSettingsDrawer />
                <Box
                    sx={{
                        ml: 2,
                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <InputLabel
                            error={
                                currentPasswordError || passwordMatchingError
                            }>
                            {currentPasswordError &&
                            currentPassword.length === 0
                                ? 'Password is required'
                                : passwordMatchingError
                                ? 'Invalid password'
                                : currentPasswordError
                                ? 'Invalid password'
                                : 'Password'}
                        </InputLabel>

                        <TextField
                            type="password"
                            variant="outlined"
                            value={currentPassword}
                            onChange={(event) => {
                                const value = event.target.value;
                                setCurrentPassword(value);
                                if (value.length >= 8) {
                                    setCurrentPasswordError(false);
                                } else {
                                    setCurrentPasswordError(true);
                                    setPasswordMatchingError(false);
                                }
                            }}
                            autoComplete={'new-password'}
                            error={
                                currentPasswordError || passwordMatchingError
                            }
                        />
                        <InputLabel error={newPasswordError}>
                            {newPasswordError && newPassword.length === 0
                                ? 'New Password is required'
                                : newPasswordError
                                ? 'Invalid password'
                                : 'New Password'}
                        </InputLabel>
                        <TextField
                            type="password"
                            variant="outlined"
                            value={newPassword}
                            onChange={(event) => {
                                const value = event.target.value;
                                setNewPassword(value);
                                if (value.length >= 8) {
                                    setNewPasswordError(false);
                                } else {
                                    setNewPasswordError(true);
                                }
                            }}
                            autoComplete={'new-password'}
                            error={newPasswordError}
                        />
                        <InputLabel error={repeatPasswordError}>
                            {repeatPasswordError && repeatPassword.length === 0
                                ? 'Repeat Password is required'
                                : repeatPasswordError
                                ? 'Passwords do not match'
                                : 'Repeat Password'}
                        </InputLabel>
                        <TextField
                            type="password"
                            variant="outlined"
                            value={repeatPassword}
                            onChange={(event) => {
                                const value = event.target.value;
                                setRepeatPassword(value);
                                if (value !== newPassword) {
                                    setRepeatPasswordError(true);
                                } else {
                                    setRepeatPasswordError(false);
                                }
                            }}
                            autoComplete={'new-password'}
                            error={repeatPasswordError}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            my: 2,
                        }}>
                        <Button
                            onClick={() => {
                                clearAllPasswordField();
                            }}
                            disabled={isButtonsDisabled()}>
                            Cancel
                        </Button>
                        <Button
                            onClick={async () => await saveAsync()}
                            disabled={isButtonsDisabled()}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ChangeAccountPassword;
