import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { useState } from 'react';
import { UserRoles } from '../../features/authentication/UserRoles';
import axiosInstance from '../../features/axios/axiosInstance';
import { UserRolesRequestMapper } from '../../features/userAccount/mappers/UserRolesRequestMapper';
import { UserAccountResponse } from '../../features/userAccount/contracts/UserAccountResponse';
import { UserRolesResponseMapper } from '../../features/userAccount/mappers/UserRolesResponseMapper';
import { UserAccount } from '../../features/userAccount/UserAccount';
import axios, { HttpStatusCode } from 'axios';
import { CreateUserAccountRequest } from '../../features/userAccount/contracts/CreateUserAccountRequest';

interface AdminCreateUserPanelProps {
    setIsShowing: React.Dispatch<React.SetStateAction<boolean>>;
    addNewUser: (userAccount: UserAccount) => void;
}

const AdminCreateUserPanel = ({
    setIsShowing,
    addNewUser,
}: AdminCreateUserPanelProps) => {
    const filteredUserRoles = Object.values(UserRoles).filter(
        (role) => role !== UserRoles.Unknown
    );

    const [selectedRole, setSelectedRole] = useState<UserRoles | undefined>(
        undefined
    );
    const [selectedRoleError, setSelectedRoleError] = useState<boolean>();
    const inputWidth = 182;

    const [displayName, setDisplayName] = useState<string>('');
    const [displayNameError, setDisplayNameError] = useState<boolean>();

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>();
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>(
        'Invalid email format'
    );

    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<boolean>();

    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [repeatPasswordError, setRepeatPasswordError] = useState<boolean>();

    const areFieldsValid = (): boolean => {
        let isValid = true;

        if (displayName.length < 1 || displayNameError) {
            setDisplayNameError(true);
            isValid = false;
        }

        if (email.length < 1 || emailError) {
            setEmailError(true);
            isValid = false;
        }

        if (
            selectedRole === UserRoles.Unknown ||
            typeof selectedRole === 'undefined' ||
            selectedRoleError
        ) {
            setSelectedRoleError(true);
            isValid = false;
        }

        if (password.length < 8 || passwordError) {
            setPasswordError(true);
            isValid = false;
        }

        if (
            repeatPassword.length < 8 ||
            repeatPassword !== password ||
            repeatPasswordError
        ) {
            setRepeatPasswordError(true);
            isValid = false;
        }

        return isValid;
    };

    const createUserAsync = async () => {
        if (!areFieldsValid()) {
            return;
        }

        if (typeof selectedRole === 'undefined') {
            setSelectedRoleError(true);
            return;
        }

        const roleRequest =
            new UserRolesRequestMapper().mapUserRolesRequestMapper(
                selectedRole
            );
        if (typeof roleRequest === 'undefined') {
            setSelectedRoleError(true);
            return;
        }

        const body: CreateUserAccountRequest = {
            role: roleRequest,
            displayName,
            email,
            password,
        };
        try {
            const response = await axiosInstance.post<UserAccountResponse>(
                '/api/v1/user-accounts',
                body,
                {
                    headers: {
                        ContentType: 'application/json',
                    },
                }
            );
            if (response.status === HttpStatusCode.Ok) {
                setIsShowing(false);
                clearAllFields();
                const responseData: UserAccountResponse = response.data;
                const userRolesResponseMapper = new UserRolesResponseMapper();

                const createdUser: UserAccount = {
                    id: responseData.id,
                    role: userRolesResponseMapper.mapUserRolesResponseToUserRoles(
                        responseData.role
                    ),
                    email: responseData.email,
                    displayName: responseData.displayName,
                    isActive: responseData.isActive,
                    dateModified: new Date(responseData.dateModified),
                    dateCreated: new Date(responseData.dateCreated),
                    isEdited: false,
                };
                addNewUser(createdUser);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === HttpStatusCode.BadRequest) {
                    setEmailError(true);
                    setEmailErrorMessage('Email already exists');
                }
                if (error.response?.status === HttpStatusCode.Unauthorized) {
                    return;
                }
            }
        }
    };

    const clearAllFields = () => {
        setSelectedRole(undefined);
        setSelectedRoleError(false);
        setDisplayName('');
        setDisplayNameError(false);
        setEmail('');
        setEmailError(false);
        setPassword('');
        setPasswordError(false);
        setRepeatPassword('');
        setRepeatPasswordError(false);
    };

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTextField-root': {
                    mr: 1,
                    width: inputWidth,
                },
                '& .MuiFormControl-root': {
                    mr: 1,
                    width: inputWidth,
                },
            }}>
            <Box>
                <InputLabel error={displayNameError}>
                    {displayNameError && displayName.length === 0
                        ? 'Name is required'
                        : displayNameError
                        ? 'Name too long'
                        : 'Name'}
                </InputLabel>

                <TextField
                    variant="outlined"
                    value={displayName}
                    onChange={(event) => {
                        setDisplayName(event.target.value);
                        if (event.target.value.length < 512) {
                            setDisplayNameError(false);
                        } else {
                            setDisplayNameError(true);
                        }
                    }}
                />
                <InputLabel error={emailError}>
                    {emailError && email.length === 0
                        ? 'Email is required'
                        : emailError
                        ? emailErrorMessage
                        : 'Email'}
                </InputLabel>
                <TextField
                    variant="outlined"
                    value={email}
                    onChange={(event) => {
                        const value = event.target.value;
                        setEmail(value);
                        if (
                            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) &&
                            value.length < 256
                        ) {
                            setEmailError(false);
                        } else {
                            setEmailError(true);
                            setEmailErrorMessage('Invalid email format');
                        }
                    }}
                    error={emailError}
                />
            </Box>
            <Box>
                <InputLabel error={selectedRoleError}>
                    {selectedRoleError ? 'Role must be not empty' : 'Role'}
                </InputLabel>

                <FormControl variant="outlined" size="medium">
                    <Select
                        name="User Role"
                        displayEmpty
                        value={selectedRole || ''}
                        onChange={(event) => {
                            setSelectedRole(event.target.value as UserRoles);
                            setSelectedRoleError(false);
                        }}
                        error={selectedRoleError}>
                        {Object.values(filteredUserRoles)
                            .reverse()
                            .map((role, i) => (
                                <MenuItem key={i} value={role}>
                                    {role}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <InputLabel error={passwordError}>
                    {passwordError && password.length === 0
                        ? 'Password is required'
                        : passwordError
                        ? 'Invalid password'
                        : 'Password'}
                </InputLabel>
                <TextField
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(event) => {
                        const value = event.target.value;
                        setPassword(value);
                        if (value.length >= 8) {
                            setPasswordError(false);
                        } else {
                            setPasswordError(true);
                        }
                    }}
                    error={passwordError}
                />
                <InputLabel error={repeatPasswordError}>
                    {repeatPasswordError && repeatPassword.length === 0
                        ? 'Repeat Password is required'
                        : repeatPasswordError
                        ? 'Passwords do not match'
                        : 'Repeat Password'}
                </InputLabel>
                <TextField
                    type="password"
                    variant="outlined"
                    value={repeatPassword}
                    onChange={(event) => {
                        const value = event.target.value;
                        setRepeatPassword(value);
                        if (value !== password) {
                            setRepeatPasswordError(true);
                        } else {
                            setRepeatPasswordError(false);
                        }
                    }}
                    error={repeatPasswordError}
                />
            </Box>
            <Box>
                <Button
                    onClick={() => {
                        setIsShowing(false);
                        clearAllFields();
                    }}>
                    Cancel
                </Button>
                <Button onClick={() => createUserAsync()}>Save</Button>
            </Box>
        </Box>
    );
};

export default AdminCreateUserPanel;
