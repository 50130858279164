import { Box, Typography, useTheme } from '@mui/material';
interface NavigationItemProps {
    isSelected: boolean;
    title: string;
    pageIndex: number;
    onClick: (pageIndex: number, event: any) => void;
    isUserTab: boolean;
}
const NavigationItem = ({
    isSelected,
    title,
    pageIndex,
    onClick,
    isUserTab,
}: NavigationItemProps) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                pt: 0.5,
                pb: 0.5,
                pl: 1,
                pr: 1,
                borderRadius: 2,
                width: isUserTab ? (theme) => theme.spacing(12) : 'auto',
                color: '#FFFFFF',
                transition:
                    'background-color 0.5s ease-in-out, color 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)',
                ...(isSelected && {
                    color: theme.palette.text.primary,
                    boxShadow: '0 0.5 1 0 #0000001A',
                    backgroundColor: '#FFFFFF',
                }),
                '&:hover': {
                    cursor: 'pointer',
                },
            }}
            onClick={(event) => onClick(pageIndex, event)}>
            <Typography
                typography={'p'}
                sx={{
                    fontWeight: 600,
                    lineHeight: 1.5,
                    whiteSpace: 'nowrap',
                    fontSize: (theme) => theme.spacing(2),
                    textAlign: 'center',
                }}>
                {title}
            </Typography>
        </Box>
    );
};

export default NavigationItem;
