import { Campaign } from '../Campaign';
import { ObjectState } from '../../ObjectState';
import { Buyer } from '../Buyer';
import { PerformanceEstimate } from '../PerformanceEstimate';
import { TimePerformanceEstimate } from '../TimePerformanceEstimate';
import { BuyerResponse } from '../contracts/buyers/BuyerResponse';
import { CampaignResponse } from '../contracts/buyers/CampaignResponse';
import { PerformanceEstimateResponse } from '../contracts/buyers/PerformanceEstimateResponse';
import { TimePerformanceEstimateResponse } from '../contracts/buyers/TimePerformanceEstimateResponse';
import { TimeExtensions } from '../extensions/TimeExtensions';

export class BuyerResponseMapper {
    mapBuyerResponse = (buyerResponse: BuyerResponse): Buyer => {
        return {
            id: buyerResponse.id,
            name: buyerResponse.name,
            objectState: ObjectState.Original,
        };
    };
    mapBuyersResponse = (buyersResponse: BuyerResponse[]): Buyer[] => {
        return buyersResponse.map(this.mapBuyerResponse);
    };

    mapCampaignsResponse = (
        campaignsResponse: CampaignResponse[],
        performanceEstimateResponses: PerformanceEstimateResponse[]
    ): Campaign[] => {
        return campaignsResponse.map((campaignsResponseElement) => {
            const campaignPerformanceEstimateResponses =
                performanceEstimateResponses.filter(
                    (performanceEstimateResponse) =>
                        performanceEstimateResponse.campaignId ===
                        campaignsResponseElement.id
                );
            return this.mapCampaignResponse(
                campaignsResponseElement,
                campaignPerformanceEstimateResponses
            );
        });
    };

    mapCampaignResponse = (
        campaignResponse: CampaignResponse,
        performanceEstimateResponses: PerformanceEstimateResponse[]
    ): Campaign => {
        const timeExtensions = new TimeExtensions();
        let performanceEstimates: PerformanceEstimate[] =
            performanceEstimateResponses.map((response) => {
                return {
                    networkName: response.networkName,
                    daypart: response.daypart,
                    hours: response.hours,
                    client: response.client,
                    kpi: response.kpi,
                    kpiValue: response.kpiValue ?? 0,
                    budgetPercentage: response.budgetPercentage,
                    id: response.id,
                    estimateSchedules: response.timeEstimates
                        .sort((a, b) =>
                            timeExtensions.compareTimes(a.time, b.time)
                        )
                        .map((estimate) => {
                            return {
                                time: estimate.time,
                                estimate: estimate.estimate,
                            };
                        }),
                    objectState: ObjectState.Original,
                };
            });

        return {
            id: campaignResponse.id,
            name: campaignResponse.name,
            brandName: campaignResponse.brandName,
            productName: campaignResponse.productName,
            budget: campaignResponse.budget,
            minMinutesBetweenAds: campaignResponse.minSecondsBetweenAds / 60,
            performanceEstimates: performanceEstimates,
            dateModified: campaignResponse.dateModified,
            objectState: ObjectState.Original,
        };
    };

    mapPerformanceEstimatesResponse = (
        performanceEstimateResponse: PerformanceEstimateResponse
    ): PerformanceEstimate => {
        return {
            id: performanceEstimateResponse.id,
            daypart: performanceEstimateResponse.daypart,
            networkName: performanceEstimateResponse.networkName ?? '',
            hours: performanceEstimateResponse.hours ?? '',
            client: performanceEstimateResponse.client ?? '',
            kpi: performanceEstimateResponse.kpi ?? '',
            kpiValue: performanceEstimateResponse.kpiValue,
            budgetPercentage: performanceEstimateResponse.budgetPercentage,
            objectState: ObjectState.Original,
            estimateSchedules:
                typeof performanceEstimateResponse.timeEstimates !== 'undefined'
                    ? this.mapTimePerformanceEstimatesResponse(
                          performanceEstimateResponse.timeEstimates
                      )
                    : [],
        };
    };

    mapTimePerformanceEstimatesResponse = (
        timePerformanceEstimatesResponse: TimePerformanceEstimateResponse[]
    ): TimePerformanceEstimate[] => {
        return typeof timePerformanceEstimatesResponse !== 'undefined' &&
            timePerformanceEstimatesResponse.length !== 0
            ? timePerformanceEstimatesResponse.map(
                  this.mapTimePerformanceEstimateResponse
              )
            : [];
    };

    mapTimePerformanceEstimateResponse = (
        timePerformanceEstimateResponse: TimePerformanceEstimateResponse
    ): TimePerformanceEstimate => ({
        time: timePerformanceEstimateResponse.time,
        estimate: timePerformanceEstimateResponse.estimate,
    });
}
