import { Daypart } from '../Daypart';
import { DaypartContract } from '../contracts/DaypartContract';

export class DaypartContractMapper {
    toContract = (daypart: Daypart): DaypartContract => {
        switch (daypart) {
            case Daypart.Daytime:
                return DaypartContract.Daytime;
            case Daypart.Unknown:
                return DaypartContract.Unknown;
            default:
                return DaypartContract.Unknown;
        }
    };
}
