export class TimeExtensions {
    convertTimeToNumeric(time: string): number {
        const result = parseInt(
            time
                .toLowerCase()
                .replace(':', '')
                .replace('am', '00')
                .replace('noon', '00')
                .replace('pm', '0000')
        );
        return result;
    }
    compareTimes(a: string, b: string): number {
        const aNumeric = this.convertTimeToNumeric(a);
        const bNumeric = this.convertTimeToNumeric(b);
        const result = aNumeric - bNumeric;
        return result;
    }
    sort(times: string[]): string[] {
        const uniqueTimes = Array.from(new Set<string>(times));

        const sortedTimes = Array.from(uniqueTimes).sort((a, b) => {
            return this.compareTimes(a, b);
        });
        return sortedTimes;
    }

    toDurationColonFormat(time: number): string {
        if (time < 60) {
            return `0:${time < 10 ? '0' : ''}${time}`;
        } else {
            const hours = Math.floor(time / 3600);
            const minutes = Math.floor((time % 3600) / 60);
            const seconds = time % 60;
            return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
                seconds < 10 ? '0' : ''
            }${seconds}`;
        }
    }

    isUSTimeFormat(time: string): boolean {
        const regex = /^(1[0-2]|[1-9])(AM|am|PM|pm)$|^12(noon|PM|pm)$/;
        return regex.test(time);
    }
}
