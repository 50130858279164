import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveBuyersStateAsync } from '../buyers/save-buyer-state-slice-extensions';
import { loadSellersStateAsync } from '../sellers/seller-state-slice-extensions';

export const saveBuyersStateLoadSellersAsync = createAsyncThunk(
    'ssp/saveBuyersStateLoadSellers',
    async (userId: string | undefined, thunkApi) => {
        await thunkApi.dispatch(saveBuyersStateAsync());
        await thunkApi.dispatch(loadSellersStateAsync(userId));
    }
);
