import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { SspState } from '../SspState';
import { Campaign } from '../Campaign';
import { BuyerCampaign } from '../BuyerCampaign';
import {
    loadOptimizationsStateAsync,
    runOptimizationAsync,
} from './outcome-slice-extensions';
import {
    removePriceFloorsByCampaignIdPrivate,
    sortCampaignsPriceFloorsByBuyerIdPrivate,
    updatePriceFloorCampaignDetailsPrivate,
    setPriceFloorPricePrivate,
    updatePriceFloorTimesPrivate,
    importPriceFloorsPrivate,
} from './sellers/price-floor-slice-extensions';
import {
    addBuyerCampaignPrivate,
    addCampaignPrivate,
    addPerformanceEstimatePrivate,
    importCampaignPerformanceEstimatePrivate,
    removeBuyerPrivate,
    removeCampaignFromPrivate,
    removePerformanceEstimateByCampaignIdAndNetworkNamePrivate,
    removePerformanceEstimatePrivate,
    setBuyerCampaingNetworksPrivate,
    setPerformanceEstimateDetailsPrivate,
    setPerformanceEstimatePrivate,
    updateBuyerCampaignBuyerNamePrivate,
    updateCampaignDetailsPrivate,
    updatePerformanceEstimateTimesPrivate,
} from './buyers/campaign-slice-extensions';
import { DailyLog } from '../DailyLog';
import {
    deleteDailyLogPrivate,
    importDailyLogsPrivate,
} from './sellers/daily-logs-slice-extensions';
import { DailyLogsExtensions } from '../DailyLogsExtensions';
import { PerformanceEstimate } from '../PerformanceEstimate';
import { PriceFloor } from '../PriceFloor';
import { saveSellerStateAsync } from './sellers/save-seller-state-slice-extensions';
import { LoadingStatus } from '../LoadingStatus';
import { ObjectState } from '../../ObjectState';
import { saveBuyersStateAsync } from './buyers/save-buyer-state-slice-extensions';
import {
    addSellerPrivate,
    loadSellersStateAsync,
} from './sellers/seller-state-slice-extensions';
import {
    loadAvailableNetworksAsyncThunk,
    loadBuyersStateAsync,
} from './buyers/buyer-state-slice-extensions';
import { CampaignBid } from '../CampaignBid';
import { AvailableNetwork } from '../AvailableNetwork';
import { ArrayExtensions } from '../extensions/ArrayExtensions';
import { StringsExtension } from '../extensions/StringsExtension';
import { IdGenerator } from '../IdGenerator';
import { saveBuyersStateLoadSellersAsync } from './actions/save-buyer-load-sellers';
import { defaultSspSliceState } from '../defaultSspSliceState';

const sspSlice = createSlice({
    name: 'ssp',
    initialState: defaultSspSliceState,
    reducers: {
        setStatusMessage: (
            state,
            action: PayloadAction<{ message?: string }>
        ) => {
            state.statusMessage = action.payload.message;
        },
        updateChannelPrice: (
            state,
            action: PayloadAction<{
                priceFloorId: number;
                time: string;
                price: number;
            }>
        ) => {
            setPriceFloorPricePrivate(
                state.sellersState,
                action.payload.priceFloorId,
                action.payload.time,
                action.payload.price
            );
        },
        addBuyerCampaign: (state, action: PayloadAction<BuyerCampaign>) => {
            const { payload: newBuyerCampaign } = action;
            addBuyerCampaignPrivate(state, newBuyerCampaign);
            sortCampaignsPriceFloorsByBuyerIdPrivate(state);
        },
        addCampaign: (
            state,
            action: PayloadAction<{ buyerId: number; newCampaign: Campaign }>
        ) => {
            const { buyerId, newCampaign } = action.payload;
            addCampaignPrivate(state, newCampaign, buyerId);
            sortCampaignsPriceFloorsByBuyerIdPrivate(state);
        },
        updateBuyerName: (
            state,
            action: PayloadAction<{ buyerId: number; newBuyerName: string }>
        ) => {
            const { buyerId, newBuyerName } = action.payload;
            updateBuyerCampaignBuyerNamePrivate(state, buyerId, newBuyerName);
        },
        updateCampaignDetails: (
            state,
            action: PayloadAction<{
                campaignId: number;
                name: string;
                brandName: string;
                productName: string;
                budget: number | undefined;
                minMinutesBetweenAds: number;
            }>
        ) => {
            const {
                campaignId,
                name,
                brandName,

                productName,
                budget,
                minMinutesBetweenAds,
            } = action.payload;
            updateCampaignDetailsPrivate(
                state,
                campaignId,
                name,
                brandName,
                productName,
                budget,
                minMinutesBetweenAds
            );

            const campaign = state.buyersState.buyerCampaigns
                .flatMap((b) => b.campaigns)
                .find((c) => c.id === campaignId);
            const budgets = getNetworkBudgets(campaign);

            const campaignBid: CampaignBid = {
                id: campaignId,
                brandName: brandName ?? '',
                buyerDisplayName: '',
                name: name ?? '',
                productName: productName ?? '',
                minMinutesBetweenAds: action.payload.minMinutesBetweenAds,
                networkBudgets: budgets,
            };
            updatePriceFloorCampaignDetailsPrivate(
                state,
                campaignId,
                campaignBid
            );
        },
        removeCampaign: (
            state,
            action: PayloadAction<{
                campaignId: number;
            }>
        ) => {
            const { campaignId } = action.payload;
            removeCampaignFromPrivate(state, campaignId);
            removePriceFloorsByCampaignIdPrivate(state, campaignId);
        },
        removeBuyer: (
            state,
            action: PayloadAction<{
                buyerId: number;
            }>
        ) => {
            const { buyerId } = action.payload;
            const buyerCampaign = state.buyersState.buyerCampaigns.find(
                (buyerCampaign) => buyerCampaign.buyer.id === buyerId
            );

            if (buyerCampaign) {
                buyerCampaign.campaigns
                    .map((c) => c.id)
                    .forEach((campaignId) => {
                        removePriceFloorsByCampaignIdPrivate(state, campaignId);
                        removeCampaign({ campaignId });
                    });

                removeBuyerPrivate(state, buyerId);
            }
        },
        addPerformanceEstimate: (
            state,
            action: PayloadAction<{ campaignId: number; networkName: string }>
        ) => {
            const { campaignId, networkName } = action.payload;
            addPerformanceEstimatePrivate(
                state.buyersState,
                campaignId,
                networkName
            );
        },
        setPerformanceEstimate: (
            state,
            action: PayloadAction<{
                performanceEstimateId: number;
                time: string;
                estimate?: string;
            }>
        ) => {
            setPerformanceEstimatePrivate(
                state,
                action.payload.performanceEstimateId,
                action.payload.time,
                action.payload.estimate
            );
        },
        removePerformanceEstimate: (
            state,
            action: PayloadAction<{
                performanceEstimateId: number;
            }>
        ) => {
            removePerformanceEstimatePrivate(
                state,
                action.payload.performanceEstimateId
            );
        },
        removePerformanceEstimateByCampaignAndNetworkName: (
            state,
            action: PayloadAction<{
                campaignId: number;
                networkName: string;
            }>
        ) => {
            const { campaignId, networkName } = action.payload;
            removePerformanceEstimateByCampaignIdAndNetworkNamePrivate(
                state.buyersState.buyerCampaigns,
                campaignId,
                networkName
            );
        },
        setPerformanceEstimateDetails: (
            state,
            action: PayloadAction<{
                performanceEstimateId: number;
                networkName: string;
                daypart: string;
                hours: string;
                client: string;
                kpi: string;
                kpiValue: number | undefined;
                budgetPercentage: number;
            }>
        ) => {
            setPerformanceEstimateDetailsPrivate(
                state,
                action.payload.performanceEstimateId,
                action.payload.networkName,
                action.payload.daypart,
                action.payload.hours,
                action.payload.client,
                action.payload.kpi,
                action.payload.kpiValue,
                action.payload.budgetPercentage
            );
            const campaign = state.buyersState.buyerCampaigns
                .flatMap((b) => b.campaigns)
                .find((c) =>
                    c.performanceEstimates.some(
                        (p) => p.id === action.payload.performanceEstimateId
                    )
                );
            const budgets = getNetworkBudgets(campaign);
            if (typeof campaign !== 'undefined') {
                const campaignBid: CampaignBid = {
                    id: campaign.id,
                    brandName: campaign.brandName ?? '',
                    buyerDisplayName: '',
                    name: campaign.name ?? '',
                    productName: campaign.productName ?? '',
                    minMinutesBetweenAds: campaign.minMinutesBetweenAds,
                    networkBudgets: budgets,
                };
                state.sellersState.campaignBids =
                    state.sellersState.campaignBids?.map((currentCampaignBid) =>
                        currentCampaignBid.id !== campaign.id
                            ? currentCampaignBid
                            : campaignBid
                    );
                updatePriceFloorCampaignDetailsPrivate(
                    state,
                    campaign.id,
                    campaignBid
                );
            }
        },
        importCampaignPerformanceEstimate: (
            state,
            action: PayloadAction<{
                campaignId: number;
                uploadedPerformanceEstimates: PerformanceEstimate[];
                networkNames: string[];
            }>
        ) => {
            const { campaignId, uploadedPerformanceEstimates, networkNames } =
                action.payload;
            importCampaignPerformanceEstimatePrivate(
                state,
                campaignId,
                uploadedPerformanceEstimates,
                networkNames
            );
        },
        importDailyLogs: (
            state,
            action: PayloadAction<{ dailyLogs: DailyLog; sellerId: number }>
        ) => {
            importDailyLogsPrivate(
                action.payload.dailyLogs,
                state.sellersState,
                action.payload.sellerId
            );

            const times = new DailyLogsExtensions().getAvailableTimes(
                action.payload.dailyLogs
            );
            const individualSeller = state.sellersState.individualSellers.find(
                (s) => s.seller.id === action.payload.sellerId
            );
            if (typeof individualSeller !== 'undefined') {
                updatePriceFloorTimesPrivate(
                    times,
                    action.payload.dailyLogs.networkName,
                    individualSeller
                );
            }
            if (state.buyersState.buyerCampaigns !== undefined) {
                updatePerformanceEstimateTimesPrivate(
                    times,
                    action.payload.dailyLogs.networkName,
                    state.buyersState.buyerCampaigns
                );
            }

            const stringsExtension = new StringsExtension();
            const networksAfter = new ArrayExtensions().distinct(
                state.sellersState.individualSellers
                    .flatMap((c) => c.dailyLogsList)
                    .filter((d) => d.objectState !== ObjectState.Deleted)
                    .map((d) => d.networkName)
            );
            if (state.buyersState.availableNetworks !== undefined) {
                state.buyersState.availableNetworks = networksAfter.map(
                    (networkName) => {
                        const existingNetwork =
                            state.buyersState.availableNetworks.find(
                                (availableNetwork) => {
                                    return stringsExtension.stringsEqual(
                                        availableNetwork.networkName,
                                        networkName
                                    );
                                }
                            );
                        return (
                            existingNetwork ?? {
                                id: IdGenerator.generate(),
                                networkName,
                            }
                        );
                    }
                );
            }

            synchronizeBuyerCampaignAvaialbleNetworks(state);
        },
        deleteDailyLog: (state, action: PayloadAction<{ id: number }>) => {
            const arrayExtensions = new ArrayExtensions();
            const networksBefore = arrayExtensions.distinct(
                state.sellersState.individualSellers
                    .flatMap((c) => c.dailyLogsList)
                    .filter((d) => d.objectState !== ObjectState.Deleted)
                    .map((d) => d.networkName)
            );
            deleteDailyLogPrivate(action.payload.id, state.sellersState);
            const networksAfter = arrayExtensions.distinct(
                state.sellersState.individualSellers
                    .flatMap((c) => c.dailyLogsList)
                    .filter((d) => d.objectState !== ObjectState.Deleted)
                    .map((d) => d.networkName)
            );
            const networksDiff = networksBefore.filter(
                (n) => !networksAfter.includes(n)
            );
            if (state.buyersState.availableNetworks !== undefined) {
                state.buyersState.availableNetworks =
                    state.buyersState?.availableNetworks?.filter(
                        (n) => !networksDiff.includes(n.networkName)
                    );
            }
            if (state.buyersState.buyerCampaigns !== undefined) {
                state.buyersState.buyerCampaigns.forEach((buyerCampaign) => {
                    buyerCampaign.campaigns?.forEach((campaign) => {
                        campaign.currentNetworks =
                            campaign.currentNetworks?.filter(
                                (network) =>
                                    !networksDiff.includes(network.networkName)
                            );
                        campaign.performanceEstimates =
                            campaign?.performanceEstimates?.filter(
                                (performanceEstimate) =>
                                    !networksDiff.includes(
                                        performanceEstimate?.networkName
                                    )
                            );
                    });
                });
            }
        },
        importPriceFloors: (
            state,
            action: PayloadAction<{
                priceFloors: PriceFloor[];
                sellerName: string;
            }>
        ) => {
            const { priceFloors, sellerName } = action.payload;
            importPriceFloorsPrivate(state, priceFloors, sellerName);
        },
        addSeller: (state, action: PayloadAction<{}>) => {
            addSellerPrivate(state.sellersState);
        },
        setSspState: (
            state,
            action: PayloadAction<{
                newSspState: SspState;
            }>
        ) => {
            const { newSspState } = action.payload;
            if (typeof newSspState !== 'undefined') {
                return {
                    ...state,
                    sellersState: newSspState.sellersState ?? [],
                    buyersState: newSspState.buyersState ?? [],
                    outcomeState: newSspState.outcomeState ?? [],
                    status: newSspState.status ?? 'idle',
                };
            }
            return state;
        },
        setBuyerCurrentNetworks: (
            state,
            action: PayloadAction<{
                availableNetworks: AvailableNetwork[];
                campaignId: number;
            }>
        ) => {
            const { availableNetworks, campaignId } = action.payload;
            const buyersCampaigns = state.buyersState.buyerCampaigns;
            setBuyerCampaingNetworksPrivate(
                buyersCampaigns,
                availableNetworks,
                campaignId
            );
        },
        setStateToDefault: (state, action: PayloadAction) => {
            return { ...defaultSspSliceState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(runOptimizationAsync.pending, (state) => {
                state.outcomeState.message = '';
                state.outcomeState.output = '';
                state.statusMessage = undefined;
                state.status = LoadingStatus.Loading;
            })
            .addCase(runOptimizationAsync.fulfilled, (state, action) => {
                state.status = LoadingStatus.Idle;
                state.outcomeState.dateCreated = action.payload.dateCreated;
                state.outcomeState.dateStart = action.payload.dateStart;
                state.outcomeState.dateEnd = action.payload.dateEnd;
                state.outcomeState.inventoryDailyLogsList =
                    action.payload.inventoryDailyLogsList;
                state.outcomeState.outcomeSummaryDemand =
                    action.payload.summaryDemand;
                state.outcomeState.outcomeSummaryDemandCampaigns =
                    action.payload.summaryDemandCampaigns;
                state.outcomeState.outcomeSummarySupply =
                    action.payload.summarySupply;
                state.outcomeState.outcomeSummaryNetworks =
                    action.payload.summaryNetworks;
            })
            .addCase(runOptimizationAsync.rejected, (state) => {
                state.status = LoadingStatus.Failed;
            })
            .addCase(loadOptimizationsStateAsync.pending, (state) => {
                state.statusMessage = undefined;
                state.outcomeState.inventoryDailyLogsList = [];
                state.outcomeState.outcomeSummaryDemand = undefined;
                state.outcomeState.outcomeSummaryDemandCampaigns = undefined;
                state.outcomeState.outcomeSummarySupply = undefined;
                state.outcomeState.outcomeSummaryNetworks = [];
                state.status = LoadingStatus.Loading;
            })
            .addCase(loadOptimizationsStateAsync.rejected, (state) => {
                state.status = LoadingStatus.Failed;
            })
            .addCase(loadOptimizationsStateAsync.fulfilled, (state, action) => {
                state.outcomeState.dateCreated = action.payload.dateCreated;
                state.outcomeState.dateStart = action.payload.dateStart;
                state.outcomeState.dateEnd = action.payload.dateEnd;
                state.outcomeState.inventoryDailyLogsList =
                    action.payload.inventoryDailyLogsList;
                state.outcomeState.outcomeSummaryDemand =
                    action.payload.summaryDemand;
                state.outcomeState.outcomeSummaryDemandCampaigns =
                    action.payload.summaryDemandCampaigns;
                state.outcomeState.outcomeSummarySupply =
                    action.payload.summarySupply;
                state.outcomeState.outcomeSummaryNetworks =
                    action.payload.summaryNetworks;
                state.status = LoadingStatus.Idle;
            })
            .addCase(loadSellersStateAsync.pending, (state, action) => {
                state.statusMessage = undefined;
                state.sellersState.individualSellers = [];
                state.status = LoadingStatus.Loading;
            })
            .addCase(loadSellersStateAsync.rejected, (state, action) => {
                state.status = LoadingStatus.Failed;
            })
            .addCase(loadSellersStateAsync.fulfilled, (state, action) => {
                if (action.payload.individualSellers.length !== 0) {
                    state.sellersState = action.payload;
                }
                state.status = LoadingStatus.Idle;
            })
            .addCase(loadBuyersStateAsync.pending, (state, action) => {
                state.statusMessage = undefined;
                state.buyersState.buyerCampaigns = [];
                state.status = LoadingStatus.Loading;
            })
            .addCase(loadBuyersStateAsync.rejected, (state, action) => {
                state.status = LoadingStatus.Failed;
            })
            .addCase(loadBuyersStateAsync.fulfilled, (state, action) => {
                state.buyersState = action.payload;
                state.status = LoadingStatus.Idle;
                bumpBuyerCampaignToSellers(state);
                synchronizeBuyerCampaignAvaialbleNetworks(state);
            })
            .addCase(saveBuyersStateAsync.pending, (state, action) => {
                state.statusMessage = undefined;
                state.status = LoadingStatus.Loading;
            })
            .addCase(saveBuyersStateAsync.rejected, (state, action) => {
                state.status = LoadingStatus.Failed;
            })
            .addCase(saveBuyersStateAsync.fulfilled, (state, action) => {
                state.buyersState = action.payload;
                state.status = LoadingStatus.Idle;
                bumpBuyerCampaignToSellers(state);
            })
            .addCase(saveSellerStateAsync.fulfilled, (state, action) => {
                state.sellersState.individualSellers = action.payload;
                state.status = LoadingStatus.Idle;
            })
            .addCase(saveSellerStateAsync.pending, (state, action) => {
                state.statusMessage = undefined;
                state.status = LoadingStatus.Loading;
            })
            .addCase(saveSellerStateAsync.rejected, (state, action) => {
                state.status = LoadingStatus.Failed;
            })

            .addCase(
                saveBuyersStateLoadSellersAsync.fulfilled,
                (state, action) => {
                    state.status = LoadingStatus.Idle;
                }
            )
            .addCase(
                saveBuyersStateLoadSellersAsync.pending,
                (state, action) => {
                    state.statusMessage = undefined;
                    state.status = LoadingStatus.Loading;
                }
            )
            .addCase(
                saveBuyersStateLoadSellersAsync.rejected,
                (state, action) => {
                    state.status = LoadingStatus.Failed;
                }
            )
            .addCase(
                loadAvailableNetworksAsyncThunk.fulfilled,
                (state, action) => {
                    state.buyersState.availableNetworks = action.payload;
                    state.status = LoadingStatus.Idle;
                }
            );
    },
});

const getNetworkBudgets = (
    campaign?: Campaign
): { [networkName: string]: number } => {
    const budgets: { [networkName: string]: number } = {};
    if (typeof campaign === 'undefined') {
        return budgets;
    }
    const sumPercents = campaign.performanceEstimates
        .map((p) => p.budgetPercentage)
        .reduce((previous, current) => previous + current, 0);

    for (let performanceEstimate of campaign.performanceEstimates) {
        const budget =
            ((campaign.budget ?? 0) * performanceEstimate.budgetPercentage) /
            sumPercents;
        budgets[performanceEstimate.networkName] = budget;
    }
    return budgets;
};

export const {
    setStatusMessage,
    updateChannelPrice,
    addBuyerCampaign,
    addCampaign,
    updateBuyerName,
    updateCampaignDetails,
    addPerformanceEstimate,
    setPerformanceEstimate,
    removePerformanceEstimate,
    removePerformanceEstimateByCampaignAndNetworkName,
    setPerformanceEstimateDetails,
    importCampaignPerformanceEstimate,
    removeCampaign,
    removeBuyer,
    importDailyLogs,
    deleteDailyLog,
    importPriceFloors,
    addSeller,
    setSspState,
    setBuyerCurrentNetworks,
    setStateToDefault,
} = sspSlice.actions;
export default sspSlice.reducer;

const bumpBuyerCampaignToSellers = (state: SspState) => {
    state.buyersState?.buyerCampaigns
        .flatMap((buyerCampaign) => buyerCampaign?.campaigns)
        .forEach((campaign) => {
            if (
                !state.sellersState.campaignBids?.some(
                    (campaignBid) => campaignBid.id === campaign.id
                )
            ) {
                const budgets = getNetworkBudgets(campaign);
                const buyerDisplayName = state.buyersState?.buyerCampaigns.find(
                    (bc) => bc.campaigns.some((c) => c === campaign)
                )?.buyer.name;
                state.sellersState.campaignBids?.push({
                    id: campaign.id,
                    brandName: campaign.brandName,
                    buyerDisplayName: buyerDisplayName ?? '',
                    name: campaign.name,
                    productName: campaign.productName,
                    minMinutesBetweenAds: campaign.minMinutesBetweenAds,
                    networkBudgets: budgets,
                });
            }
        });
};

const synchronizeBuyerCampaignAvaialbleNetworks = (state: SspState) => {
    const buyersAvailableNetworks = state.buyersState.availableNetworks;
    if (
        typeof buyersAvailableNetworks !== 'undefined' &&
        typeof state.buyersState.buyerCampaigns !== 'undefined'
    ) {
        state.buyersState.buyerCampaigns.forEach((buyerCampaign) => {
            if (typeof buyerCampaign !== 'undefined') {
                buyerCampaign.campaigns.forEach((campaign) => {
                    const performanceEstimateNetworks: string[] =
                        campaign.performanceEstimates.map(
                            (performanceEstimate) => {
                                return performanceEstimate.networkName;
                            }
                        );
                    const availableNetworks = buyersAvailableNetworks.filter(
                        (network) =>
                            performanceEstimateNetworks.includes(
                                network.networkName
                            )
                    );
                    campaign.currentNetworks =
                        availableNetworks as AvailableNetwork[];
                });
            }
        });
    }
};

const getBuyersState = (state: RootState) => state.ssp?.buyersState;

export const selectBuyerCampaigns = createSelector(
    [getBuyersState],
    (buyersState) =>
        buyersState?.buyerCampaigns?.filter(
            (b) => b.buyer?.objectState !== ObjectState.Deleted
        ) ?? []
);

export const selectCampaigns = createSelector(
    [getBuyersState],
    (buyersState) =>
        buyersState?.buyerCampaigns?.flatMap((b) =>
            b.campaigns.filter(
                (campaign) => campaign.objectState !== ObjectState.Deleted
            )
        ) ?? []
);
export const selectSspSellers = (state: RootState) => state.ssp?.sellersState;
export const selectSspState = (state: RootState) => state.ssp;
export const selectSspStatus = (state: RootState) => state.ssp?.status;
export const selectBuyersAvailableNetworks = (state: RootState) =>
    state.ssp?.buyersState?.availableNetworks;
export const selectStatusMessage = (state: RootState) =>
    state.ssp?.statusMessage;

export const hasBuyersChanges = (state: RootState) =>
    (state.ssp?.buyersState?.buyerCampaigns?.filter(
        (b) =>
            b.buyer.objectState !== ObjectState.Original ||
            typeof b.campaigns.find(
                (campaign) =>
                    campaign.objectState !== ObjectState.Original ||
                    typeof campaign?.performanceEstimates?.find(
                        (performanceEstimate) =>
                            performanceEstimate.objectState !==
                            ObjectState.Original
                    ) !== 'undefined'
            ) !== 'undefined'
    )?.length ?? 0) > 0;

export const hasSellersChanges = (state: RootState) => {
    const sellersChangedLength =
        state.ssp?.sellersState.individualSellers?.filter(
            (s) => s.seller.objectState !== ObjectState.Original
        )?.length ?? 0;
    const individualSellers = state.ssp?.sellersState?.individualSellers ?? [];
    const dailyLogsLength =
        individualSellers.filter((s) =>
            s.dailyLogsList?.some((d) => d.objectState !== ObjectState.Original)
        ).length ?? 0;
    const sellersLength =
        individualSellers.filter(
            (s) => s.seller.objectState !== ObjectState.Original
        )?.length ?? 0;
    const priceFloorsLength =
        individualSellers.filter((s) =>
            s.priceFloors?.some((pF) => pF.objectState !== ObjectState.Original)
        ).length ?? 0;

    return (
        dailyLogsLength +
            sellersLength +
            sellersChangedLength +
            priceFloorsLength >
        0
    );
};
