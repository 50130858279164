import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { InventoryDailyLogs } from '../../features/ssp/InventoryDailyLogs';
import { InventoryDailyLogsService } from '../../features/ssp/InventoryDailyLogsService';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import { TimeExtensions } from '../../features/ssp/extensions/TimeExtensions';

interface OptimizedBidsInventoryDetailsProps {
    inventoryDailyLogs: InventoryDailyLogs;
    showAllocatedOnly: boolean;
    showPriceFloor: boolean;
}
const OptimizedBidsInventoryDetails = (
    props: OptimizedBidsInventoryDetailsProps
) => {
    const serializer = new InventoryDailyLogsService();
    const moneyFormatter = new MoneyFormatter();
    const timeExtensions = new TimeExtensions();
    const logs = !props.showAllocatedOnly
        ? props.inventoryDailyLogs.logs
        : props.inventoryDailyLogs.logs.filter((l) => !!l.bidAmount);
    return (
        <Box
            sx={{
                background: (theme) => theme.palette.background.paper,
                p: 2,
                borderRadius: 2,
            }}>
            <Typography variant="h6">
                {props.inventoryDailyLogs.networkName}
            </Typography>
            <Box sx={{ mt: 1 }}>
                <TableContainer>
                    <Table aria-label="inventory outcome table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Date
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Hour
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    No.
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Type
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Offset
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Length
                                </TableCell>

                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Name
                                </TableCell>

                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Status
                                </TableCell>

                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Buyer
                                </TableCell>

                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Brand
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Product
                                </TableCell>
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}>
                                    Bid
                                </TableCell>
                                {props.showPriceFloor && (
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Price Floor
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs.map((log, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {log.date}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {log.hourBreak}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {log.no}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {serializer.getLogTypeString(
                                            log.logType
                                        )}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {timeExtensions.toDurationColonFormat(
                                            log.offset
                                        )}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {timeExtensions.toDurationColonFormat(
                                            log.length
                                        )}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {log.name}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {serializer.getAvailabilityStatusString(
                                            log.status
                                        )}
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {log.buyerName}
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {log.brandName}
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {log.productName}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        {typeof log.bidAmount !== 'undefined' &&
                                            moneyFormatter.format(
                                                log.bidAmount
                                            )}
                                    </TableCell>
                                    {props.showPriceFloor && (
                                        <TableCell
                                            sx={{
                                                pr: 0.5,
                                                pl: 0.5,
                                            }}>
                                            {typeof log.priceFloor !==
                                                'undefined' &&
                                                moneyFormatter.format(
                                                    log.priceFloor
                                                )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default OptimizedBidsInventoryDetails;
