import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { UserAccount } from '../../features/userAccount/UserAccount';
import { UserRoles } from '../../features/authentication/UserRoles';
import { useNavigate } from 'react-router-dom';
import axios, { HttpStatusCode } from 'axios';
import axiosInstance from '../../features/axios/axiosInstance';
import { UserAccountRequestMapper } from '../../features/userAccount/mappers/UserAccountRequestMapper';
import { UserAccountResponseMapper } from '../../features/userAccount/mappers/UserAccountResponseMapper';
import { useState } from 'react';
import AdminChangePasswordDialog from '../admin-change-password-dialog/AdminChangePasswordDialog';
import { VpnKeyOutlined, ArticleOutlined } from '@mui/icons-material';
import { UserAccountResponse } from '../../features/userAccount/contracts/UserAccountResponse';

interface AdminUsersTableProps {
    userAccounts: UserAccount[];
    setUserAccounts: React.Dispatch<React.SetStateAction<UserAccount[]>>;
}

const AdminUsersTable = ({
    userAccounts,
    setUserAccounts,
}: AdminUsersTableProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDisplayNameChange = (
        value: string,
        userAccountIndex: number
    ) => {
        const updatedUserAccounts = [...userAccounts];
        updatedUserAccounts[userAccountIndex] = {
            ...updatedUserAccounts[userAccountIndex],
            displayName: value,
            isEdited: true,
        };
        setUserAccounts(updatedUserAccounts);
    };

    const handleRoleChange = (role: UserRoles, userAccountIndex: number) => {
        const updatedUserAccounts = [...userAccounts];
        updatedUserAccounts[userAccountIndex] = {
            ...updatedUserAccounts[userAccountIndex],
            role: role,
            isEdited: true,
        };
        setUserAccounts(updatedUserAccounts);
    };

    const handleActiveStatusChange = (
        value: number,
        userAccountIndex: number
    ) => {
        if (value > 1) {
            return;
        }
        const isActive = value === 1;

        const updatedUserAccounts = [...userAccounts];
        updatedUserAccounts[userAccountIndex] = {
            ...updatedUserAccounts[userAccountIndex],
            isActive: isActive,
            isEdited: true,
        };
        setUserAccounts(updatedUserAccounts);
    };

    const filteredUserRoles = Object.values(UserRoles).filter(
        (role) => role !== UserRoles.Unknown
    );

    const updateUserAccountsWithResponse = (userAccount: UserAccount) => {
        const userAccountIndex = userAccounts.findIndex(
            (user) => user.id === userAccount.id
        );

        if (userAccountIndex === -1) {
            return;
        }

        const updatedUserAccounts = [...userAccounts];
        updatedUserAccounts[userAccountIndex] = userAccount;
        setUserAccounts(updatedUserAccounts);
    };

    const updateUserAsync = async (
        userAccount: UserAccount
    ): Promise<UserAccount | undefined> => {
        try {
            const userAccountRequestMapper = new UserAccountRequestMapper();
            const userAccountUpdateRequest =
                userAccountRequestMapper.mapUserAccountToUpdateUserAccountRequest(
                    userAccount
                );
            const response = await axiosInstance.put<UserAccountResponse>(
                `/api/v1/user-accounts/${userAccount.id}`,
                userAccountUpdateRequest
            );
            const responseData = response.data;
            setIsLoading(false);
            return new UserAccountResponseMapper().mapAccountResponseToUserAccount(
                responseData
            );
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === HttpStatusCode.Unauthorized) {
                    return;
                }
            }
            return;
        }
    };

    const clickSaveButton = async (userAccount: UserAccount) => {
        setIsLoading(true);
        const updatedUserAccount = await updateUserAsync(userAccount);
        if (updatedUserAccount !== undefined) {
            updateUserAccountsWithResponse(updatedUserAccount);
        }
    };
    const [openDialogIndex, setOpenDialogIndex] = useState<number | null>(null);

    const getSelectStyles = () => {
        return {
            '& input': {
                opacity: 0,
            },
            pr: 3,
            '& .MuiSelect-select': {
                background: 'none',
            },
            opacity: 0.75,
        };
    };

    const navigate = useNavigate();
    return (
        <TableContainer sx={{ maxHeight: '70vh' }}>
            <Table stickyHeader aria-label="admin user table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Date Modified</TableCell>
                        <TableCell>Date Created</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userAccounts.map((user, i) => (
                        <TableRow key={i} hover>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>
                                <TextField
                                    sx={{
                                        '& input': {
                                            background: 'none',
                                        },
                                    }}
                                    size="small"
                                    variant="outlined"
                                    value={user.displayName}
                                    onChange={(event) => {
                                        handleDisplayNameChange(
                                            event.target.value,
                                            i
                                        );
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <FormControl variant="outlined" size="medium">
                                    <Select
                                        name="User Role"
                                        sx={getSelectStyles()}
                                        displayEmpty
                                        value={user.role}
                                        onChange={(event) => {
                                            handleRoleChange(
                                                event.target.value as UserRoles,
                                                i
                                            );
                                        }}>
                                        {filteredUserRoles
                                            .reverse()
                                            .map((role, i) => (
                                                <MenuItem key={i} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl variant="outlined" size="medium">
                                    <Select
                                        sx={getSelectStyles()}
                                        name="Active status"
                                        displayEmpty
                                        value={user.isActive ? 1 : 0}
                                        onChange={(event) => {
                                            const value = Number.parseInt(
                                                event.target.value as string
                                            );
                                            handleActiveStatusChange(value, i);
                                        }}>
                                        <MenuItem value={1}>Active</MenuItem>
                                        <MenuItem value={0}>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell
                                sx={{
                                    '& .MuiTypography-root': {
                                        opacity: '1 !important',
                                    },
                                }}>
                                <Typography
                                    variant="caption"
                                    whiteSpace={'nowrap'}>
                                    {user.dateModified.toLocaleString('en-US')}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    '& .MuiTypography-root': {
                                        opacity: '1 !important',
                                    },
                                }}>
                                <Typography
                                    variant="caption"
                                    whiteSpace={'nowrap'}>
                                    {user.dateCreated.toLocaleString('en-US')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    {user.role !== UserRoles.Admin ? (
                                        <Tooltip
                                            title="View data"
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [
                                                                    0, -10,
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}>
                                            <Button
                                                onClick={() => {
                                                    navigate(
                                                        `/${user.role}/${user.id}/${user.role}`.toLowerCase()
                                                    );
                                                }}>
                                                <ArticleOutlined />
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title="View data"
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [
                                                                    0, -10,
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}>
                                            <Button
                                                onClick={() =>
                                                    navigate(
                                                        `/seller`.toLowerCase()
                                                    )
                                                }>
                                                <ArticleOutlined />
                                            </Button>
                                        </Tooltip>
                                    )}
                                    <Tooltip
                                        title="Change password"
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -10],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}>
                                        <Button
                                            onClick={() => {
                                                setOpenDialogIndex(i);
                                            }}>
                                            <VpnKeyOutlined />
                                        </Button>
                                    </Tooltip>
                                    <AdminChangePasswordDialog
                                        isOpen={openDialogIndex === i}
                                        id={user.id}
                                        role={user.role}
                                        displayName={user.displayName}
                                        email={user.email}
                                        setIsOpen={() =>
                                            setOpenDialogIndex(null)
                                        }
                                    />
                                    <Button
                                        onClick={() => {
                                            clickSaveButton(user);
                                        }}
                                        disabled={!user.isEdited || isLoading}>
                                        Save
                                    </Button>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AdminUsersTable;
