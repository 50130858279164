import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    Box,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { PriceFloor } from '../../features/ssp/PriceFloor';

import { useDispatch } from 'react-redux';
import {
    selectSspStatus,
    updateChannelPrice,
} from '../../features/ssp/slice-extensions/ssp-slice';
import { TimeExtensions } from '../../features/ssp/extensions/TimeExtensions';
import NumericThousandSeparatedTextField from '../numeric-thousand-separated-text-field/NumericThousandSeparatedTextField';
import { useAppSelector } from '../../app/hooks';
import { LoadingStatus } from '../../features/ssp/LoadingStatus';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import InfoIcon from '@mui/icons-material/Info';
import UploadPriceFloors from '../upload-price-floors/UploadPriceFloors';
import { DailyLog } from '../../features/ssp/DailyLog';
import { NetworkColorHelper } from '../../features/ssp/NetworkColorHelper';

const inactiveCellGreyShade = 50;
const minutesColumnsGreyShade = 200;
const hourColumnsGreyShade = 400;

interface PriceFloorsTableProps {
    priceFloor: PriceFloor;
    handleUploadPriceFloors: (priceFloors: PriceFloor[]) => void;
    isUploadPriceFloorDisabled: boolean;
    dailyLog?: DailyLog;
}

export default function PriceFloorsTable(props: PriceFloorsTableProps) {
    const isMobile = useMediaQuery('(max-width:920px)');
    const colorHelper = NetworkColorHelper.getInstance();
    const theme = useTheme();
    const sspStatus = useAppSelector(selectSspStatus);
    const getSortedTimes = (): string[] => {
        const times = props.priceFloor.priceFloorSchedules
            .filter((schedule) => schedule.isAvailable)
            .map((schedule) => schedule.time);

        const sortedTimes = new TimeExtensions().sort(times);
        return sortedTimes;
    };

    const dataWithUniqueTimes = getSortedTimes();

    const dispatch = useDispatch();
    const handlePriceChange = (
        priceFloorId: number,
        time: string,
        price: number
    ) => {
        dispatch(
            updateChannelPrice({
                priceFloorId,
                time,
                price,
            })
        );
    };

    const renderRowCell = (time: string, rightBorderColor: string) => {
        const schedule = props.priceFloor.priceFloorSchedules.find(
            (s) => s.time === time
        );

        return (
            <TableCell
                key={time}
                sx={{
                    pr: 0.5,
                    pl: 0.5,
                    backgroundColor: schedule?.isAvailable
                        ? ''
                        : theme.palette.grey[inactiveCellGreyShade],
                    boxSizing: 'border-box',
                }}>
                {schedule?.isAvailable && (
                    <NumericThousandSeparatedTextField
                        onChange={(event) =>
                            handlePriceChange(
                                props.priceFloor.id,
                                time,
                                Number(event.target.value)
                            )
                        }
                        value={schedule?.price}
                        withSign
                        sign="$"
                        disabled={sspStatus === LoadingStatus.Loading}
                        fullWidth
                    />
                )}
            </TableCell>
        );
    };
    const getDatesRange = (startDate: string, endDate: string): string => {
        if (startDate === endDate) {
            return startDate;
        }
        if (startDate === '' || endDate === '') {
            return `${startDate}${endDate}`;
        }
        return `${startDate} - ${endDate}`;
    };
    const isLastOfThisHour = (time: string, times: string[]): boolean => {
        const currentIndex = Math.max(times.lastIndexOf(time), 0);
        if (currentIndex >= times.length - 1) {
            return true;
        }
        const nextTime = times[currentIndex + 1];
        const result = time.split(':')[0] !== nextTime.split(':')[0];
        return result;
    };
    const moneyFormatter = new MoneyFormatter();
    return (
        <Box
            sx={{
                background: (theme) => theme.palette.background.paper,
                p: 2,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'flex-end',
                    flexDirection: isMobile ? 'column' : 'row',
                }}>
                <Box
                    key={props.priceFloor.networkName}
                    sx={{
                        pl: 2,
                        pr: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 1,
                        background: colorHelper.getColor(
                            props.priceFloor.networkName
                        ),
                        border: '1px solid #00000026',
                        minWidth: 'fit-content',
                        height: (theme) => theme.spacing(5),
                        width: (theme) => theme.spacing(20),
                        fontSize: (theme) => theme.spacing(1.75),
                        lineHeight: (theme) => theme.spacing(3),
                    }}>
                    {props.priceFloor.networkName}
                </Box>
                {props.dailyLog && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: (theme) => theme.spacing(1),
                            }}>
                            <Typography
                                typography={'overline'}
                                sx={{
                                    lineHeight: (theme) => theme.spacing(1),

                                    textTransform: 'none',
                                }}>
                                Dates
                            </Typography>
                            <Typography
                                typography={'p'}
                                sx={{
                                    height: (theme) => theme.spacing(5),
                                    width: (theme) =>
                                        isMobile ? 'auto' : theme.spacing(42),
                                    opacity: 0.6,
                                    fontSize: (theme) => theme.spacing(2),
                                    lineHeight: (theme) => theme.spacing(3),
                                    textAlign: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: `${theme.spacing(
                                        0.125
                                    )} solid rgba(0, 0, 0, 0.15)`,
                                    borderRadius: 2,
                                    padding: (theme) => theme.spacing(1),
                                    pr: 1.5,
                                }}>
                                {getDatesRange(
                                    props.dailyLog.dateStart,
                                    props.dailyLog.dateEnd
                                )}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: (theme) => theme.spacing(1),
                            }}>
                            <Typography
                                typography={'overline'}
                                sx={{
                                    lineHeight: (theme) => theme.spacing(1),

                                    textTransform: 'none',
                                }}>
                                Hours
                            </Typography>
                            <Typography
                                typography={'p'}
                                sx={{
                                    height: (theme) => theme.spacing(5),
                                    width: (theme) => theme.spacing(20),
                                    opacity: 0.6,
                                    fontSize: (theme) => theme.spacing(2),
                                    lineHeight: (theme) => theme.spacing(3),
                                    textAlign: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: `${theme.spacing(
                                        0.125
                                    )} solid rgba(0, 0, 0, 0.15)`,
                                    borderRadius: 2,
                                    padding: (theme) => theme.spacing(1),
                                    pr: 1.5,
                                }}>
                                {`${props.dailyLog.hoursStart} - ${props.dailyLog.hoursEnd}`}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 5,
                    height: isMobile ? 'auto' : theme.spacing(5),
                    alignItems: 'center',
                }}>
                <Box>
                    <Typography
                        typography={'overline'}
                        sx={{
                            lineHeight: (theme) => theme.spacing(1),
                            fontSize: (theme) => theme.spacing(1.5),
                            textTransform: 'none',
                        }}>
                        {`${props.priceFloor.advertisedCampaign.brandName}, ${props.priceFloor.advertisedCampaign.name}`}
                    </Typography>
                    <Typography
                        typography={'p'}
                        sx={{
                            lineHeight: (theme) => theme.spacing(3),
                            textAlign: 'left',
                            fontSize: (theme) => theme.spacing(2),
                        }}>
                        {props.priceFloor.advertisedCampaign
                            .buyerDisplayName !== ''
                            ? props.priceFloor.advertisedCampaign
                                  .buyerDisplayName
                            : 'NA'}
                        <Tooltip
                            title={`${props.priceFloor.advertisedCampaign.buyerDisplayName}: ${props.priceFloor.advertisedCampaign.brandName}, ${props.priceFloor.advertisedCampaign.name}`}
                            sx={{
                                color: (theme) =>
                                    `${theme.palette.primary.main} !important`,
                            }}>
                            <IconButton>
                                <InfoIcon
                                    sx={{ width: (theme) => theme.spacing(2) }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: (theme) => theme.spacing(1),
                    }}>
                    <Typography
                        typography={'overline'}
                        sx={{
                            lineHeight: (theme) => theme.spacing(1),
                            fontSize: (theme) => theme.spacing(1.5),
                            textTransform: 'none',
                        }}>
                        Time Between Ads:
                    </Typography>
                    <Typography
                        typography={'p'}
                        sx={{
                            lineHeight: (theme) => theme.spacing(3),
                            textAlign: 'left',
                            fontSize: (theme) => theme.spacing(2),
                        }}>
                        {props.priceFloor.advertisedCampaign.budget
                            ? `${
                                  props.priceFloor.advertisedCampaign
                                      .minMinutesBetweenAds
                              } ${
                                  props.priceFloor.advertisedCampaign
                                      .minMinutesBetweenAds === 1
                                      ? 'min'
                                      : 'mins'
                              }`
                            : undefined}{' '}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: (theme) => theme.spacing(1),
                    }}>
                    <Typography
                        typography={'overline'}
                        sx={{
                            lineHeight: (theme) => theme.spacing(1),
                            fontSize: (theme) => theme.spacing(1.5),
                            textTransform: 'none',
                        }}>
                        Max Budget
                    </Typography>
                    <Typography
                        typography={'p'}
                        sx={{
                            lineHeight: (theme) => theme.spacing(3),
                            textAlign: 'left',
                            fontSize: (theme) => theme.spacing(2),
                        }}>
                        {props.priceFloor.advertisedCampaign.budget
                            ? `${moneyFormatter.format(
                                  props.priceFloor.advertisedCampaign.budget
                              )}`
                            : undefined}
                    </Typography>
                </Box>
            </Box>
            <TableContainer>
                <Table aria-label="price floors table" size="small">
                    <TableHead>
                        <TableRow>
                            {dataWithUniqueTimes.map((time, index) => (
                                <TableCell
                                    sx={{
                                        pr: 0.5,
                                        pl: 0.5,
                                    }}
                                    key={index}>
                                    {time}
                                </TableCell>
                            ))}
                            <TableCell
                                sx={{
                                    pr: 0.5,
                                    pl: 0.5,
                                    minWidth: 110,
                                    boxSizing: 'border-box',
                                }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {dataWithUniqueTimes.map((time) =>
                                renderRowCell(
                                    time,

                                    isLastOfThisHour(time, dataWithUniqueTimes)
                                        ? theme.palette.grey[
                                              hourColumnsGreyShade
                                          ]
                                        : theme.palette.grey[
                                              minutesColumnsGreyShade
                                          ]
                                )
                            )}
                            <TableCell
                                sx={{
                                    whiteSpace: 'nowrap',
                                    boxSizing: 'border-box',
                                    borderTop: 'none',
                                    padding: `0 ${theme.spacing(1)} !important`,
                                    '& .MuiButton-root': {
                                        margin: 0,
                                        padding: 0,
                                        minWidth: 'auto',
                                        minHeight: 'unset',
                                        width: 'max-content',
                                        textAlign: 'center',
                                        fontSize: (theme) => theme.spacing(2),
                                        fontWeight: 600,
                                        height: (theme) => theme.spacing(3),
                                        color: (theme) =>
                                            `${theme.palette.primary.main} !important`,
                                        '& .MuiButton-startIcon': {
                                            marginLeft: 0,
                                        },
                                    },
                                }}>
                                <UploadPriceFloors
                                    onUploaded={props.handleUploadPriceFloors}
                                    disabled={props.isUploadPriceFloorDisabled}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
