import { DailyLogType } from '../DailyLogType';
import { DailyLogTypeRequest } from '../contracts/seller/DailyLogTypeRequest';

export class DailyLogTypeRequestMapper {
    mapEnumDailyLogTypeRequest = (
        dailyLogType: DailyLogType
    ): DailyLogTypeRequest => {
        switch (dailyLogType) {
            case DailyLogType.Break:
                return DailyLogTypeRequest.Break;
            case DailyLogType.Ad:
                return DailyLogTypeRequest.Ad;
            case DailyLogType.Unknown:
                return DailyLogTypeRequest.Unknown;
            default:
                return DailyLogTypeRequest.Unknown;
        }
    };
}
