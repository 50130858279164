import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import axiosInstance from '../../../axios/axiosInstance';
import { IndividualSellerState } from '../../IndividualSellerState';
import { SellerResponseMapper } from '../../mappers/SellerResponseMapper';
import { Seller } from '../../Seller';
import { ObjectState } from '../../../ObjectState';
import { DailyLog } from '../../DailyLog';
import { CreateDailyLogRequest } from '../../contracts/seller/CreateDailyLogRequest';
import { SellerRequestMapper } from '../../mappers/SellerRequestMapper';
import { StringsExtension } from '../../extensions/StringsExtension';
import { CreatePriceFloorRequest } from '../../contracts/seller/CreatePriceFloorRequest';
import { CreatePriceFloorResponse } from '../../contracts/seller/CreatePriceFloorResponse';
import { PriceFloor } from '../../PriceFloor';
import { DailyLogResponse } from '../../contracts/seller/DailyLogResponse';
import { DaypartContractMapper } from '../../mappers/DaypartContractMapper';
import { SellerResponse } from '../../contracts/seller/SellerResponse';
import { UpdatePriceFloorResponse } from '../../contracts/seller/UpdatePriceFloorResponse';
import { loadAvailableNetworksAsyncThunk } from '../buyers/buyer-state-slice-extensions';
import { CampaignBid } from '../../CampaignBid';
import { AxiosError } from 'axios';
import { ProblemDetail } from '../../contracts/ProblemDetail';
import { setStatusMessage } from '../ssp-slice';

export const saveSellerStateAsync = createAsyncThunk(
    'ssp/saveSellerStateAsync',
    async (userId: string | undefined, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const individualSellers = state.ssp.sellersState.individualSellers;
        let individualSellersStates = await createSellersAsync(
            individualSellers
        );
        try {
            individualSellersStates = await createDailyLogsAsync(
                individualSellersStates
            );

            individualSellersStates = await updateDailyLogsAsync(
                individualSellersStates
            );
            individualSellersStates = await deleteDailyLogsAsync(
                individualSellersStates
            );

            individualSellersStates = await createPriceFloorsAsync(
                individualSellersStates,
                state.ssp.sellersState.campaignBids
            );

            individualSellersStates = await updatePriceFloorsAsync(
                individualSellersStates,
                state.ssp.sellersState.campaignBids
            );
            thunkApi.dispatch(loadAvailableNetworksAsyncThunk(userId));
            return individualSellersStates;
        } catch (err) {
            const error = err as AxiosError<ProblemDetail>;
            thunkApi.dispatch(
                setStatusMessage({ message: error.response?.data.detail })
            );
            throw err;
        }
    }
);

export const createSellersAsync = async (
    individualSellersStates: IndividualSellerState[]
): Promise<IndividualSellerState[]> => {
    const newSellers = selectNewSellersPrivate(individualSellersStates);
    if (newSellers.length <= 0) {
        return individualSellersStates;
    }

    for (let seller of newSellers) {
        const body = {
            name: seller.name,
        };
        const response = await axiosInstance.post<SellerResponse>(
            `/api/v1/sellers`,
            body
        );

        const sellersResponse = response.data;
        const createdSeller: Seller = new SellerResponseMapper().toSellerModel(
            sellersResponse
        );

        const updatedSellers = updateIndividualSellersIds(
            individualSellersStates,
            [createdSeller]
        );

        individualSellersStates = individualSellersStates.map(
            (individualSeller) => {
                const updatedSeller = updatedSellers.find((seller) =>
                    new StringsExtension().stringsEqual(
                        seller.name,
                        individualSeller.seller.name
                    )
                );
                if (typeof updatedSeller !== 'undefined') {
                    return {
                        ...individualSeller,
                        seller: updatedSeller,
                    };
                }
                return individualSeller;
            }
        );
    }

    return individualSellersStates;
};

const selectNewSellersPrivate = (
    individualSellers: IndividualSellerState[]
): Seller[] => {
    return individualSellers
        .map((individualSeller) => individualSeller.seller)
        .filter((s) => s.objectState === ObjectState.Added);
};

export const updateIndividualSellersIds = (
    individualSellers: IndividualSellerState[],
    sellers: Seller[]
): Seller[] => {
    const updatedSellers: Seller[] = [];
    individualSellers.forEach((individualSeller) => {
        const matchingSeller = sellers.find((seller) =>
            new StringsExtension().stringsEqual(
                individualSeller.seller.name,
                seller.name
            )
        );

        if (typeof matchingSeller !== 'undefined') {
            updatedSellers.push(matchingSeller);
        }
    });
    return updatedSellers;
};

export const createDailyLogsAsync = async (
    individualSellersStates: IndividualSellerState[]
): Promise<IndividualSellerState[]> => {
    const createDailyLogsRequests = selectAndMapToCreateDailyLogRequests(
        individualSellersStates
    );
    const sellerResponseMapper = new SellerResponseMapper();
    for (let i = 0; i < createDailyLogsRequests.length; i++) {
        const createDailyLogsRequest = createDailyLogsRequests[i];
        const response = await axiosInstance.post<DailyLogResponse>(
            `/api/v1/daily-logs`,
            createDailyLogsRequest
        );
        const addDailyLogsResponse = response.data;

        const createdDailyLogs: DailyLog =
            sellerResponseMapper.toDailyLogModel(addDailyLogsResponse);
        individualSellersStates = updateCreatedDailyLogsPrivate(
            individualSellersStates,
            createdDailyLogs,
            createDailyLogsRequest.ownerId
        );
    }
    return individualSellersStates;
};

export const updateDailyLogsAsync = async (
    individualSellersStates: IndividualSellerState[]
): Promise<IndividualSellerState[]> => {
    const updateDailyLogs = selectDailyLogsByObjectState(
        individualSellersStates,
        ObjectState.Modified
    );
    if (updateDailyLogs.length <= 0) {
        return individualSellersStates;
    }
    const sellerRequestMapper = new SellerRequestMapper();
    for (let i = 0; i < updateDailyLogs.length; i++) {
        const dailyLogs = updateDailyLogs[i];
        const dailyLogsRequest =
            sellerRequestMapper.mapDailyLogsToUpdateDailyLogsRequest(dailyLogs);
        const response = await axiosInstance.put<DailyLogResponse>(
            `/api/v1/daily-logs/${dailyLogs.id}`,
            dailyLogsRequest
        );
        const dailyLogResponse = response.data;

        individualSellersStates = updateDailyLogsPrivate(
            individualSellersStates,
            dailyLogResponse
        );
    }
    return individualSellersStates;
};

export const deleteDailyLogsAsync = async (
    individualSellersStates: IndividualSellerState[]
): Promise<IndividualSellerState[]> => {
    const dailyLogsToDelete = selectDailyLogsByObjectState(
        individualSellersStates,
        ObjectState.Deleted
    );
    if (dailyLogsToDelete.length <= 0) {
        return individualSellersStates;
    }

    for (let i = 0; i < dailyLogsToDelete.length; i++) {
        const dailyLogs = dailyLogsToDelete[i];

        await axiosInstance.delete(`/api/v1/daily-logs/${dailyLogs.id}`);
    }
    const result = individualSellersStates.map((seller) => {
        if (
            !dailyLogsToDelete.some((dailyLogToDelete) =>
                seller.dailyLogsList.includes(dailyLogToDelete)
            )
        ) {
            return seller;
        }
        const modifiedSeller = {
            ...seller,
            dailyLogsList: seller.dailyLogsList.filter(
                (dailyLog) => !dailyLogsToDelete.includes(dailyLog)
            ),
        };
        return modifiedSeller;
    });
    return result;
};

const selectAndMapToCreateDailyLogRequests = (
    individualSellersState: IndividualSellerState[]
): CreateDailyLogRequest[] => {
    const createDailyLogsRequests: CreateDailyLogRequest[] = [];

    for (const individualSellerState of individualSellersState) {
        for (const dailyLogs of individualSellerState.dailyLogsList) {
            if (dailyLogs.objectState === ObjectState.Added) {
                createDailyLogsRequests.push(
                    new SellerRequestMapper().mapDailyLogToCreateDailyLogsRequest(
                        dailyLogs,
                        individualSellerState.seller.id
                    )
                );
            }
        }
    }

    return createDailyLogsRequests;
};

const updateDailyLogsPrivate = (
    individualSellersStates: IndividualSellerState[],
    dailyLogResponse: DailyLogResponse
): IndividualSellerState[] => {
    const sellerResponseMapper = new SellerResponseMapper();
    const updatedDailyLogs =
        sellerResponseMapper.toDailyLogModel(dailyLogResponse);
    const daypartContractMapper = new DaypartContractMapper();
    return individualSellersStates.map((individualSeller) =>
        individualSeller.seller.id === dailyLogResponse.ownerId
            ? {
                  ...individualSeller,
                  dailyLogsList: individualSeller.dailyLogsList.map(
                      (dailyLogs) => {
                          if (
                              dailyLogs.networkName ===
                                  dailyLogResponse.networkName &&
                              daypartContractMapper.toContract(
                                  dailyLogs.daypart
                              ) === dailyLogResponse.daypart
                          ) {
                              return {
                                  ...updatedDailyLogs,
                              };
                          }
                          return dailyLogs;
                      }
                  ),
              }
            : individualSeller
    );
};

const updateCreatedDailyLogsPrivate = (
    individualSellersStates: IndividualSellerState[],
    createdDailyLogs: DailyLog,
    sellerId: number
): IndividualSellerState[] => {
    return individualSellersStates.map((individualSellerState) => {
        if (individualSellerState.seller.id === sellerId) {
            const existingDailyLogsIndex = findSameDailyLogsIndexPrivate(
                individualSellerState.dailyLogsList,
                createdDailyLogs
            );

            if (existingDailyLogsIndex !== -1) {
                return {
                    ...individualSellerState,
                    dailyLogsList: individualSellerState.dailyLogsList.map(
                        (dailyLogs, index) => {
                            if (index === existingDailyLogsIndex) {
                                return {
                                    ...createdDailyLogs,
                                };
                            }

                            return dailyLogs;
                        }
                    ),
                };
            }
        }
        return individualSellerState;
    });
};

const selectDailyLogsByObjectState = (
    individualSellerStates: IndividualSellerState[],
    objectState: ObjectState
): DailyLog[] => {
    return individualSellerStates.flatMap((state) => {
        return state.dailyLogsList.filter(
            (dailyLog) => dailyLog.objectState === objectState
        );
    });
};

const findSameDailyLogsIndexPrivate = (
    dailyLogsList: DailyLog[],
    dailyLogs: DailyLog
): number => {
    const stringsExtension = new StringsExtension();
    return dailyLogsList.findIndex(
        (dailyLog) =>
            stringsExtension.stringsEqual(
                dailyLog.dateStart,
                dailyLogs.dateStart
            ) &&
            stringsExtension.stringsEqual(
                dailyLog.dateEnd,
                dailyLogs.dateEnd
            ) &&
            dailyLog.daypart === dailyLogs.daypart &&
            stringsExtension.stringsEqual(
                dailyLog.hoursStart,
                dailyLogs.hoursStart
            ) &&
            stringsExtension.stringsEqual(
                dailyLog.hoursEnd,
                dailyLogs.hoursEnd
            ) &&
            stringsExtension.stringsEqual(
                dailyLog.networkName,
                dailyLogs.networkName
            )
    );
};

export const createPriceFloorsAsync = async (
    individualSellersStates: IndividualSellerState[],
    campaigns: CampaignBid[]
): Promise<IndividualSellerState[]> => {
    const createPriceFloors = selectCreatedPriceFloorsRequestsPrivate(
        individualSellersStates
    );
    if (createPriceFloors.length <= 0) {
        return individualSellersStates;
    }
    const createResponses = await Promise.all(
        createPriceFloors.map(async (createPriceFloorRequest) => {
            const response = await axiosInstance.post<CreatePriceFloorResponse>(
                '/api/v1/price-floors',
                createPriceFloorRequest
            );
            return response.data;
        })
    );
    createResponses.forEach((createResponse) => {
        const priceFloor =
            new SellerResponseMapper().mapCreatePriceFloorResponseToPriceFloor(
                createResponse,
                campaigns
            );
        individualSellersStates = updatePriceFloorsPrivate(
            individualSellersStates,
            priceFloor,
            createResponse.ownerId
        );
    });

    return individualSellersStates;
};

const selectCreatedPriceFloorsRequestsPrivate = (
    individualSellersState: IndividualSellerState[]
): CreatePriceFloorRequest[] => {
    const createPriceFloorRequest: CreatePriceFloorRequest[] = [];

    for (const individualSellerState of individualSellersState) {
        for (const priceFloor of individualSellerState.priceFloors) {
            if (priceFloor.objectState === ObjectState.Added) {
                createPriceFloorRequest.push(
                    new SellerRequestMapper().mapCreatePriceFloorRequest(
                        priceFloor,
                        individualSellerState.seller.id
                    )
                );
            }
        }
    }

    return createPriceFloorRequest;
};

const selectUpdatedPriceFloorsRequestsPrivate = (
    individualSellersState: IndividualSellerState[]
): PriceFloor[] => {
    return individualSellersState.flatMap((individualSellerState) =>
        individualSellerState.priceFloors.filter(
            (priceFloor) => priceFloor.objectState === ObjectState.Modified
        )
    );
};

const updatePriceFloorsPrivate = (
    individualSellersStates: IndividualSellerState[],
    updatePriceFloor: PriceFloor,
    sellerId: number
): IndividualSellerState[] => {
    return individualSellersStates.map((individualSellerState) => {
        if (individualSellerState.seller.id === sellerId) {
            const existingPriceFloorIndex = findSamePriceFloorIndexPrivate(
                individualSellerState.priceFloors,
                updatePriceFloor
            );

            if (existingPriceFloorIndex !== -1) {
                return {
                    ...individualSellerState,
                    priceFloors: individualSellerState.priceFloors.map(
                        (priceFloor, i) =>
                            i === existingPriceFloorIndex
                                ? updatePriceFloor
                                : priceFloor
                    ),
                };
            }
        }

        return individualSellerState;
    });
};

const findSamePriceFloorIndexPrivate = (
    priceFloorList: PriceFloor[],
    priceFLoor: PriceFloor
): number => {
    const stringsExtension = new StringsExtension();
    return priceFloorList.findIndex(
        (existPriceFloor) =>
            stringsExtension.stringsEqual(
                existPriceFloor.networkName,
                priceFLoor.networkName
            ) &&
            stringsExtension.stringsEqual(
                existPriceFloor.advertisedCampaign.productName,
                priceFLoor.advertisedCampaign.productName
            ) &&
            stringsExtension.stringsEqual(
                existPriceFloor.advertisedCampaign.brandName,
                priceFLoor.advertisedCampaign.brandName
            ) &&
            stringsExtension.stringsEqual(
                existPriceFloor.advertisedCampaign.name,
                priceFLoor.advertisedCampaign.name
            ) &&
            existPriceFloor.daypart === priceFLoor.daypart &&
            stringsExtension.stringsEqual(
                existPriceFloor.hoursStart,
                priceFLoor.hoursStart
            ) &&
            stringsExtension.stringsEqual(
                existPriceFloor.hoursEnd,
                priceFLoor.hoursEnd
            )
    );
};

export const updatePriceFloorsAsync = async (
    individualSellersStates: IndividualSellerState[],
    campaigns: CampaignBid[]
): Promise<IndividualSellerState[]> => {
    const updatePriceFloors = selectUpdatedPriceFloorsRequestsPrivate(
        individualSellersStates
    );
    if (updatePriceFloors.length <= 0) {
        return individualSellersStates;
    }
    const updateResponses = await Promise.all(
        updatePriceFloors.map(async (updatePriceFloor) => {
            const updatePriceFloorRequest =
                new SellerRequestMapper().mapModelToUpdatePriceFloorRequest(
                    updatePriceFloor
                );
            const response = await axiosInstance.put<UpdatePriceFloorResponse>(
                `/api/v1/price-floors/${updatePriceFloor.id}`,
                updatePriceFloorRequest
            );
            return response.data;
        })
    );
    updateResponses.forEach((updateResponse) => {
        const priceFloor =
            new SellerResponseMapper().mapUpdatePriceFloorResponseToPriceFloor(
                updateResponse,
                campaigns
            );
        individualSellersStates = updatePriceFloorsPrivate(
            individualSellersStates,
            priceFloor,
            updateResponse.ownerId
        );
    });

    return individualSellersStates;
};
