import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    loadInitialAuthenticationStateAsync,
    selectInitializationStatus,
} from '../../features/authentication/authenticationSlice';

const InitializeAuth = ({ children }: { children: JSX.Element }) => {
    const initializationStatus = useAppSelector(selectInitializationStatus);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (initializationStatus === 'not-initialized') {
            dispatch(loadInitialAuthenticationStateAsync());
        }
    }, [dispatch, initializationStatus]);

    if (initializationStatus === 'initialized') {
        return children;
    }

    return <></>;
};
export default InitializeAuth;
