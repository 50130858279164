import { Box, Tab, Tabs } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    importPriceFloors,
    selectBuyerCampaigns,
    selectSspStatus,
} from '../../features/ssp/slice-extensions/ssp-slice';
import { PriceFloor } from '../../features/ssp/PriceFloor';
import { Campaign } from '../../features/ssp/Campaign';
import { BuyerCampaign } from '../../features/ssp/BuyerCampaign';
import { StringsExtension } from '../../features/ssp/extensions/StringsExtension';
import { LoadingStatus } from '../../features/ssp/LoadingStatus';
import PriceFloorsTable from '../../components/price-floors-table/PriceFloorsTable';
import { IndividualSellerState } from '../../features/ssp/IndividualSellerState';
import { useState } from 'react';

export interface SellerFormProps {
    individualSeller: IndividualSellerState;
}

export const SellerForm = ({ individualSeller }: SellerFormProps) => {
    const buyerCampaigns: BuyerCampaign[] =
        useAppSelector(selectBuyerCampaigns) ?? [];
    const sspStatus = useAppSelector(selectSspStatus);
    const dispatch = useAppDispatch();
    const [selectedTab, setSelectedTab] = useState<number>();

    const handleChange = (_: any, newValue: number) => {
        setSelectedTab(newValue);
    };

    const isDisabled = sspStatus === LoadingStatus.Loading;

    const handleUploadPriceFloors = (
        priceFloors: PriceFloor[],
        sellerName: string
    ) => {
        const existedCampaignNames: string[] =
            getMatchingCampaignNames(priceFloors);
        const filteredPriceFloors: PriceFloor[] = priceFloors.filter(
            (priceFloor) => {
                return existedCampaignNames.includes(
                    priceFloor.advertisedCampaign.name
                );
            }
        );
        dispatch(
            importPriceFloors({
                priceFloors: filteredPriceFloors,
                sellerName: sellerName,
            })
        );
    };

    const getMatchingCampaignNames = (priceFloors: PriceFloor[]): string[] => {
        const currentCampaigns: Campaign[] = buyerCampaigns.flatMap(
            (buyerCampaign) => buyerCampaign.campaigns
        );

        const existingCampaignNames: string[] = currentCampaigns.flatMap(
            (campaign) => campaign.name
        );

        const newCampaignNames: string[] = priceFloors.flatMap(
            (priceFloor) => priceFloor.advertisedCampaign.name
        );

        const matchingNames: string[] = [];
        const nonMatchingNames: string[] = [];
        const stringExtensions = new StringsExtension();
        newCampaignNames.forEach((newName) => {
            if (
                existingCampaignNames.some((existingName) =>
                    stringExtensions.stringsEqual(existingName, newName)
                )
            ) {
                matchingNames.push(newName);
            } else {
                nonMatchingNames.push(newName);
            }
        });

        if (nonMatchingNames.length > 0) {
            alert(
                `The price floors for the following campaigns could not be uploaded because the campaigns with these names do not exist: ${nonMatchingNames.join(
                    ', '
                )}`
            );
        }

        return matchingNames;
    };

    const getUniqueCompaigns = (priceFloors: PriceFloor[]) => {
        const campaigns = priceFloors.map((x) => ({
            id: x.advertisedCampaign.id,
            name: x.advertisedCampaign.name,
        }));

        const uniqueCampaigns = Array.from(
            new Map(
                campaigns.map((campaign) => [campaign.id, campaign])
            ).values()
        );

        if (!selectedTab && uniqueCampaigns.length > 0) {
            setSelectedTab(uniqueCampaigns.find((x) => x)?.id);
        }

        return uniqueCampaigns;
    };

    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiTabs-root': {
                    minHeight: (theme) => theme.spacing(3.75),
                    alignItems: 'flex-end',
                },
                '& .MuiButtonBase-root': {
                    textTransform: 'none',
                    color: (theme) => theme.palette.primary.dark,
                    minHeight: (theme) => theme.spacing(3.75),
                },
                '& .Mui-selected': {
                    color: (theme) =>
                        `${theme.palette.primary.main} !important`,
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
            <Tabs
                sx={{
                    '& .MuiTabs-flexContainer': {
                        overflow: 'auto',
                    },
                    '& .MuiTab-root ': {
                        padding: (theme) =>
                            `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
                        fontSize: (theme) => theme.spacing(2),
                        whiteSpace: 'nowrap',
                    },
                    '& .MuiTab-root:not(.Mui-selected)': {
                        borderBottom: '1px solid #00000026',
                    },
                }}
                value={selectedTab}
                onChange={handleChange}>
                {getUniqueCompaigns(individualSeller?.priceFloors).map(
                    (campaign) => (
                        <Tab
                            key={campaign.id}
                            value={campaign.id}
                            label={campaign.name}
                        />
                    )
                )}
            </Tabs>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}>
                {individualSeller?.priceFloors.map(
                    (campaign, index) =>
                        selectedTab === campaign.advertisedCampaign.id && (
                            <PriceFloorsTable
                                handleUploadPriceFloors={(
                                    priceFloors: PriceFloor[]
                                ) => {
                                    handleUploadPriceFloors(
                                        priceFloors,
                                        individualSeller?.seller?.name
                                    );
                                }}
                                isUploadPriceFloorDisabled={isDisabled}
                                dailyLog={individualSeller.dailyLogsList.find(
                                    (x) =>
                                        x.networkName === campaign.networkName
                                )}
                                priceFloor={campaign}
                                key={index}
                            />
                        )
                )}
            </Box>
        </Box>
    );
};
