import { Box, Typography } from '@mui/material';
import OptimizationsHistoryTable from '../../components/optimizations-history-table/OptimizationsHistoryTable';

const OptimizationLogs = () => {
    return (
        <Box>
            <Box sx={{ my: 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                    }}>
                    <Typography variant="h4" component="h1">
                        Optimization Logs
                    </Typography>
                </Box>
                <Box sx={{ my: 4 }}>
                    <OptimizationsHistoryTable></OptimizationsHistoryTable>
                </Box>
            </Box>
        </Box>
    );
};

export default OptimizationLogs;
