import { UpdateUserAccountRequest } from '../contracts/UpdateUserAccountRequest';
import { UserAccount } from '../UserAccount';
import { UserRolesRequestMapper } from './UserRolesRequestMapper';

export class UserAccountRequestMapper {
    private userRolesRequestMapper = new UserRolesRequestMapper();

    mapUserAccountToUpdateUserAccountRequest = (
        userAccount: UserAccount
    ): UpdateUserAccountRequest => {
        return {
            role: this.userRolesRequestMapper.mapUserRolesRequestMapper(
                userAccount.role
            )!,
            displayName: userAccount.displayName,
            isActive: userAccount.isActive,
        };
    };
}
