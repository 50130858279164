import { StringsExtension } from './StringsExtension';

export class ParseExtension {
    constructor(private cells: (string | number)[][]) {}
    private stringsExtension = new StringsExtension();

    getHeadValue(attributeName: string): number | string {
        const lowercasedAttributeName = attributeName.toLowerCase();
        const row = this.cells.find((row) =>
            row.find(
                (cell) =>
                    cell?.toString().trim().toLowerCase() ===
                    lowercasedAttributeName.trim()
            )
        );
        if (typeof row !== 'undefined') {
            return this.getMarkerValue(row, lowercasedAttributeName);
        }
        return '';
    }

    isEmptyRow(row: (string | number)[]): boolean {
        return row.every((cell) => cell === '');
    }

    getIndexes(values: string[], cells: (string | number)[]): number[] {
        const indexes: number[] = values.map((value) =>
            cells.findIndex((cell) =>
                this.stringsExtension.stringsEqual(cell, value)
            )
        );
        return indexes;
    }

    getHeadValueInRange(
        attributeName: string,
        markerIndex: number,
        nextMarkerIndex: number
    ): number | string {
        const lowercasedAttributeName = attributeName.toLowerCase();
        const row = this.cells
            .slice(markerIndex - 1, nextMarkerIndex)
            .find((row) =>
                row.find(
                    (cell) =>
                        cell?.toString().trim().toLowerCase() ===
                        lowercasedAttributeName.trim()
                )
            );
        if (typeof row !== 'undefined') {
            return this.getMarkerValue(row, lowercasedAttributeName);
        }
        return '';
    }

    private getMarkerValue = (
        row: (string | number)[],
        attributeName: string
    ): string | number => {
        const lowercasedAttributeName = attributeName.toLowerCase();

        const markerIndex = row.findIndex(
            (cell) => cell?.toString().toLowerCase() === lowercasedAttributeName
        );

        if (markerIndex >= 0 && markerIndex < row.length - 1) {
            const value = row[markerIndex + 1] ?? '';
            return this.stringsExtension.trimIfString(value) ?? '';
        }

        return '';
    };

    getLastRowIndex(): number {
        if (!Array.isArray(this.cells)) {
            return -1;
        }
        if (this.cells.length === 0) {
            return -1;
        }
        return this.cells.length - 1;
    }

    getHeadIndex(value: string): number | undefined {
        const index = this.cells.findIndex((row) =>
            row.find((cell) => this.stringsExtension.stringsEqual(cell, value))
        );
        if (index >= 0) {
            return index;
        }
        return undefined;
    }

    getRowLengthByIndex(rowIndex: number): number {
        if (!Array.isArray(this.cells)) {
            return 0;
        }
        if (rowIndex >= 0 && rowIndex < this.cells.length) {
            return this.cells[rowIndex].length;
        } else {
            return 0;
        }
    }

    parseValueToNumber(value: string | number): number | undefined {
        if (typeof value === 'number') {
            return value;
        }

        if (typeof value === 'string') {
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                return parsedValue;
            }
        }

        return undefined;
    }

    parseHoursStartEnd(value: number | string): string {
        if (typeof value === 'number') {
            const hour24 = Math.floor(24 * value) % 24;
            if (hour24 < 12) {
                return `${hour24}AM`;
            }
            const isNoon = hour24 === 12;
            if (isNoon) {
                return '12noon';
            }
            const afternoonHour = hour24 - 12;
            return `${afternoonHour}PM`;
        }
        return value;
    }
}
