import {
    Box,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { PerformanceEstimate } from '../../features/ssp/PerformanceEstimate';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    importCampaignPerformanceEstimate,
    removePerformanceEstimate,
    selectCampaigns,
    setBuyerCurrentNetworks,
    setPerformanceEstimate,
    setPerformanceEstimateDetails,
} from '../../features/ssp/slice-extensions/ssp-slice';
import UploadBuyerCampaingPerformanceEstimates from '../buyer-campaign-form/UploadBuyerCampaingPerformanceEstimates';
import NumericThousandSeparatedTextField from '../numeric-thousand-separated-text-field/NumericThousandSeparatedTextField';
import { ObjectState } from '../../features/ObjectState';
import { AvailableNetwork } from '../../features/ssp/AvailableNetwork';
import { StringsExtension } from '../../features/ssp/extensions/StringsExtension';
import { Campaign } from '../../features/ssp/Campaign';
import { NetworkColorHelper } from '../../features/ssp/NetworkColorHelper';
import BuyerCancelSaveButtons from '../buyer-cancel-save-buttons/BuyerCancelSaveButtons';
import { useState } from 'react';
import { MoreHoriz, SyncOutlined } from '@mui/icons-material';
export const hourColumnsGreyShade = 400;

interface CampaignPerformanceEstimatesProps {
    performanceEstimates: PerformanceEstimate[];
    campaignId: number;
    availableNetworks: AvailableNetwork[];
    maxDateModified: Date;
    minDate: Date;
    isSaveBarVisible: boolean;
}

export const CampaignPerformanceEstimates = ({
    performanceEstimates,
    campaignId,
    availableNetworks,
    maxDateModified,
    minDate,
    isSaveBarVisible,
}: CampaignPerformanceEstimatesProps) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const inputWidth = 182;
    const colorHelper = NetworkColorHelper.getInstance();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMobile = useMediaQuery('(max-width:920px)');

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const getFilteredPerformanceEstimates = (): PerformanceEstimate[] => {
        const availableNetworkNames = availableNetworks.map(
            (network) => network.networkName
        );

        const filteredPerformanceEstimates = performanceEstimates.filter(
            (performanceEstimate) => {
                return (
                    performanceEstimate.objectState !== ObjectState.Deleted &&
                    availableNetworkNames.includes(
                        performanceEstimate.networkName
                    )
                );
            }
        );

        return filteredPerformanceEstimates;
    };

    const handleClickDeletePerformanceEstimate = (
        performanceEstimateId: number,
        performanceEstimateNetwork: string
    ) => {
        const stringsExtension = new StringsExtension();
        const network = availableNetworks.find((availableNetwork) =>
            stringsExtension.stringsEqual(
                availableNetwork.networkName,
                performanceEstimateNetwork
            )
        );

        if (typeof network !== 'undefined') {
            const newNetworks: AvailableNetwork[] = availableNetworks.filter(
                (availableNetwork) =>
                    !stringsExtension.stringsEqual(
                        availableNetwork.networkName,
                        network.networkName
                    )
            );
            dispatch(
                setBuyerCurrentNetworks({
                    campaignId: campaignId,
                    availableNetworks: newNetworks,
                })
            );
            dispatch(
                removePerformanceEstimate({
                    performanceEstimateId: performanceEstimateId,
                })
            );
        }
    };

    const onUploadExcel = (
        performanceEstimates: PerformanceEstimate[],
        campaignId: number
    ) => {
        if (performanceEstimates.length > 0) {
            const networkNames = availableNetworks?.map(
                (availableNetwork) => availableNetwork.networkName
            );
            dispatch(
                importCampaignPerformanceEstimate({
                    campaignId: campaignId,
                    uploadedPerformanceEstimates: performanceEstimates,
                    networkNames: networkNames,
                })
            );
        }
    };

    const campaigns: Campaign[] = useAppSelector(selectCampaigns);
    const campaign = campaigns.find((campaign) => campaign.id === campaignId);
    const budgetAllocationPercentageFieldName = 'Max Budget Allocation';

    const getBudgetPerNetwork = (performanceEstimate: PerformanceEstimate) => {
        if (typeof campaign === 'undefined') {
            return 0;
        }

        const performanceEstimateBudgetPercentage = isNaN(
            performanceEstimate.budgetPercentage
        )
            ? 0
            : performanceEstimate.budgetPercentage;

        const result =
            ((campaign.budget ?? 0) * performanceEstimateBudgetPercentage) /
            100;
        return result;
    };

    return (
        <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 2,
                    '& .MuiButtonBase-root': {
                        fontSize: (theme) => theme.spacing(2),
                        fontWeight: 600,
                    },
                }}>
                {' '}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                        gap: 2,
                        '& .MuiButtonBase-root': {
                            ml: 0,
                            display: 'flex',
                            gap: 1,
                            '& .MuiButton-icon': {
                                ml: 0,
                                mr: 0,
                            },
                        },
                    }}>
                    <Typography
                        variant="h6"
                        component="h6"
                        sx={{ fontSize: (theme) => theme.spacing(3) }}>
                        Performance Estimates
                    </Typography>

                    <UploadBuyerCampaingPerformanceEstimates
                        onUploaded={(performanceEstimates) => {
                            onUploadExcel(performanceEstimates, campaignId);
                        }}
                    />
                </Box>{' '}
                {isSaveBarVisible && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: 1,
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                            {maxDateModified > minDate ? (
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{
                                        mb: 0,
                                        fontSize: (theme) =>
                                            theme.spacing(1.75),
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center',
                                    }}>
                                    <SyncOutlined
                                        fontSize="small"
                                        sx={{
                                            width: (theme) =>
                                                theme.spacing(1.75),
                                        }}
                                    />
                                    Last saved:{' '}
                                    {maxDateModified.toLocaleString('en-US')}
                                </Typography>
                            ) : (
                                <div></div>
                            )}
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                            <BuyerCancelSaveButtons />
                        </Box>
                    </Box>
                )}
            </Box>

            {!getFilteredPerformanceEstimates()?.length && (
                <Box
                    sx={{
                        background: (theme) => theme.palette.background.paper,
                        p: 2,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: (theme) => theme.spacing(1.75),
                        height: (theme) => theme.spacing(13),
                        gap: 2,
                        opacity: 0.75,
                    }}>
                    There are no performance estimates for this campaign
                </Box>
            )}
            {getFilteredPerformanceEstimates().map(
                (performanceEstimate, performanceEstimateIndex) => {
                    const hasBudgetPercentageError =
                        performanceEstimate.budgetPercentage > 100 ||
                        performanceEstimate.budgetPercentage < 0;

                    return (
                        <Box
                            key={performanceEstimateIndex}
                            sx={{
                                background: (theme) =>
                                    theme.palette.background.paper,
                                p: 2,
                                borderRadius: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                width: isMobile ? 'auto' : 'fit-content',
                                '& .MuiFormControl-root': {
                                    mr: `0 !important`,
                                    width: (theme) =>
                                        isMobile
                                            ? 'auto'
                                            : `${theme.spacing(20)} !important`,
                                },
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column' : 'row',

                                    gap: 2,
                                    alignItems: isMobile
                                        ? 'flex-start'
                                        : 'flex-end',
                                    '& .MuiTextField-root': {
                                        mr: 1,
                                        width: inputWidth,
                                    },
                                }}>
                                <Box>
                                    <Box
                                        key={performanceEstimate.networkName}
                                        sx={{
                                            pl: 2,
                                            pr: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: 1,
                                            background: colorHelper.getColor(
                                                performanceEstimate.networkName
                                            ),
                                            border: '1px solid #00000026',
                                            minWidth: 'fit-content',
                                            height: (theme) => theme.spacing(5),
                                            width: (theme) => theme.spacing(20),
                                            fontSize: (theme) =>
                                                theme.spacing(1.75),
                                            lineHeight: (theme) =>
                                                theme.spacing(3),
                                        }}>
                                        {performanceEstimate.networkName}
                                    </Box>
                                </Box>
                                <Box>
                                    <InputLabel>Daypart</InputLabel>
                                    <TextField
                                        disabled={true}
                                        sx={{
                                            mr: 0,
                                            '& input': {
                                                background: 'transparent',
                                                color: '#050D1C !important',
                                            },
                                        }}
                                        variant="outlined"
                                        value={
                                            performanceEstimate.daypart ?? ''
                                        }
                                        onChange={(event) => {
                                            dispatch(
                                                setPerformanceEstimateDetails({
                                                    performanceEstimateId:
                                                        performanceEstimate.id,
                                                    networkName:
                                                        performanceEstimate.networkName,
                                                    daypart: event.target.value,
                                                    hours: performanceEstimate.hours,
                                                    client: performanceEstimate.client,
                                                    kpi: performanceEstimate.kpi,
                                                    kpiValue:
                                                        performanceEstimate.kpiValue,
                                                    budgetPercentage:
                                                        performanceEstimate.budgetPercentage,
                                                })
                                            );
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Hours</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        value={performanceEstimate.hours ?? ''}
                                        onChange={(event) => {
                                            dispatch(
                                                setPerformanceEstimateDetails({
                                                    performanceEstimateId:
                                                        performanceEstimate.id,
                                                    networkName:
                                                        performanceEstimate.networkName,
                                                    daypart:
                                                        performanceEstimate.daypart,
                                                    hours: event.target.value,
                                                    client: performanceEstimate.client,
                                                    kpi: performanceEstimate.kpi,
                                                    kpiValue:
                                                        performanceEstimate.kpiValue,
                                                    budgetPercentage:
                                                        performanceEstimate.budgetPercentage,
                                                })
                                            );
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexDirection: isMobile ? 'column' : 'row',
                                    alignItems: isMobile
                                        ? 'flex-start'
                                        : 'flex-end',
                                    '& .MuiTextField-root': {
                                        mr: 1,
                                        width: inputWidth,
                                    },
                                }}>
                                <Box>
                                    <InputLabel>KPI</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        value={performanceEstimate.kpi ?? ''}
                                        onChange={(event) => {
                                            dispatch(
                                                setPerformanceEstimateDetails({
                                                    performanceEstimateId:
                                                        performanceEstimate.id,
                                                    networkName:
                                                        performanceEstimate.networkName,
                                                    daypart:
                                                        performanceEstimate.daypart,
                                                    hours: performanceEstimate.hours,
                                                    client: performanceEstimate.client,
                                                    kpi: event.target.value,
                                                    kpiValue:
                                                        performanceEstimate.kpiValue,
                                                    budgetPercentage:
                                                        performanceEstimate.budgetPercentage,
                                                })
                                            );
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <NumericThousandSeparatedTextField
                                        value={performanceEstimate.kpiValue}
                                        helperText="KPI Value"
                                        onChange={(event) => {
                                            const parsedValue = parseFloat(
                                                event.target.value.replace(
                                                    /,/g,
                                                    ''
                                                )
                                            );
                                            dispatch(
                                                setPerformanceEstimateDetails({
                                                    performanceEstimateId:
                                                        performanceEstimate.id,
                                                    networkName:
                                                        performanceEstimate.networkName,
                                                    daypart:
                                                        performanceEstimate.daypart,
                                                    hours: performanceEstimate.hours,
                                                    client: performanceEstimate.client,
                                                    kpi: performanceEstimate.kpi,
                                                    kpiValue: parsedValue,
                                                    budgetPercentage:
                                                        performanceEstimate.budgetPercentage,
                                                })
                                            );
                                        }}
                                        withSign
                                        sign="$"
                                    />
                                </Box>
                                <Box>
                                    <NumericThousandSeparatedTextField
                                        value={
                                            performanceEstimate.budgetPercentage
                                        }
                                        helperText={
                                            hasBudgetPercentageError
                                                ? `${budgetAllocationPercentageFieldName}, % must be between 0 and 100`
                                                : `${budgetAllocationPercentageFieldName}, %`
                                        }
                                        onChange={(event) => {
                                            const parsedValue = parseFloat(
                                                event.target.value.replace(
                                                    /,/g,
                                                    ''
                                                )
                                            );
                                            dispatch(
                                                setPerformanceEstimateDetails({
                                                    performanceEstimateId:
                                                        performanceEstimate.id,
                                                    networkName:
                                                        performanceEstimate.networkName,
                                                    daypart:
                                                        performanceEstimate.daypart,
                                                    hours: performanceEstimate.hours,
                                                    client: performanceEstimate.client,
                                                    kpi: performanceEstimate.kpi,
                                                    kpiValue:
                                                        performanceEstimate.kpiValue,
                                                    budgetPercentage:
                                                        parsedValue,
                                                })
                                            );
                                        }}
                                        error={hasBudgetPercentageError}
                                        withSign
                                        sign="%"
                                    />
                                </Box>
                                <Box>
                                    <NumericThousandSeparatedTextField
                                        value={getBudgetPerNetwork(
                                            performanceEstimate
                                        )}
                                        helperText={`${budgetAllocationPercentageFieldName} Amount`}
                                        disabled
                                        onChange={() => {}}
                                        withSign
                                        sign="$"
                                    />
                                </Box>
                                <Box>
                                    <IconButton onClick={handleMenuOpen}>
                                        <MoreHoriz
                                            sx={{
                                                width: (theme) =>
                                                    theme.spacing(3),
                                                height: (theme) =>
                                                    theme.spacing(3),
                                                fontSize: (theme) =>
                                                    theme.spacing(2),
                                                fontWeight: '400',
                                                lineHeight: (theme) =>
                                                    theme.spacing(2),
                                                textAlign: 'center',
                                                color: (theme) =>
                                                    theme.palette.primary.dark,
                                            }}
                                        />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        sx={{
                                            '& .MuiList-root': { padding: '0' },
                                        }}
                                        onClose={handleMenuClose}>
                                        <MenuItem
                                            sx={{
                                                width: (theme) =>
                                                    theme.spacing(13.25),
                                                height: (theme) =>
                                                    theme.spacing(5.5),
                                                padding: (theme) =>
                                                    `${theme.spacing(2)}`,
                                                fontSize: (theme) =>
                                                    theme.spacing(2),
                                                fontWeight: '500',
                                                lineHeight: (theme) =>
                                                    theme.spacing(2),
                                                textAlign: 'left',
                                                color: '#C50003',
                                                background: 'white',
                                            }}
                                            onClick={() => {
                                                handleClickDeletePerformanceEstimate(
                                                    performanceEstimate.id,
                                                    performanceEstimate.networkName
                                                );
                                                handleMenuClose();
                                            }}>
                                            Delete
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <TableContainer
                                    sx={{
                                        '& .MuiFormControl-root': {
                                            mr: `0 !important`,
                                            width: (theme) =>
                                                `${theme.spacing(
                                                    12
                                                )} !important`,
                                        },
                                    }}>
                                    <Table
                                        aria-label="price floors table"
                                        size="small">
                                        <TableHead>
                                            <TableRow>
                                                {performanceEstimate.estimateSchedules.map(
                                                    (
                                                        estimateSchedule,
                                                        index
                                                    ) => (
                                                        <TableCell
                                                            key={index}
                                                            sx={{
                                                                pr: 0.5,
                                                                pl: 0.5,
                                                            }}>
                                                            {
                                                                estimateSchedule.time
                                                            }
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {performanceEstimate.estimateSchedules.map(
                                                    (
                                                        estimateSchedule,
                                                        index
                                                    ) => (
                                                        <TableCell
                                                            key={index}
                                                            sx={{
                                                                pr: 0.5,
                                                                pl: 0.5,
                                                                borderRight:
                                                                    '1px solid ' +
                                                                    theme
                                                                        .palette
                                                                        .grey[
                                                                        hourColumnsGreyShade
                                                                    ],
                                                            }}>
                                                            <NumericThousandSeparatedTextField
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    dispatch(
                                                                        setPerformanceEstimate(
                                                                            {
                                                                                performanceEstimateId:
                                                                                    performanceEstimate.id,
                                                                                time: estimateSchedule.time,
                                                                                estimate:
                                                                                    event
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                                value={
                                                                    estimateSchedule.estimate
                                                                }
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    );
                }
            )}
        </Box>
    );
};
export default CampaignPerformanceEstimates;
