import { AvailabilityStatus } from './AvailabilityStatus';
import { BuyerCampaign } from './BuyerCampaign';
import { CampaignAdSpaceValue } from './CampaignAdSpaceValue';
import { DailyLogType } from './DailyLogType';
import { Daypart } from './Daypart';
import { ArrayExtensions } from './extensions/ArrayExtensions';
import { InventoryDailyLogs } from './InventoryDailyLogs';
import { OutcomeState } from './OutcomeState';
import { OutcomeSummaryDemand } from './OutcomeSummaryDemand';
import { OutcomeSummaryDemandCampaign } from './OutcomeSummaryDemandCampaign';
import { OutcomeSummaryDemandRow } from './OutcomeSummaryDemandRow';
import { OutcomeSummaryNetwork } from './OutcomeSummaryNetwork';
import { OutcomeSummarySupply } from './OutcomeSummarySupply';
import { OutcomeSummarySupplyRow } from './OutcomeSummarySupplyRow';
import { PerformanceEstimate } from './PerformanceEstimate';
import { TimeExtensions } from './extensions/TimeExtensions';

const grandTotal = 'Grand Total';
export class InventoryDailyLogsService {
    serialize(
        outcome: OutcomeState,
        showPriceFloorColumn: boolean,
        showOutcomeSummaryDemand: boolean,
        showKpiAndPerformanceEstimates: boolean
    ): (string | number)[][] {
        const inventoryDailyLogsList: InventoryDailyLogs[] =
            outcome.inventoryDailyLogsList;
        const outcomeSummarySupply: OutcomeSummarySupply | undefined =
            outcome.outcomeSummarySupply;
        const outcomeSummaryDemand: OutcomeSummaryDemand | undefined =
            showOutcomeSummaryDemand ? outcome.outcomeSummaryDemand : undefined;
        const outcomeSummaryDemandCampaigns:
            | OutcomeSummaryDemandCampaign[]
            | undefined = outcome.outcomeSummaryDemandCampaigns;
        const outcomeSummaryNetworks: OutcomeSummaryNetwork[] =
            outcome.outcomeSummaryNetworks;
        const summary = this.generateSummary(outcome);
        const inventoryDetails = this.generateInventoryDetails(
            inventoryDailyLogsList,
            showPriceFloorColumn
        );
        const outcomeSummary = this.toOutcomeSummaryCells(
            outcomeSummarySupply,
            outcomeSummaryDemand,
            outcomeSummaryDemandCampaigns,
            showKpiAndPerformanceEstimates
        );
        const outcomeNetworksSummary = this.toOutcomeNetworksCells(
            outcomeSummaryNetworks
        );
        const result: (string | number)[][] = [
            ...summary,
            ...outcomeSummary,
            ...outcomeNetworksSummary,
            ...inventoryDetails,
        ];

        return result;
    }

    serializeBuyer = (
        outcome: OutcomeState,
        showPriceFloorColumn: boolean,
        showKpiAndPerformanceEstimates: boolean
    ): (string | number)[][] => {
        const inventoryDailyLogsList: InventoryDailyLogs[] =
            outcome.inventoryDailyLogsList;

        const outcomeSummaryDemand: OutcomeSummaryDemand | undefined =
            outcome.outcomeSummaryDemand;
        const summary = this.generateSummary(outcome);
        const outcomeSummaryDemandCampaigns:
            | OutcomeSummaryDemandCampaign[]
            | undefined = outcome.outcomeSummaryDemandCampaigns;
        const outcomeSummary = this.toOutcomeSummaryCells(
            undefined,
            outcomeSummaryDemand,
            outcomeSummaryDemandCampaigns,
            showKpiAndPerformanceEstimates
        );

        const inventoryDetails = this.generateInventoryDetails(
            inventoryDailyLogsList,
            showPriceFloorColumn
        );

        const result: (string | number)[][] = [
            ...summary,
            ...outcomeSummary,
            ...inventoryDetails,
        ];

        return result;
    };

    toOutcomeNetworksCells(
        outcomeSummaryNetworks: OutcomeSummaryNetwork[]
    ): (string | number)[][] {
        const result: (string | number)[][] = [];
        if (outcomeSummaryNetworks.length > 0) {
            result.push(['By Network']);

            outcomeSummaryNetworks.forEach((summary) => {
                result.push(['', 'Network:', summary.networkName]);
                result.push([]);
                result.push([
                    '',
                    'Agency',
                    'Brand',
                    'Product',
                    '# of Ads Allocated',
                    'Avg Bid',
                    'Allocated Budget',
                    'Avg Price Floor',
                    '% Increase',
                ]);
                summary.rows.forEach((row) => {
                    result.push([
                        '',
                        row.agency,
                        row.brand,
                        row.product,
                        row.noOfAdsAllocated,
                        row.avgBid ?? '',
                        row.totalBids,
                        row.product !== grandTotal
                            ? row.avgPriceFloor ?? ''
                            : '',
                        row.product !== grandTotal ? row.percentIncrease : '',
                    ]);
                });
                result.push([]);
            });
        }
        return result;
    }

    toOutcomeSummaryCells(
        outcomeSummarySupply: OutcomeSummarySupply | undefined,
        outcomeSummaryDemand: OutcomeSummaryDemand | undefined,
        outcomeSummaryDemandCampaigns:
            | OutcomeSummaryDemandCampaign[]
            | undefined,
        showKpiAndPerformanceEstimates: boolean
    ): (string | number)[][] {
        const result: (string | number)[][] = [];
        if (
            typeof outcomeSummarySupply !== 'undefined' ||
            typeof outcomeSummaryDemand !== 'undefined' ||
            (typeof outcomeSummaryDemandCampaigns !== 'undefined' &&
                outcomeSummaryDemandCampaigns.length > 0)
        ) {
            result.push(['Outcome Summary']);
        }

        if (
            typeof outcomeSummarySupply !== 'undefined' &&
            outcomeSummarySupply !== null
        ) {
            result.push(['Supply', '', '', '', '', '*For selected bid']);
            result.push([
                '',
                'Network',
                '# of Ads Listed',
                '# of Ads Sold',
                'Avg Sale Price',
                'Avg Price Floor*',
                '% Increase',
                'Total Revenue',
            ]);
            outcomeSummarySupply.rows.forEach((row) => {
                result.push([
                    '',
                    row.network,
                    row.noOfAds,
                    row.noOfAdsSold,
                    row.avgSalePrice,
                    row.avgPriceFloor,
                    row.percentIncrease,
                    row.totalRevenue,
                ]);
            });
            result.push([]);
        }

        if (
            typeof outcomeSummaryDemand !== 'undefined' &&
            outcomeSummaryDemand !== null
        ) {
            result.push(['Demand']);
            const headers = [
                '',
                'Agency',
                'Brand',
                'Product',
                '# of Ads Allocated',
                'Avg Bid',
                'Allocated Budget',
                'Remaining Budget',
            ];

            if (showKpiAndPerformanceEstimates) {
                headers.push('Avg Perf. Est. per Ad');
                headers.push('KPI');
            }
            result.push(headers);
            outcomeSummaryDemand.rows.forEach((row) => {
                result.push([
                    '',
                    row.agency,
                    row.brand,
                    row.product,
                    row.noOfAdsAllocated,
                    row.avgBidAmount ?? '',
                    row.totalBidAmount,
                    row.remainingBudget ?? '',
                    showKpiAndPerformanceEstimates
                        ? row.avgPerformanceEstimatePerAd ?? ''
                        : '',
                    showKpiAndPerformanceEstimates ? row.kpi ?? '' : '',
                ]);
            });
            result.push([]);
        }

        if (typeof outcomeSummaryDemandCampaigns !== 'undefined') {
            outcomeSummaryDemandCampaigns.forEach(
                (outcomeSummaryDemandCampaign) => {
                    if (
                        typeof outcomeSummaryDemandCampaign !== 'undefined' &&
                        outcomeSummaryDemandCampaign !== null
                    ) {
                        result.push([
                            'Demand - ' +
                                outcomeSummaryDemandCampaign.campaignName,
                        ]);
                        const headers = [
                            '',
                            'Network',
                            '# of Ads Allocated',
                            'Avg Bid',
                            'Max Budget',
                            'Allocated Budget',
                            'Remaining Budget',
                        ];

                        if (showKpiAndPerformanceEstimates) {
                            headers.push('Avg Perf. Est. per Ad');
                            headers.push('KPI');
                        }
                        result.push(headers);
                        outcomeSummaryDemandCampaign.rows.forEach((row) => {
                            result.push([
                                '',
                                row.networkName,
                                row.noOfAdsAllocated,
                                row.avgBidAmount ?? '',
                                row.budget ?? '',
                                row.totalBidAmount,
                                row.remainingBudget ?? '',
                                showKpiAndPerformanceEstimates
                                    ? row.avgPerformanceEstimatePerAd ?? ''
                                    : '',
                                showKpiAndPerformanceEstimates
                                    ? row.kpi ?? ''
                                    : '',
                            ]);
                        });
                        result.push([]);
                    }
                }
            );
        }

        return result;
    }

    toCents(value: number): number {
        return Math.ceil(100 * value) / 100;
    }

    toOutcomeSummaryNetwork(
        optimizedBids: CampaignAdSpaceValue[],
        buyerCampaigns: BuyerCampaign[],
        inventoryDailyLogsList: InventoryDailyLogs[]
    ): OutcomeSummaryNetwork[] {
        const networkOutcomes = Array<OutcomeSummaryNetwork>();
        inventoryDailyLogsList.forEach((dailyLog) => {
            const networkBids = optimizedBids.filter(
                (b) => b.networkName === dailyLog.networkName
            );
            const demandSummary = this.toOutcomeSummaryDemand(
                networkBids,
                buyerCampaigns
            );
            const outcomeSummarySupply = this.toOutcomeSummarySupply([
                dailyLog,
            ]);
            const avgPriceFloor =
                outcomeSummarySupply?.rows?.[0]?.avgPriceFloor;
            const percentIncrease =
                outcomeSummarySupply?.rows?.[0]?.percentIncrease;
            const networkOutcome: OutcomeSummaryNetwork = {
                networkName: dailyLog.networkName,
                rows: demandSummary.rows.map((r) => {
                    return {
                        agency: r.agency,
                        avgBid: r.avgBidAmount,
                        brand: r.brand,
                        noOfAdsAllocated: r.noOfAdsAllocated,
                        product: r.product,
                        totalBids: r.totalBidAmount,
                        avgPriceFloor: avgPriceFloor,
                        percentIncrease,
                    };
                }),
            };
            networkOutcomes.push(networkOutcome);
        });
        return networkOutcomes;
    }

    toOutcomeSummaryDemand(
        optimizedBids: CampaignAdSpaceValue[],
        buyerCampaigns: BuyerCampaign[]
    ): OutcomeSummaryDemand {
        const rows: OutcomeSummaryDemandRow[] = [];

        const campaignIds = this.distinct(
            optimizedBids.map((b) => b.campaignId)
        );
        campaignIds.forEach((campaignId) => {
            const campaignOptimizedBids = optimizedBids.filter(
                (c) => c.campaignId === campaignId
            );
            const buyerCampaign = buyerCampaigns.find(
                (b) =>
                    typeof b.campaigns.find((c) => c.id === campaignId) !==
                    'undefined'
            );
            if (typeof buyerCampaign === 'undefined') return;
            const campaign = buyerCampaign.campaigns.find(
                (c) => c.id === campaignId
            );
            if (typeof campaign === 'undefined') return;

            const agency: string = buyerCampaign.buyer.name;
            const brand: string = campaign.brandName ?? '';
            const product: string = campaign.productName ?? '';
            const noOfAdsAllocated: number = campaignOptimizedBids.length;
            const totalBidAmount: number = campaignOptimizedBids
                .map((c) => c.value)
                .reduce(
                    (previousValue, currentValue) =>
                        previousValue + currentValue,
                    0
                );
            const avgBidAmount: number =
                noOfAdsAllocated > 0
                    ? this.toCents(totalBidAmount / noOfAdsAllocated)
                    : 0;

            const kpi: number = this.getAveragePerformanceEstimateKpiValue(
                campaign.performanceEstimates
            );
            const avgPerformanceEstimatePerAd: number =
                kpi > 0 ? this.toCents(avgBidAmount / kpi) : 0;
            const campaignBudget = campaign.budget ?? 0;
            const remainingBudget: number = campaignBudget - totalBidAmount;
            rows.push({
                agency,
                brand,
                product,
                noOfAdsAllocated,
                budget: campaignBudget,
                totalBidAmount,
                remainingBudget,
                avgBidAmount,
                kpi,
                avgPerformanceEstimatePerAd,
            });
        });
        rows.push({
            agency: '',
            brand: '',
            product: grandTotal,
            noOfAdsAllocated: rows
                .map((r) => r.noOfAdsAllocated)
                .reduce(
                    (previousValue, currentValue) =>
                        previousValue + currentValue,
                    0
                ),
            budget: rows
                .map((r) => r.budget)
                .reduce(
                    (previousValue, currentValue) =>
                        previousValue + currentValue,
                    0
                ),
            totalBidAmount: rows
                .map((r) => r.totalBidAmount)
                .reduce(
                    (previousValue, currentValue) =>
                        previousValue + currentValue,
                    0
                ),
            remainingBudget: rows
                .map((r) => r.remainingBudget)
                .reduce(
                    (previousValue, currentValue) =>
                        previousValue + currentValue,
                    0
                ),
        });

        return { rows: rows };
    }

    toOutcomeSummarySupply(
        inventoryDailyLogsList: InventoryDailyLogs[]
    ): OutcomeSummarySupply {
        const rows: OutcomeSummarySupplyRow[] = [];
        inventoryDailyLogsList.forEach((inventoryDailyLogs) => {
            const networkName = inventoryDailyLogs.networkName;
            const noOfAdsListed = inventoryDailyLogs.logs.filter(
                (l) =>
                    l.status === AvailabilityStatus.Available &&
                    l.logType === DailyLogType.Ad
            ).length;
            const adsSold = inventoryDailyLogs.logs.filter(
                (l) =>
                    l.logType === DailyLogType.Ad &&
                    typeof l.campaignId !== 'undefined'
            );
            const noOfAdsSold = adsSold.length;
            let avgSalePrice =
                noOfAdsListed > 0
                    ? this.toCents(
                          inventoryDailyLogs.logs
                              .filter(
                                  (l) =>
                                      l.logType === DailyLogType.Ad &&
                                      typeof l.campaignId !== 'undefined'
                              )
                              .map((l) => l.bidAmount ?? 0)
                              .reduce(
                                  (previousValue, currentValue) =>
                                      previousValue + currentValue,
                                  0
                              ) / noOfAdsSold
                      )
                    : 0;
            avgSalePrice = isNaN(avgSalePrice) ? 0 : avgSalePrice;
            const noPriceFloors = inventoryDailyLogs.logs.filter(
                (l) =>
                    l.logType === DailyLogType.Ad &&
                    typeof l.priceFloor !== 'undefined'
            );
            const avgPriceFloorAmount =
                noPriceFloors.length > 0
                    ? this.toCents(
                          noPriceFloors
                              .map((p) => p.priceFloor ?? 0)
                              .reduce(
                                  (previousValue, currentValue) =>
                                      previousValue + currentValue,
                                  0
                              ) / noPriceFloors.length
                      )
                    : 0;

            const percentIncrease =
                avgPriceFloorAmount !== 0
                    ? this.toCents(
                          100 * (avgSalePrice / avgPriceFloorAmount - 1)
                      )
                    : 0;
            const totalRevenue = adsSold
                .map((a) => a.bidAmount ?? 0)
                .reduce(
                    (previousValue, currentValue) =>
                        previousValue + currentValue,
                    0
                );
            rows.push({
                avgPriceFloor: avgPriceFloorAmount,
                avgSalePrice: avgSalePrice,
                network: networkName,
                noOfAds: noOfAdsListed,
                noOfAdsSold: noOfAdsSold,
                percentIncrease: percentIncrease,
                totalRevenue: totalRevenue,
            });
        });
        return { rows: rows };
    }

    formatDate(date: Date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [month, day, year].join('/');
    }

    distinct(elements: (string | number)[]): (string | number)[] {
        return new ArrayExtensions().distinct(elements);
    }

    generateSummary(outcome: OutcomeState): (string | number)[][] {
        const result: (string | number)[][] = [];
        const campaignIds =
            outcome.inventoryDailyLogsList.flatMap((m) =>
                m.logs
                    .filter(
                        (l) =>
                            l.campaignId !== undefined && l.campaignId !== null
                    )
                    .map((l) => l.campaignId as string | number)
            ) ?? [];
        const totalCampaigns = this.distinct(campaignIds).length;

        const dayparts = this.distinct(
            outcome.inventoryDailyLogsList.map((d) =>
                this.getDaypartString(d.daypart)
            )
        ).join(', ');

        const hoursStarts = this.distinct(
            outcome.inventoryDailyLogsList.map((d) => d.hoursStart)
        ).join(', ');

        const hoursEnds = this.distinct(
            outcome.inventoryDailyLogsList.map((d) => d.hoursEnd)
        ).join(', ');

        const totalNetworks = this.distinct(
            outcome.inventoryDailyLogsList.map((b) => b.networkName)
        ).length;

        result.push(['DENALI TV']);
        result.push([
            'Date of Optimization',
            this.formatDate(outcome.dateCreated),
        ]);
        result.push(['# of Networks', totalNetworks]);
        result.push(['# of Campaigns', totalCampaigns]);
        result.push(['Daypart', dayparts]);
        if (outcome.dateStart !== '') {
            result.push(['Date Start', outcome.dateStart]);
        }
        if (outcome.dateEnd !== '') {
            result.push(['Date End', outcome.dateEnd]);
        }

        result.push(['Hours Start', hoursStarts]);
        result.push(['Hours End', hoursEnds]);
        result.push([]);
        return result;
    }

    generateInventoryDetails(
        inventoryDailyLogsList: InventoryDailyLogs[],
        showPriceFloorColumn: boolean
    ): (string | number)[][] {
        const timeExtensions = new TimeExtensions();
        const result: (string | number)[][] = [];
        const inventoryDetailHeaders = [
            '',
            'Date',
            'Hour',
            'No.',
            'Type',
            'Offset',
            'Length',
            'Name',
            'Inventory Code',
            'Status',
            'Buyer - Agency',
            'Buyer - Brand',
            'Buyer - Product',
            'Bid Amount',
        ];
        if (showPriceFloorColumn) {
            inventoryDetailHeaders.push('Price Floor');
        }
        inventoryDailyLogsList.forEach((inventoryDailyLogs) => {
            result.push(['Inventory Detail']);
            result.push(['', 'Network', inventoryDailyLogs.networkName]);
            result.push(inventoryDetailHeaders);
            inventoryDailyLogs.logs.forEach((log) => {
                result.push([
                    '',
                    log.date,
                    log.hourBreak,
                    log.no,
                    this.getLogTypeString(log.logType),
                    timeExtensions.toDurationColonFormat(log.offset),
                    timeExtensions.toDurationColonFormat(log.length),
                    log.name,
                    log.inventoryCode,
                    this.getAvailabilityStatusString(log.status),
                    log.buyerName ?? '',
                    log.brandName ?? '',
                    log.productName ?? '',
                    log.bidAmount ?? '',
                    log.priceFloor ?? '',
                ]);
            });

            result.push([]);
            result.push([]);
        });

        return result;
    }

    getDaypartString(daypart: Daypart): string {
        if (daypart === Daypart.Daytime) {
            return 'Daytime';
        }

        return 'Unknown';
    }

    getLogTypeString(logType: DailyLogType): string {
        switch (logType) {
            case DailyLogType.Ad:
                return 'AD';
            case DailyLogType.Break:
                return 'BRK';
        }
        return '';
    }

    getAvailabilityStatusString(status: AvailabilityStatus): string {
        switch (status) {
            case AvailabilityStatus.Available:
                return 'Available';
            case AvailabilityStatus.NotAvailable:
                return 'Not Available';
            case AvailabilityStatus.Unknown:
                return 'Unknown';
        }
        return '';
    }

    private getAveragePerformanceEstimateKpiValue(
        performanceEstimates: PerformanceEstimate[]
    ): number {
        const allPerformanceEstimatesKpiValues: number[] =
            performanceEstimates.map((pe) => pe.kpiValue ?? 0);
        const sum = allPerformanceEstimatesKpiValues.reduce((a, b) => a + b, 0);
        const avg = sum / allPerformanceEstimatesKpiValues.length || 0;
        return avg;
    }
}
