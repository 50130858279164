import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    authenticateAsync,
    selectAuthenticationStatus,
    selectIsAuthenticated,
} from '../../features/authentication/authenticationSlice';
import { Alert } from '@mui/material';

const Login = () => {
    const navigate = useNavigate();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const isAuthenticationFailed =
        useAppSelector(selectAuthenticationStatus) === 'failed';
    const isAuthenticationLoading =
        useAppSelector(selectAuthenticationStatus) === 'loading';
    const dispatch = useAppDispatch();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email: string = data.get('email')?.toString() ?? '';
        const password: string = data.get('password')?.toString() ?? '';
        dispatch(authenticateAsync({ email, password }));
    };
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    useEffect(() => {
        if (isAuthenticated) {
            navigate(from, { replace: true });
        }
    }, [isAuthenticated, from, navigate]);

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    '& .MuiInputLabel-root': {
                        fontSize: (theme) => theme.spacing(2),
                        opacity: 0.75,
                    },
                }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}>
                    <TextField
                        sx={{
                            '& input': {
                                height: (theme) =>
                                    `${theme.spacing(3)} !important`,
                                padding: (theme) =>
                                    `${theme.spacing(2)} ${theme.spacing(
                                        1.75
                                    )} !important`,
                            },
                        }}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        sx={{
                            '& input': {
                                height: (theme) =>
                                    `${theme.spacing(3)} !important`,
                                padding: (theme) =>
                                    `${theme.spacing(2)} ${theme.spacing(
                                        1.75
                                    )} !important`,
                            },
                        }}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    {isAuthenticationFailed ? (
                        <Alert severity="error">
                            Login failed. Check email and password.
                        </Alert>
                    ) : (
                        []
                    )}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isAuthenticationLoading}
                        sx={{ mt: 3, mb: 2 }}>
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};
export default Login;
