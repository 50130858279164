export class PercentFormatter {
    format(amount: number | undefined) {
        if (typeof amount === 'undefined' || amount === null) {
            return '';
        }
        const roundedAmount = Math.round(amount * 10) / 10;

        return (roundedAmount > 0 ? '+' : '') + `${roundedAmount}%`;
    }
}
