import { PriceFloor } from '../../features/ssp/PriceFloor';
import { PriceFloorsParser } from '../../features/ssp/parsers/PriceFloorsParser';
import UploadDocument from '../upload-document/UploadDocument';

interface UploadPriceFloorsProps {
    onUploaded: (priceFloors: PriceFloor[]) => void;
    disabled?: boolean;
}

const UploadPriceFloors = (props: UploadPriceFloorsProps) => {
    const onDocumentUploaded = (cells: (string | number)[][]) => {
        try {
            const priceFloors: PriceFloor[] = new PriceFloorsParser(
                cells
            ).parse();
            props.onUploaded(priceFloors);
        } catch (error) {
            alert(error);
        }
    };
    return (
        <UploadDocument
            buttonText="Upload Price Floors"
            onUploaded={onDocumentUploaded}
            disabled={props.disabled}
            isIconRight></UploadDocument>
    );
};
export default UploadPriceFloors;
