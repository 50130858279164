import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/dm-sans';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const generateTheme = () => {
    const theme = createTheme();
    const createTableCellStyles = () => ({
        color: '#050d1c',
        fontSize: `${theme.spacing(1.75)}`,
        lineHeight: `${theme.spacing(3)}`,
        fontWeight: 400,
        borderBottom: 'none',
        borderRight: `${theme.spacing(0.125)} solid rgba(0, 0, 0, 0.15)`,
        '&:last-child': {
            borderRight: 'none',
        },
    });
    return createTheme({
        ...theme,
        typography: {
            fontFamily: 'DM Sans',
            overline: {
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 1.5,
                fontFamily: 'DM Sans',
            },
        },
        palette: {
            primary: { main: '#4472c4', dark: '#050D1C' },
            secondary: {
                main: '#ffbe28',
                light: '#00C1DE',
                dark: '#0000000D',
            },
            background: {
                default: '#F6F9FE',
                paper: '#EBF0FA',
            },
            text: {
                primary: '#050D1C',
            },
        },
        components: {
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        borderRadius: `${theme.spacing(0.5)}`,
                        border: `${theme.spacing(
                            0.125
                        )} solid rgba(0, 0, 0, 0.15)`,
                        background: '#EBF0FA',
                    },
                },
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        maxWidth: '100%',
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#0000000D',
                        height: `${theme.spacing(4)}`,
                        borderBottom: `${theme.spacing(
                            0.125
                        )} solid rgba(0, 0, 0, 0.15)`,
                    },
                },
            },
            MuiTableBody: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#ffffff',
                        '& .MuiTableRow-root': {
                            height: `${theme.spacing(5)}`,
                            borderBottom: `${theme.spacing(
                                0.125
                            )} solid rgba(0, 0, 0, 0.15)`,
                            '&:last-child': {
                                borderBottom: 'none',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        ...createTableCellStyles(),
                        padding: `0 ${theme.spacing(
                            1
                        )} !important`,
                        opacity: 0.75,
                    },
                    body: {
                        ...createTableCellStyles(),
                        padding: `0 ${theme.spacing(1)} !important`,
                        '& input': {
                            padding: '0 !important',
                            opacity: 0.75,
                        },
                        '& fieldset': {
                            border: 'none !important',
                        },
                        '& .MuiInputBase-adornedStart': {
                            padding: '0 !important',
                        },
                        '& .MuiInputBase-adornedEnd': {
                            padding: '0 !important',
                        },
                        '& .MuiTypography-root': {
                            opacity: '0.75 !important',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        background: '#ffffff',
                        height: `${theme.spacing(3)} !important`,
                        padding: `${theme.spacing(1)} !important`,
                        paddingRight: `${theme.spacing(1.5)} !important`,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: '#050D1C',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: '#050D1C',
                        fontSize: theme.spacing(1.5),
                        marginBottom: theme.spacing(0.5),
                    },
                },
            },
        },
    });
};

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={generateTheme()}>
            <CssBaseline enableColorScheme />
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
