import { Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './components/layout/Layout';
import Login from './components/login/Login';

import NoMatch from './components/no-match/NoMatch';
import RequireAuth from './components/require-auth/RequireAuth';
import InitializeAuth from './components/initialize-auth/InitializeAuth';

import SettingsRoot from './components/settings-root/SettingsRoot';

import { useAppSelector, useAppDispatch } from './app/hooks';
import { loadSellersStateAsync } from './features/ssp/slice-extensions/sellers/seller-state-slice-extensions';
import { useEffect } from 'react';
import {
    selectAuthenticatedRole,
    selectIsAuthenticated,
} from './features/authentication/authenticationSlice';
import { loadBuyersStateAsync } from './features/ssp/slice-extensions/buyers/buyer-state-slice-extensions';
import RequireRoles from './components/require-roles/RequireRoles';
import { UserRoles } from './features/authentication/UserRoles';
import { loadOptimizationsStateAsync } from './features/ssp/slice-extensions/outcome-slice-extensions';
import BuyerHome from './pages/buyer-home/BuyerHome';
import SellerHome from './pages/seller-home/SellerHome';
import AdminHome from './pages/admin/AdminHome';
import OptimizationLogs from './pages/optimization-logs/OptimizationLogs';
import OptimizationLog from './pages/optimization-log/OptimizationLog';
import UserAccounts from './pages/user-accounts/UserAccounts';
import OutcomeHome from './pages/outcome-home/OutcomeHome';
import ChangeAccountPassword from './pages/change-account-password/ChangeAccountPassword';
import ChangeAccountName from './pages/change-account-name/ChangeAccountName';

const App = () => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const role = useAppSelector(selectAuthenticatedRole);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (isAuthenticated === true) {
            if (role === UserRoles.Admin) {
                dispatch(loadSellersStateAsync());
                dispatch(loadBuyersStateAsync());
            } else if (role === UserRoles.Seller) {
                dispatch(loadSellersStateAsync());
            } else if (role === UserRoles.Buyer) {
                dispatch(loadBuyersStateAsync());
            }

            dispatch(loadOptimizationsStateAsync());
        }
    }, [dispatch, role, isAuthenticated]);
    return (
        <InitializeAuth>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route
                        index
                        element={
                            <RequireAuth>
                                <>
                                    <RequireRoles roles={[UserRoles.Admin]}>
                                        <AdminHome />
                                    </RequireRoles>
                                    <RequireRoles roles={[UserRoles.Seller]}>
                                        <SellerHome />
                                    </RequireRoles>
                                    <RequireRoles roles={UserRoles.Buyer}>
                                        <BuyerHome />
                                    </RequireRoles>
                                </>
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="seller/:id?/:role?"
                        element={
                            <RequireAuth
                                roles={[UserRoles.Admin, UserRoles.Seller]}>
                                <SellerHome />
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="buyer/:id?/:role?"
                        element={
                            <RequireAuth
                                roles={[UserRoles.Admin, UserRoles.Buyer]}>
                                <BuyerHome />
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="outcome/:id?/:role?"
                        element={
                            <RequireAuth>
                                <OutcomeHome />
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="seller-outcome/:id?/:role?"
                        element={
                            <RequireAuth>
                                <OutcomeHome parmsRole={UserRoles.Seller} />
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="buyer-outcome/:id?/:role?"
                        element={
                            <RequireAuth>
                                <OutcomeHome parmsRole={UserRoles.Buyer} />
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="settings"
                        element={
                            <RequireAuth>
                                <RequireRoles roles={UserRoles.Admin}>
                                    <SettingsRoot />
                                </RequireRoles>
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="optimization-logs/"
                        element={
                            <RequireAuth>
                                <RequireRoles roles={UserRoles.Admin}>
                                    <OptimizationLogs />
                                </RequireRoles>
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="optimization-log/:id"
                        element={
                            <RequireAuth>
                                <RequireRoles roles={UserRoles.Admin}>
                                    <OptimizationLog />
                                </RequireRoles>
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="user-accounts"
                        element={
                            <RequireAuth>
                                <RequireRoles roles={UserRoles.Admin}>
                                    <UserAccounts />
                                </RequireRoles>
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="/account/change-name"
                        element={
                            <RequireAuth>
                                <ChangeAccountName />
                            </RequireAuth>
                        }
                    />
                    <Route
                        index
                        path="/account/change-password"
                        element={
                            <RequireAuth>
                                <ChangeAccountPassword />
                            </RequireAuth>
                        }
                    />
                    <Route path="login" element={<Login />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </InitializeAuth>
    );
};

export default App;
