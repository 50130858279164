// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { OutcomeState } from '../../features/ssp/OutcomeState';
import { UserRoles } from '../../features/authentication/UserRoles';
import { InventoryDailyLogsService } from '../../features/ssp/InventoryDailyLogsService';
import { useAppSelector } from '../../app/hooks';
import { selectAuthenticatedRole } from '../../features/authentication/authenticationSlice';

export interface DownloadOutcomeButtonProps {
    outcomeState: OutcomeState;
    isLoaded: boolean;
}
const DownloadOutcomeButton = (props: DownloadOutcomeButtonProps) => {
    const role = useAppSelector(selectAuthenticatedRole);
    const serializer = new InventoryDailyLogsService();
    const downloadFile = () => {
        /* convert state to workbook */

        const priceFloorEligibleRoles: string[] = [
            UserRoles.Admin,
            UserRoles.Seller,
        ];
        const showKpiAndPerformanceEstimatesRoles: string[] = [
            UserRoles.Admin,
            UserRoles.Buyer,
        ];
        const showOutcomeSummaryDemand: string[] = [
            UserRoles.Admin,
            UserRoles.Buyer,
        ];
        const showPriceFloorColumn = priceFloorEligibleRoles.includes(role);
        const showKpiAndPerformanceEstimates =
            showKpiAndPerformanceEstimatesRoles.includes(role);
        const cells = serializer.serialize(
            props.outcomeState,
            showPriceFloorColumn,
            showOutcomeSummaryDemand.includes(role),
            showKpiAndPerformanceEstimates
        );
        const ws = XLSX.utils.aoa_to_sheet(cells);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Optimization');
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, 'DenaliTV-Optimization.xlsx');
    };

    return (
        <Button
            sx={{
                color: '#FFFFFF',
                borderRadius: 2,
                fontSize: 16,
                fontWeight: 600,
                height: 32,
                lineHeight: 3,
                background: (theme) => `${theme.palette.primary.main}`,
                padding: (theme) => `${theme.spacing(0.5)} ${theme.spacing(1)}`,
                '& .MuiButton-startIcon': {
                    ml: 0,
                },
                '&:hover': {
                    color: (theme) => `${theme.palette.primary.main}`,
                },
            }}
            disabled={!props.isLoaded}
            startIcon={<CloudDownloadOutlinedIcon />}
            onClick={downloadFile}>
            Download
        </Button>
    );
};
export default DownloadOutcomeButton;
