import { createAsyncThunk } from '@reduxjs/toolkit';
import { SellersState } from '../../SellersState';
import axiosInstance from '../../../axios/axiosInstance';

import { IndividualSellerState } from '../../IndividualSellerState';
import { PriceFloorResponse } from '../../contracts/seller/PriceFloorResponse';
import { SellerResponseMapper } from '../../mappers/SellerResponseMapper';
import { Seller } from '../../Seller';
import { IdGenerator } from '../../IdGenerator';
import { ObjectState } from '../../../ObjectState';
import { DailyLogResponse } from '../../contracts/seller/DailyLogResponse';
import { SellerResponse } from '../../contracts/seller/SellerResponse';
import { CampaignBidResponse } from '../../contracts/seller/CampaignBidResponse';
import { StringsExtension } from '../../extensions/StringsExtension';

export const loadSellersStateAsync = createAsyncThunk(
    'ssp/loadSellersState',
    async (userId: string | undefined, thunkApi) => {
        var sellersResponse = await loadSellersAsync(userId);
        var campaignBidResponses = await loadCampaignBidsAsync(userId);

        const sellerResponseMapper = new SellerResponseMapper();

        const sellerState: SellersState = {
            individualSellers: [],
            campaignBids: campaignBidResponses.map(
                sellerResponseMapper.toCampaignBidModel
            ),
        };

        if (typeof sellersResponse !== 'undefined') {
            const priceFloorsResponse = (
                await thunkApi.dispatch(loadPriceFloorsAsync(userId))
            ).payload as PriceFloorResponse[];
            const sellersDailyLogsResponse = (
                await thunkApi.dispatch(loadSellersDailyLogsAsync(userId))
            ).payload as DailyLogResponse[];

            sellersResponse.forEach((sellerResponse) => {
                const sellerDailyLogs = sellersDailyLogsResponse.filter(
                    (logs) => logs.ownerId === sellerResponse.id
                );

                const sellerPriceFloorsResponses =
                    findPriceFLoorWithSellerIdPrivate(
                        sellerResponse.id,
                        priceFloorsResponse
                    );

                const individualSellerState: IndividualSellerState = {
                    seller: sellerResponseMapper.toSellerModel(sellerResponse),
                    dailyLogsList:
                        sellerResponseMapper.toDailyLogsModel(
                            sellerDailyLogs
                        ) ?? [],
                    priceFloors:
                        sellerResponseMapper.toPriceFloorsModel(
                            sellerPriceFloorsResponses,
                            sellerState.campaignBids
                        ) ?? [],
                };
                sellerState.individualSellers.push(individualSellerState);
            });
        }
        return sellerState;
    }
);

const loadSellersAsync = async (userId: string | undefined) => {
    const response = await axiosInstance.get<SellerResponse[]>(
        `/api/v1/sellers` +
            new StringsExtension().returnIfNotEmpty(
                userId,
                `?userId=${userId}`
            ),
        {
            headers: {
                ContentType: 'application/json',
            },
        }
    );

    return response.data;
};

const loadCampaignBidsAsync = async (userId: string | undefined) => {
    const response = await axiosInstance.get<CampaignBidResponse[]>(
        `/api/v1/campaign-bids` +
            new StringsExtension().returnIfNotEmpty(
                userId,
                `?userId=${userId}`
            ),
        {
            headers: {
                ContentType: 'application/json',
            },
        }
    );

    return response.data;
};

const loadSellersDailyLogsAsync = createAsyncThunk(
    'ssp/loadSellersDailyLogs',
    async (userId: string | undefined) => {
        const response = await axiosInstance.get<DailyLogResponse[]>(
            `/api/v1/daily-logs` +
                new StringsExtension().returnIfNotEmpty(
                    userId,
                    `?userId=${userId}`
                ),
            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );

        return response.data;
    }
);

const findPriceFLoorWithSellerIdPrivate = (
    sellerId: number,
    priceFloorsResponse: PriceFloorResponse[]
): PriceFloorResponse[] => {
    const result: PriceFloorResponse[] = priceFloorsResponse.filter(
        (priceFloorResponse) => priceFloorResponse.ownerId === sellerId
    );

    return result;
};

export const addSellerPrivate = (state: SellersState) => {
    if (typeof state.individualSellers === 'undefined')
        state.individualSellers = [];
    const seller: Seller = {
        name: `Seller ${state.individualSellers?.length + 1}`,
        id: IdGenerator.generate(),
        objectState: ObjectState.Added,
    };
    const individualSeller = {
        seller: seller,
        dailyLogsList: [],
        priceFloors: [],
    };
    state.individualSellers.push(individualSeller);
};

const loadPriceFloorsAsync = createAsyncThunk(
    'ssp/loadPriceFloors',
    async (userId: string | undefined) => {
        const response = await axiosInstance.get<PriceFloorResponse[]>(
            `/api/v1/price-floors` +
                new StringsExtension().returnIfNotEmpty(
                    userId,
                    `?userId=${userId}`
                ),
            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );

        return response.data;
    }
);
