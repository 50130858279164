import { Alert, Box, Button } from '@mui/material';
import { loadSellersStateAsync } from '../../features/ssp/slice-extensions/sellers/seller-state-slice-extensions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectSspStatus,
    hasSellersChanges,
    selectStatusMessage,
} from '../../features/ssp/slice-extensions/ssp-slice';
import { saveSellerStateAsync } from '../../features/ssp/slice-extensions/sellers/save-seller-state-slice-extensions';
import { LoadingStatus } from '../../features/ssp/LoadingStatus';
import { useParams } from 'react-router-dom';

const SellerCancelSaveButtons = () => {
    const params = useParams();
    const dispatch = useAppDispatch();

    const sspStatus = useAppSelector(selectSspStatus);
    const statusMessage = useAppSelector(selectStatusMessage);

    const hasChanges = useAppSelector(hasSellersChanges);
    const isDisabled =
        sspStatus === LoadingStatus.Loading || hasChanges === false;

    const cancelClick = async () => {
        await dispatch(loadSellersStateAsync());
    };

    const saveClick = async () => {
        await dispatch(saveSellerStateAsync(params.id));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            }}>
            {sspStatus === LoadingStatus.Failed && (
                <Alert severity="error">
                    {statusMessage ?? 'Unexpected error has occurred'}
                </Alert>
            )}
            <Button
                disabled={isDisabled}
                onClick={cancelClick}
                sx={{
                    width: (theme) => theme.spacing(12),
                    border: '1px solid',
                    borderRadius: 2,
                    padding: (theme) =>
                        `${theme.spacing(0.5)} ${theme.spacing(1)}`,
                    borderColor: '#00000026',
                    fontSize: (theme) => theme.spacing(2),
                    color: (theme) => theme.palette.text.primary,
                    height: (theme) => theme.spacing(4),
                    '&.Mui-disabled': {
                        color: (theme) => theme.palette.text.primary,
                        opacity: 0.75,
                        borderColor: '#00000026',
                    },
                }}>
                Cancel
            </Button>
            <Button
                disabled={isDisabled}
                onClick={saveClick}
                sx={{
                    width: (theme) => theme.spacing(12),
                    border: '1px solid',
                    borderRadius: 2,
                    fontSize: (theme) => theme.spacing(2),
                    padding: (theme) =>
                        `${theme.spacing(0.5)} ${theme.spacing(1)}`,
                    background: (theme) => theme.palette.primary.main,
                    color: 'white',
                    '&:hover': {
                        color: (theme) => `${theme.palette.primary.main}`,
                    },
                    height: (theme) => theme.spacing(4),
                    '&.Mui-disabled': {
                        color: `white`,
                        opacity: 0.75,
                        background: (theme) => theme.palette.primary.main,
                    },
                }}>
                Save
            </Button>
        </Box>
    );
};

export default SellerCancelSaveButtons;
