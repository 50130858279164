import { ObjectState } from '../../../ObjectState';
import { DailyLog } from '../../DailyLog';
import { SellersState } from '../../SellersState';
import { StringsExtension } from '../../extensions/StringsExtension';

export const importDailyLogsPrivate = (
    dailyLogs: DailyLog,
    state: SellersState,
    sellerId: number
): void => {
    if (typeof dailyLogs === 'undefined') {
        return;
    }
    const individualSeller = state.individualSellers.find(
        (seller) => seller.seller.id === sellerId
    );
    if (typeof individualSeller === 'undefined') {
        throw new Error(
            `The supplier of the network '${dailyLogs.networkName}' is not registered.`
        );
    }
    const stringsExtension = new StringsExtension();
    individualSeller.dailyLogsList ??= [];
    const existingDailyLogs = individualSeller.dailyLogsList.find((d) =>
        stringsExtension.stringsEqual(d.networkName, dailyLogs.networkName)
    );
    if (typeof existingDailyLogs === 'undefined') {
        dailyLogs.objectState = ObjectState.Added;
        individualSeller.dailyLogsList.push(dailyLogs);
    } else {
        if (typeof individualSeller.dailyLogsList !== 'undefined') {
            individualSeller.dailyLogsList.forEach((currentDailyLogs) => {
                if (
                    stringsExtension.stringsEqual(
                        currentDailyLogs.networkName,
                        dailyLogs.networkName
                    )
                ) {
                    currentDailyLogs.daypart = dailyLogs.daypart;
                    currentDailyLogs.hoursStart = dailyLogs.hoursStart;
                    currentDailyLogs.hoursEnd = dailyLogs.hoursEnd;
                    currentDailyLogs.dateStart = dailyLogs.dateStart;
                    currentDailyLogs.dateEnd = dailyLogs.dateEnd;
                    currentDailyLogs.logs = dailyLogs.logs;
                    if (currentDailyLogs.objectState === ObjectState.Original) {
                        currentDailyLogs.objectState = ObjectState.Modified;
                    }
                    if (currentDailyLogs.objectState === ObjectState.Deleted) {
                        currentDailyLogs.objectState = ObjectState.Modified;
                    }
                }
            });
        }
    }
};

export const deleteDailyLogPrivate = (
    id: number,
    state: SellersState
): void => {
    state.individualSellers.forEach((individualSeller) => {
        individualSeller.dailyLogsList = individualSeller.dailyLogsList.filter(
            (dailyLog) =>
                !(
                    dailyLog.id === id &&
                    dailyLog.objectState === ObjectState.Added
                )
        );

        individualSeller.dailyLogsList.forEach((dailyLog) => {
            if (dailyLog.id === id) {
                dailyLog.objectState = ObjectState.Deleted;
            }
        });
        const activeNetworkNames = individualSeller.dailyLogsList
            .filter((d) => d.objectState !== ObjectState.Deleted)
            .map((d) => d.networkName);
        individualSeller.priceFloors = individualSeller.priceFloors.filter(
            (p) => activeNetworkNames.includes(p.networkName)
        );
    });
};
