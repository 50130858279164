import { UserRoles } from '../../authentication/UserRoles';
import { UserRolesResponse } from '../contracts/UserRolesResponse';

export class UserRolesResponseMapper {
    mapUserRolesResponseToUserRoles = (
        userRolesResponse: UserRolesResponse
    ): UserRoles => {
        switch (userRolesResponse) {
            case 1:
                return UserRoles.Admin;
            case 2:
                return UserRoles.Seller;
            case 3:
                return UserRoles.Buyer;
            default:
                return UserRoles.Unknown;
        }
    };
}
