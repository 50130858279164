import { SspState } from '../../SspState';
import { PriceFloor } from '../../PriceFloor';
import { PriceFloorSchedule } from '../../PriceFloorSchedule';
import { DailyLog } from '../../DailyLog';
import { DailyLogsExtensions } from '../../DailyLogsExtensions';
import { AdvertisedCampaign } from '../../AdvertisedCampaign';
import { IndividualSellerState } from '../../IndividualSellerState';
import { IdGenerator } from '../../IdGenerator';
import { SellersState } from '../../SellersState';
import { SspDefaultSettings } from '../../SspDefaultSettings';
import { TimeExtensions } from '../../extensions/TimeExtensions';
import { StringsExtension } from '../../extensions/StringsExtension';
import { ObjectState } from '../../../ObjectState';
import { CampaignBid } from '../../CampaignBid';

export const updatePriceFloorTimesPrivate = (
    times: string[],
    networkName: string,
    sellersPriceFloor: IndividualSellerState
) => {
    if (
        sellersPriceFloor.priceFloors !== undefined &&
        sellersPriceFloor.priceFloors.length > 0
    ) {
        sellersPriceFloor.priceFloors.forEach((priceFloor) => {
            if (priceFloor.networkName !== networkName) {
                return;
            }
            const sortedTimes = new TimeExtensions().sort(times);
            const updatedSchedules = sortedTimes.map((time) => {
                const schedule = priceFloor.priceFloorSchedules.find(
                    (s) => s.time === time
                );
                if (typeof schedule !== 'undefined') {
                    return schedule;
                }
                return {
                    time,
                    isAvailable: true,
                    price: SspDefaultSettings.defaultPriceFloorPrice,
                };
            });
            priceFloor.priceFloorSchedules = updatedSchedules;
        });
    }
};

export const setPriceFloorPricePrivate = (
    state: SellersState,
    priceFloorId: number,
    time: string,
    price: number
) => {
    state.individualSellers.forEach((seller) => {
        seller.priceFloors.forEach((priceFloor) => {
            if (priceFloor.id === priceFloorId) {
                const schedule = priceFloor.priceFloorSchedules.find(
                    (schedule) => schedule.time === time
                );
                if (typeof schedule !== 'undefined') {
                    schedule.price = price;
                    priceFloor.objectState = ObjectState.Modified;
                }
            }
        });
    });
};

export const addPriceFloorsIfNotExistPrivate = (
    state: SellersState,
    advertisedCampaign: AdvertisedCampaign
) => {
    state.individualSellers.forEach((seller) => {
        seller.dailyLogsList.forEach((dailyLogs) => {
            createPriceFloorIfNotExistsPrivate(
                seller,
                advertisedCampaign,
                dailyLogs
            );
        });
    });
};

const createPriceFloorIfNotExistsPrivate = (
    individualSeller: IndividualSellerState,
    advertisedCampaign: AdvertisedCampaign,
    dailyLogs: DailyLog
): PriceFloor | undefined => {
    if (typeof individualSeller.priceFloors === 'undefined') {
        individualSeller.priceFloors = [];
    }
    const existingPriceFloor = getExistingPriceFloorPrivate(
        individualSeller,
        advertisedCampaign,
        dailyLogs
    );
    if (typeof existingPriceFloor !== 'undefined') {
        return undefined;
    }
    const priceFloorSchedules = getPriceFloorSchedules(dailyLogs);
    const priceFloor: PriceFloor = {
        id: IdGenerator.generate(),
        advertisedCampaign: advertisedCampaign,
        networkName: dailyLogs.networkName,
        priceFloorSchedules,
        daypart: dailyLogs.daypart,
        hoursStart: dailyLogs.hoursStart,
        hoursEnd: dailyLogs.hoursEnd,
        objectState: ObjectState.Added,
    };
    individualSeller.priceFloors.push(priceFloor);
    return priceFloor;
};

const getExistingPriceFloorPrivate = (
    individualSeller: IndividualSellerState,
    advertisedCampaign: AdvertisedCampaign,
    dailyLogs: DailyLog
): PriceFloor | undefined => {
    const stringsExtension = new StringsExtension();
    return individualSeller.priceFloors.find(
        (p) =>
            p.advertisedCampaign.id === advertisedCampaign.id &&
            stringsExtension.stringsEqual(
                p.networkName,
                dailyLogs.networkName
            ) &&
            p.daypart === dailyLogs.daypart
    );
};

const getPriceFloorSchedules = (dailyLogs: DailyLog): PriceFloorSchedule[] => {
    return new DailyLogsExtensions()
        .getAvailableTimes(dailyLogs)
        .map((time) => {
            return {
                time: time,
                isAvailable: true,
                price: SspDefaultSettings.defaultPriceFloorPrice,
            };
        });
};

export const updatePriceFloorCampaignDetailsPrivate = (
    state: SspState,
    campaignId: number,
    campaignBid: CampaignBid
) => {
    state.sellersState.campaignBids?.forEach((bid) => {
        if (bid.id === campaignId) {
            bid.brandName = campaignBid.brandName;
            bid.name = campaignBid.name;
            bid.productName = campaignBid.productName;
        }
    });
    state.sellersState.individualSellers?.forEach((sellerPriceFloor) => {
        sellerPriceFloor.priceFloors.forEach((campaignPriceFloor) => {
            if (campaignPriceFloor.advertisedCampaign.id === campaignId) {
                const campaign = campaignPriceFloor.advertisedCampaign;
                if (campaignBid.name) {
                    campaign.name = campaignBid.name;
                }
                if (campaignBid.brandName) {
                    campaign.brandName = campaignBid.brandName;
                }
                if (campaignBid.productName) {
                    campaign.productName = campaignBid.productName;
                }
                campaign.minMinutesBetweenAds =
                    campaignBid.minMinutesBetweenAds;
                campaign.budget =
                    campaignBid.networkBudgets[campaignPriceFloor.networkName];
            }
        });
    });
};

export const removePriceFloorsByCampaignIdPrivate = (
    state: SspState,
    campaignId: number
) => {
    state.sellersState.individualSellers?.forEach((sellerPriceFloor) => {
        sellerPriceFloor.priceFloors = sellerPriceFloor.priceFloors.filter(
            (campaignPriceFloor) =>
                !(
                    campaignPriceFloor.advertisedCampaign.id === campaignId &&
                    campaignPriceFloor.objectState === ObjectState.Added
                )
        );
        sellerPriceFloor.priceFloors.forEach((campaignPriceFloor) => {
            if (campaignPriceFloor.advertisedCampaign.id === campaignId) {
                campaignPriceFloor.objectState = ObjectState.Deleted;
            }
        });
    });
};

export const sortCampaignsPriceFloorsByBuyerIdPrivate = (state: SspState) => {
    state.sellersState.individualSellers?.forEach((sellersPriceFloor) => {
        sellersPriceFloor.priceFloors.sort(
            (a, b) => a.advertisedCampaign.id - b.advertisedCampaign.id
        );
    });
};

export const importPriceFloorsPrivate = (
    state: SspState,
    updatePriceFloors: PriceFloor[],
    sellerName: string
) => {
    const seller = state.sellersState.individualSellers?.find(
        (s) => s.seller.name === sellerName
    );

    if (typeof seller === 'undefined') return;

    const existingPriceFloors = seller.priceFloors;

    if (existingPriceFloors.length === 0) {
        const campaigns = state.sellersState.campaignBids;
        if (typeof campaigns !== 'undefined') {
            updatePriceFloors.forEach((priceFloor) =>
                updatePriceFloorWithCampaignPrivate(
                    priceFloor,
                    priceFloor.advertisedCampaign.name,
                    campaigns
                )
            );
        }
        existingPriceFloors.push(...updatePriceFloors);
        return;
    }
    const stringsExtension = new StringsExtension();
    existingPriceFloors.forEach((priceFloor) => {
        const updatePriceFloor =
            findByPriceFloorByCampaignNameAndNetworkNamePrivate(
                updatePriceFloors,
                priceFloor.advertisedCampaign.name,
                priceFloor.networkName
            );

        if (typeof updatePriceFloor === 'undefined') {
            return;
        }

        if (
            !stringsExtension.stringsEqual(
                priceFloor.advertisedCampaign.name,
                updatePriceFloor?.advertisedCampaign.name
            )
        ) {
            return;
        }

        updatePriceFloor.priceFloorSchedules.forEach((schedule) => {
            const existingSchedule = priceFloor.priceFloorSchedules.find((s) =>
                stringsExtension.stringsEqual(s.time, schedule.time)
            );

            if (typeof existingSchedule !== 'undefined') {
                existingSchedule.price = schedule.price;
            } else {
                priceFloor.priceFloorSchedules.push(schedule);
            }
            priceFloor.objectState = ObjectState.Modified;
        });
    });
};

const findByPriceFloorByCampaignNameAndNetworkNamePrivate = (
    priceFloors: PriceFloor[],
    campaignName: string,
    networkName: string
): PriceFloor | undefined => {
    const stringsExtension = new StringsExtension();
    return priceFloors.find((priceFloor) => {
        return (
            stringsExtension.stringsEqual(
                priceFloor.advertisedCampaign.name,
                campaignName
            ) &&
            stringsExtension.stringsEqual(priceFloor.networkName, networkName)
        );
    });
};

const getCampaignByNamePrivate = (
    campaignBids: CampaignBid[],
    campaignName: string
): CampaignBid | undefined => {
    const stringsExtension = new StringsExtension();
    return campaignBids.find((campaign) => {
        return stringsExtension.stringsEqual(campaign.name, campaignName);
    });
};

const updatePriceFloorWithCampaignPrivate = (
    priceFloor: PriceFloor,
    campaignName: string,
    campaignBids: CampaignBid[]
): PriceFloor => {
    const newCampaign = getCampaignByNamePrivate(campaignBids, campaignName);
    if (typeof newCampaign === 'undefined') return priceFloor;
    priceFloor.advertisedCampaign = {
        brandName: newCampaign.brandName,
        buyerDisplayName: newCampaign.buyerDisplayName,
        id: newCampaign.id,
        name: newCampaign.name,
        productName: newCampaign.productName,
        budget: newCampaign.networkBudgets[priceFloor.networkName],
        minMinutesBetweenAds: newCampaign.minMinutesBetweenAds,
    };
    priceFloor.objectState = ObjectState.Modified;
    return priceFloor;
};
