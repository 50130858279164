import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { OutcomeSummaryNetwork } from '../../features/ssp/OutcomeSummaryNetwork';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import { PercentFormatter } from '../../features/ssp/PercentFormatter';

interface OutcomeSummaryNetworkViewProps {
    outcomeSummaryNetwork: OutcomeSummaryNetwork;
}

const OutcomeSummaryNetworkView = (props: OutcomeSummaryNetworkViewProps) => {
    const moneyFormatter = new MoneyFormatter();
    const percentFormatter = new PercentFormatter();
    return (
        <Box
            sx={{
                background: (theme) => theme.palette.background.paper,
                p: 2,
                borderRadius: 2
            }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {props.outcomeSummaryNetwork.networkName}
            </Typography>
            {props.outcomeSummaryNetwork.rows.length > 0 && (
                <Box>
                    <TableContainer>
                        <Table aria-label="Outcome summary demand" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Agency
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Brand
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Product
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        # Ads Allocated
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Avg Bid
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Allocated Budget
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Avg Price Floor
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Increase
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.outcomeSummaryNetwork.rows.map(
                                    (row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                '& .MuiTableCell-body': {
                                                    fontWeight:
                                                        row.product ===
                                                        'Grand Total'
                                                            ? 'bold'
                                                            : '',
                                                },
                                            }}>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.agency}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.brand}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.product}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.noOfAdsAllocated}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.avgBid
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.totalBids
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.product !== 'Grand Total'
                                                    ? moneyFormatter.format(
                                                          row.avgPriceFloor
                                                      )
                                                    : ''}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.product !== 'Grand Total'
                                                    ? percentFormatter.format(
                                                          row.percentIncrease
                                                      )
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};
export default OutcomeSummaryNetworkView;
