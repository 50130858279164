import { Box } from '@mui/material';
import NavigationItem from '../navigation-item/NavigationItem';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { StringsExtension } from '../../features/ssp/extensions/StringsExtension';
import { selectAuthenticatedRole } from '../../features/authentication/authenticationSlice';
import { UserRoles } from '../../features/authentication/UserRoles';

const NavigationTabControls = () => {
    const params = useParams();
    const navigate = useNavigate();
    const stateRole = useAppSelector(selectAuthenticatedRole);
    const role = params.role ?? stateRole;

    const isAdmin = role === UserRoles.Admin;
    const [titles, setTitles] = useState<string[]>([]);
    const [urls, setUrls] = useState<string[]>([]);
    useEffect(() => {
        const stringsExtension = new StringsExtension();
        const detailViewTitles = stringsExtension.stringsEqual(
            role,
            UserRoles.Admin
        )
            ? [
                  'Seller Inputs',
                  'Buyer Inputs',
                  'Seller Outcome',
                  'Buyer Outcome',
              ]
            : stringsExtension.stringsEqual(role, UserRoles.Seller)
            ? ['Inputs', 'Outcome']
            : ['Inputs', 'Outcome'];

        const detailViewNavigateRoutes = stringsExtension.stringsEqual(
            role,
            UserRoles.Admin
        )
            ? [
                  '/seller' +
                      stringsExtension.returnIfNotEmpty(
                          params.id,
                          `/${params.id}/${params.role}`
                      ),
                  '/buyer' +
                      stringsExtension.returnIfNotEmpty(
                          params.id,
                          `/${params.id}/${params.role}`
                      ),
                  '/seller-outcome' +
                      stringsExtension.returnIfNotEmpty(
                          params.id,
                          `/${params.id}/${params.role}`
                      ),
                  '/buyer-outcome' +
                      stringsExtension.returnIfNotEmpty(
                          params.id,
                          `/${params.id}/${params.role}`
                      ),
              ]
            : stringsExtension.stringsEqual(role, UserRoles.Seller)
            ? [
                  '/seller' +
                      stringsExtension.returnIfNotEmpty(
                          params.id,
                          `/${params.id}/${params.role}`
                      ),
                  '/outcome' +
                      stringsExtension.returnIfNotEmpty(
                          params.id,
                          `/${params.id}/${params.role}`
                      ),
              ]
            : [
                  '/buyer' +
                      stringsExtension.returnIfNotEmpty(
                          params.id,
                          `/${params.id}/${params.role}`
                      ),
                  '/outcome' +
                      stringsExtension.returnIfNotEmpty(
                          params.id,
                          `/${params.id}/${params.role}`
                      ),
              ];
        const isDetailView: boolean = detailViewNavigateRoutes.includes(
            window.location.pathname
        );
        const titles = isDetailView ? detailViewTitles : [];
        const urls = isDetailView ? detailViewNavigateRoutes : [];

        if (!isAdmin) {
            setTitles(detailViewTitles);
            setUrls(detailViewNavigateRoutes);
        } else {
            setTitles(titles);
            setUrls(urls);
        }
    }, [isAdmin, params, role]);

    const isSelected = (pageIndex: number) => {
        return (
            urls.length !== 0 &&
            (urls[pageIndex] === window.location.pathname ||
                (pageIndex === 0 && window.location.pathname === '/'))
        );
    };

    const onClick = (pageIndex: number) => {
        if (urls.length !== 0) {
            navigate(urls[pageIndex]);
        }
    };
    if (titles.length === 0 || urls.length === 0) {
        return null;
    }

    const isArrowVisible = (index: number): boolean => {
        if (isSelected(index) || urls.length === 2) {
            return true;
        }
        return false;
    };
    return (
        <Box
            sx={{
                background: 'rgba(255, 255, 255, 0.25)',
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: 0.5,
                gap: 1,
                height: (theme) => theme.spacing(5),
                flexWrap: 'nowrap',
            }}>
            {urls.map((page, index) => (
                <React.Fragment key={page}>
                    {index > 0 && (
                        <img
                            width={23}
                            src={
                                isArrowVisible(index)
                                    ? '/arrows/horizontalArrow.svg'
                                    : '/arrows/horizontalStick.svg'
                            }
                            alt={
                                isArrowVisible(index)
                                    ? 'selected-arrow'
                                    : 'arrow'
                            }
                            style={{
                                opacity: isArrowVisible(index) ? 1 : 0.5,
                                transform: isArrowVisible(index)
                                    ? 'scale(1)'
                                    : 'scale(0.9)',
                                transition:
                                    'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                            }}
                        />
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <NavigationItem
                            isSelected={isSelected(index)}
                            title={titles[index]}
                            pageIndex={index}
                            onClick={onClick}
                            isUserTab={!isAdmin}
                        />
                    </Box>
                </React.Fragment>
            ))}
        </Box>
    );
};
export default NavigationTabControls;
