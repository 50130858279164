import { DailyLogTypeResponse } from '../contracts/seller/DailyLogTypeResponse';
import { DailyLogType } from '../DailyLogType';

export class DailyLogTypeResponseMapper {
    mapEnumDailyLogTypeResponse = (
        dailyLogTypeResponse: DailyLogTypeResponse
    ): DailyLogType => {
        switch (dailyLogTypeResponse) {
            case DailyLogTypeResponse.Break:
                return DailyLogType.Break;
            case DailyLogTypeResponse.Ad:
                return DailyLogType.Ad;
            case DailyLogTypeResponse.Unknown:
                return DailyLogType.Unknown;
            default:
                return DailyLogType.Unknown;
        }
    };
}
