import { DaypartContract } from '../contracts/DaypartContract';
import { Daypart } from '../Daypart';

export class DaypartResponseMapper {
    mapEnumDaypartResponse = (daypartResponse: DaypartContract): Daypart => {
        switch (daypartResponse) {
            case DaypartContract.Daytime:
                return Daypart.Daytime;
            case DaypartContract.Unknown:
                return Daypart.Unknown;
            default:
                return Daypart.Unknown;
        }
    };
}
