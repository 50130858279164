import { PriceFloor } from '../PriceFloor';
import { DailyLogEntry } from '../DailyLogEntry';
import { DailyLog } from '../DailyLog';
import { PriceFloorSchedule } from '../PriceFloorSchedule';
import { CreateUpdateDailyLogEntryRequest } from '../contracts/seller/CreateUpdateDailyLogEntryRequest';
import { CreateDailyLogRequest } from '../contracts/seller/CreateDailyLogRequest';
import { CreatePriceFloorRequest } from '../contracts/seller/CreatePriceFloorRequest';
import { AvailabilityStatusRequestMapper } from './AvailabilityStatusRequestMapper';
import { DailyLogTypeRequestMapper } from './DailyLogTypeRequestMapper';
import { UpdatePriceFloorRequest } from '../contracts/seller/UpdatePriceFloorRequest';
import { DaypartContractMapper } from './DaypartContractMapper';
import { UpdateDailyLogRequest } from '../contracts/seller/UpdateDailyLogRequest';
import { CreateUpdatePriceFloorScheduleRequest } from '../contracts/seller/CreateUpdatePriceFloorScheduleRequest';

export class SellerRequestMapper {
    private daypartContractMapper = new DaypartContractMapper();
    private dailyLogTypeRequestMapper = new DailyLogTypeRequestMapper();

    private availabilityStatusRequestMapper =
        new AvailabilityStatusRequestMapper();

    mapDailyLogsToUpdateDailyLogsRequest = (
        dailyLogs: DailyLog
    ): UpdateDailyLogRequest => {
        const daypart = this.daypartContractMapper.toContract(
            dailyLogs.daypart
        );
        const hoursStart = dailyLogs.hoursStart;
        const hoursEnd = dailyLogs.hoursEnd;
        const dateStart = dailyLogs.dateStart;
        const dateEnd = dailyLogs.dateEnd;
        const logs = dailyLogs.logs.map(
            this.mapDailyLogEntryToCreateUpdatedDailyLogEntryRequest
        );
        return {
            daypart: daypart,
            hoursStart: hoursStart,
            hoursEnd: hoursEnd,
            dateStart: dateStart,
            dateEnd: dateEnd,
            logs: logs,
        };
    };

    mapDailyLogToCreateDailyLogsRequest = (
        dailyLog: DailyLog,
        sellerId: number
    ): CreateDailyLogRequest => {
        const networkName = dailyLog.networkName;
        const daypart = this.daypartContractMapper.toContract(dailyLog.daypart);
        const hoursStart = dailyLog.hoursStart;
        const hoursEnd = dailyLog.hoursEnd;
        const dateStart = dailyLog.dateStart;
        const dateEnd = dailyLog.dateEnd;
        const logs = this.mapDailyLogEntriesToCreateDailyLogRequests(
            dailyLog.logs
        );
        return {
            id: dailyLog.id,
            ownerId: sellerId,
            networkName: networkName,
            daypart: daypart,
            hoursStart: hoursStart,
            hoursEnd: hoursEnd,
            dateStart: dateStart,
            dateEnd: dateEnd,
            logs: logs,
        };
    };

    mapDailyLogEntriesToCreateDailyLogRequests = (
        dailyLogEntries: DailyLogEntry[]
    ): CreateUpdateDailyLogEntryRequest[] => {
        return dailyLogEntries.map(this.mapDailyLogToCreateDailyLogRequest);
    };

    private mapDailyLogToCreateDailyLogRequest = (
        dailyLog: DailyLogEntry
    ): CreateUpdateDailyLogEntryRequest => {
        const date = dailyLog.date;
        const hourBreak = dailyLog.hourBreak;
        const no = dailyLog.no;
        const logType =
            this.dailyLogTypeRequestMapper.mapEnumDailyLogTypeRequest(
                dailyLog.logType
            );
        const offset = dailyLog.offset;
        const length = dailyLog.length;
        const name = dailyLog.name;
        const inventoryCode = dailyLog.inventoryCode;
        const status =
            this.availabilityStatusRequestMapper.mapEnumAvailabilityStatusRequest(
                dailyLog.status
            );
        return {
            date: date,
            hourBreak: hourBreak,
            no: no,
            logType: logType,
            offset: offset,
            length: length,
            name: name,
            inventoryCode: inventoryCode,
            status: status,
        };
    };

    private mapDailyLogEntryToCreateUpdatedDailyLogEntryRequest = (
        dailyLogEntry: DailyLogEntry
    ): CreateUpdateDailyLogEntryRequest => {
        const date = dailyLogEntry.date;
        const hourBreak = dailyLogEntry.hourBreak;
        const no = dailyLogEntry.no;
        const logType =
            this.dailyLogTypeRequestMapper.mapEnumDailyLogTypeRequest(
                dailyLogEntry.logType
            );
        const offset = dailyLogEntry.offset;
        const length = dailyLogEntry.length;
        const name = dailyLogEntry.name;
        const inventoryCode = dailyLogEntry.inventoryCode;
        const status =
            this.availabilityStatusRequestMapper.mapEnumAvailabilityStatusRequest(
                dailyLogEntry.status
            );
        return {
            date: date,
            hourBreak: hourBreak,
            no: no,
            logType: logType,
            offset: offset,
            length: length,
            name: name,
            inventoryCode: inventoryCode,
            status: status,
        };
    };

    mapCreatePriceFloorRequest = (
        priceFloor: PriceFloor,
        sellerId: number
    ): CreatePriceFloorRequest => {
        const networkName = priceFloor.networkName;
        const daypart = this.daypartContractMapper.toContract(
            priceFloor.daypart
        );
        const advertisedCampaignId = priceFloor.advertisedCampaign.id;
        const hoursStart = priceFloor.hoursStart;
        const hoursEnd = priceFloor.hoursEnd;
        const priceFloorSchedules = priceFloor.priceFloorSchedules.map(
            this.mapPriceFloorScheduleToCreateUpdateRequest
        );
        return {
            ownerId: sellerId,
            networkName: networkName,
            daypart: daypart,
            hoursStart: hoursStart,
            hoursEnd: hoursEnd,
            advertisedCampaignId: advertisedCampaignId,
            priceFloorSchedules: priceFloorSchedules,
        };
    };

    private mapPriceFloorScheduleToCreateUpdateRequest = (
        priceFloorSchedule: PriceFloorSchedule
    ): CreateUpdatePriceFloorScheduleRequest => {
        const time = priceFloorSchedule.time;
        const price = priceFloorSchedule.price;
        const isAvailable = priceFloorSchedule.isAvailable;
        return {
            time: time,
            price: price,
            isAvailable: isAvailable,
        };
    };

    mapModelToUpdatePriceFloorRequest = (
        priceFloor: PriceFloor
    ): UpdatePriceFloorRequest => {
        const networkName = priceFloor.networkName;
        const daypart = this.daypartContractMapper.toContract(
            priceFloor.daypart
        );
        const hoursStart = priceFloor.hoursStart;
        const hoursEnd = priceFloor.hoursEnd;
        const advertisedCampaign = priceFloor.advertisedCampaign.id;
        const priceFloorSchedules = priceFloor.priceFloorSchedules.map(
            this.mapPriceFloorScheduleToCreateUpdateRequest
        );
        return {
            id: priceFloor.id,
            networkName: networkName,
            daypart: daypart,
            hoursStart: hoursStart,
            hoursEnd: hoursEnd,
            advertisedCampaignId: advertisedCampaign,
            priceFloorSchedules: priceFloorSchedules,
        };
    };
}
