import { Box, useMediaQuery } from '@mui/material';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import { PercentFormatter } from '../../features/ssp/PercentFormatter';
import { NetworkColorHelper } from '../../features/ssp/NetworkColorHelper';

interface NetworkSummaryBoxProps {
    networkName: string;
    unitRate: number;
    totalRevenue: number;
}

const NetworkSummaryBox = (props: NetworkSummaryBoxProps) => {
    const moneyFormatter = new MoneyFormatter();
    const percentFormatter = new PercentFormatter();
    const isMobile = useMediaQuery('(max-width:920px)');
    const colorHelper = NetworkColorHelper.getInstance();

    return (
        <Box
            sx={{
                backgroundColor: '#ffffff',
                height: (theme) => theme.spacing(23),
                borderRadius: (theme) => theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: (theme) => theme.spacing(2),
                borderBottom: (theme) => `${theme.spacing(0.5)} solid #050D1C`,
                width: (theme) =>
                    isMobile ? 'auto' : `calc(50% - ${theme.spacing(1.5)})`,
                minWidth: 'fit-content',
                maxWidth: (theme) => theme.spacing(55.5),
            }}>
            <Box
                key={props.networkName}
                sx={{
                    pl: 1,
                    pr: 1,
                    borderRadius: 1,
                    background: colorHelper.getColor(props.networkName),
                    border: '1px solid #00000026',
                    width: 'fit-content',
                    height: (theme) => theme.spacing(3),
                }}>
                {props.networkName}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: (theme) => theme.spacing(1),
                }}>
                <Box
                    sx={{
                        fontSize: (theme) => theme.spacing(1.25),
                        fontWeight: 600,
                        opacity: 0.6,
                        lineHeight: (theme) => theme.spacing(0.875),
                    }}>
                    UNIT RATE
                </Box>
                <Box
                    sx={{
                        textAlign: 'left',
                        fontSize: (theme) => isMobile ? theme.spacing(5) : theme.spacing(10),
                        fontWeight: 400,
                        lineHeight: (theme) => theme.spacing(7),
                    }}>
                    {percentFormatter.format(props.unitRate)}
                </Box>
                <Box
                    sx={{
                        textAlign: 'left',
                        fontSize: (theme) => theme.spacing(2),
                        fontWeight: 400,
                        lineHeight: (theme) => theme.spacing(3),
                    }}>
                    Total Revenue {moneyFormatter.format(props.totalRevenue)}
                </Box>
            </Box>
        </Box>
    );
};
export default NetworkSummaryBox;
