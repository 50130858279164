import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import { OutcomeSummaryDemandCampaign } from '../../features/ssp/OutcomeSummaryDemandCampaign';
import { NetworkColorHelper } from '../../features/ssp/NetworkColorHelper';
import { OneDigitAfterDotFormatter } from '../../features/ssp/OneDigitAfterDotFormatter';

interface OutcomeSummaryDemandCamapignViewProps {
    outcomeSummaryDemandCampaign: OutcomeSummaryDemandCampaign;
    isSeller: boolean;
}

const OutcomeSummaryDemandCampaignView = (
    props: OutcomeSummaryDemandCamapignViewProps
) => {
    const moneyFormatter = new MoneyFormatter();
    const isMobile = useMediaQuery('(max-width:920px)');
    const colorHelper = NetworkColorHelper.getInstance();
    const oneDigitAfterDotFormatter = new OneDigitAfterDotFormatter();

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.background.paper,
                p: 2,
                borderRadius: 2,
            }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {props.outcomeSummaryDemandCampaign.brandName},{' '}
                {props.outcomeSummaryDemandCampaign.campaignName}
            </Typography>
            {props.outcomeSummaryDemandCampaign.rows.length > 0 && (
                <Box sx={{ width: isMobile ? 'auto' : 'fit-content' }}>
                    <TableContainer>
                        <Table aria-label="Outcome summary demand" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Network
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        # of Ads Allocated
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Avg Bid
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Max Budget
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Allocated Budget
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Remaining Budget
                                    </TableCell>
                                    {!props.isSeller && (
                                        <TableCell
                                            sx={{
                                                pr: 0.5,
                                                pl: 0.5,
                                            }}>
                                            Avg Perf. Est. /Ad
                                        </TableCell>
                                    )}
                                    {!props.isSeller && (
                                        <TableCell
                                            sx={{
                                                pr: 0.5,
                                                pl: 0.5,
                                            }}>
                                            KPI
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.outcomeSummaryDemandCampaign.rows.map(
                                    (row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                '& .MuiTableCell-body': {
                                                    fontWeight:
                                                        row.networkName ===
                                                        'Grand Total'
                                                            ? 'bold'
                                                            : '',
                                                },
                                                height: 40,
                                            }}>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.networkName ===
                                                'Grand Total' ? (
                                                    row.networkName
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            pl: 1,
                                                            pr: 1,
                                                            borderRadius: 1,
                                                            background:
                                                                colorHelper.getColor(
                                                                    row.networkName
                                                                ),
                                                            border: '1px solid #00000026',
                                                            width: 'fit-content',
                                                            height: (theme) =>
                                                                theme.spacing(
                                                                    3
                                                                ),
                                                        }}>
                                                        {row.networkName}
                                                    </Box>
                                                )}
                                            </TableCell>

                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.noOfAdsAllocated}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.avgBidAmount
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.budget
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.totalBidAmount
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.remainingBudget != null
                                                    ? moneyFormatter.format(
                                                          row.remainingBudget
                                                      )
                                                    : '—'}
                                            </TableCell>
                                            {!props.isSeller && (
                                                <TableCell
                                                    sx={{
                                                        pr: 0.5,
                                                        pl: 0.5,
                                                    }}>
                                                    {oneDigitAfterDotFormatter.format(
                                                        row.avgPerformanceEstimatePerAd)
                                                    }
                                                </TableCell>
                                            )}

                                            {!props.isSeller && (
                                                <TableCell
                                                    sx={{
                                                        pr: 0.5,
                                                        pl: 0.5,
                                                    }}>
                                                    {moneyFormatter.format(
                                                        row.kpi
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};
export default OutcomeSummaryDemandCampaignView;
