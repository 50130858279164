import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { selectSspStatus } from '../../features/ssp/slice-extensions/ssp-slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    loadOptimizationsStateAsync,
    runOptimizationAsync,
    selectOutcome,
} from '../../features/ssp/slice-extensions/outcome-slice-extensions';
import { LoadingStatus } from '../../features/ssp/LoadingStatus';
import { UserRoles } from '../../features/authentication/UserRoles';
import OutcomeRoot from '../../components/ouotcome-root/OutcomeRoot';
import DownloadOutcomeButton from '../../components/download-outcome-button/DownloadOutcomeButton';
import RequireRoles from '../../components/require-roles/RequireRoles';
import { selectAuthenticatedRole } from '../../features/authentication/authenticationSlice';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export interface OutcomeHomeProps {
    parmsRole?: UserRoles;
}

const OutcomeHome = (props: OutcomeHomeProps) => {
    const dispatch = useAppDispatch();
    const status = useAppSelector(selectSspStatus);
    const isMobile = useMediaQuery('(max-width:920px)');

    const role = useAppSelector(selectAuthenticatedRole);
    const params = useParams();

    useEffect(() => {
        if (
            role === UserRoles.Admin &&
            params.id !== '' &&
            typeof params.id !== 'undefined'
        ) {
            dispatch(loadOptimizationsStateAsync(params.id));
        }
    }, [dispatch, role, params.id]);

    const outcome = useAppSelector(selectOutcome);
    return (
        <Box sx={{ my: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: isMobile ? 'column' : 'row',
                    mb: 2,
                }}>
                <Typography
                    variant="h4"
                    component="h1"
                    sx={{ fontSize: (theme) => theme.spacing(4) }}>
                    DenaliTV Ad Campaign Optimization Outcome
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <RequireRoles roles={[UserRoles.Admin, UserRoles.Seller]}>
                        <Button
                            sx={{
                                height: 32,
                                gap: theme => theme.spacing(1),
                                width: (theme) => theme.spacing(25),
                                border: '1px solid',
                                borderColor: 'primary.main',
                                borderRadius: 2,
                                padding: (theme) => theme.spacing(0.5, 1),
                                fontSize: 16,
                                fontWeight: 600,
                                alignItems: 'center',
                                lineHeight: 3,
                                '& .MuiButton-startIcon': {
                                    ml: 0,
                                },
                            }}
                            disabled={status === LoadingStatus.Loading}
                            onClick={() => {
                                dispatch(runOptimizationAsync());
                            }}>
                                <img src='/button-icons/magic-stick.svg' alt='Magic Stick Icon'/>
                            Run optimization
                        </Button>
                    </RequireRoles>

                    <DownloadOutcomeButton
                        outcomeState={outcome}
                        isLoaded={
                            status === LoadingStatus.Idle &&
                            typeof outcome?.outcomeSummaryDemand !== 'undefined'
                        }></DownloadOutcomeButton>
                </Box>
            </Box>
            <Box sx={{ my: 4 }}>
                <OutcomeRoot
                    parmsRole={props.parmsRole}
                    outcomeState={outcome}
                    isLoaded={status === LoadingStatus.Idle}></OutcomeRoot>
            </Box>
        </Box>
    );
};

export default OutcomeHome;
