import { Box, useTheme } from '@mui/material';
import { PercentFormatter } from '../../features/ssp/PercentFormatter';
import { StringsExtension } from '../../features/ssp/extensions/StringsExtension';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined';
import SouthWestOutlinedIcon from '@mui/icons-material/SouthWestOutlined';
import { TitleType } from './CampaignSummaryBosex';
import { OneDigitAfterDotFormatter } from '../../features/ssp/OneDigitAfterDotFormatter';

interface CampaignSummaryBoxProps {
    title: string;
    value: number;
    subTitleValue: number | undefined;
    valueInBrackets: number | undefined;
    valueType: TitleType;
    subTitleType: TitleType;
    valueInBracketsType: TitleType;
    index?: number;
    isValueSignVisible: boolean;
    isArrowVisible: boolean;
}

const CampaignSummaryBox = (props: CampaignSummaryBoxProps) => {
    const percentFormatter = new PercentFormatter();
    const moneyFormatter = new MoneyFormatter();
    const oneDigitAfterDotFormatter = new OneDigitAfterDotFormatter();
    const stringsExtension = new StringsExtension();
    const theme = useTheme();
    const getBorderColor = (): string => {
        if (props.index !== undefined) {
            if (props.index === 1) {
                return theme.palette.primary.main;
            }
            if (props.index === 2) {
                return theme.palette.secondary.light;
            }
        }

        return theme.palette.primary.dark;
    };

    const getFormattedValue = (value: any, type: TitleType) => {
        switch (type) {
            case TitleType.Percent:
                return props.isValueSignVisible
                    ? percentFormatter.format(value)
                    : stringsExtension.removePlusNegativeSign(
                          percentFormatter.format(value)
                      );
            case TitleType.Number:
                return moneyFormatter.format(value);
            case TitleType.Decimal:
                return oneDigitAfterDotFormatter.format(value);

            default:
                return value;
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: '#ffffff',
                height: (theme) => theme.spacing(23),
                borderRadius: (theme) => theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: (theme) => theme.spacing(2),
                borderBottom: (theme) =>
                    `${theme.spacing(0.5)} solid ${getBorderColor()}`,
                width: '100%',
                minWidth: 'fit-content',
                maxWidth: (theme) => theme.spacing(36),
            }}>
            <Box
                sx={{
                    pl: 1,
                    pr: 1,
                    width: 'fit-content',
                    height: (theme) => theme.spacing(3),
                    fontSize: (theme) => theme.spacing(1.75),
                }}>
                {props.title}
            </Box>
            <Box
                sx={{
                    textAlign: 'left',
                    fontSize: (theme) => theme.spacing(10),
                    fontWeight: 400,
                    lineHeight: (theme) => theme.spacing(7),
                    display: 'flex',
                    flexDirection: 'column',
                    gap: (theme) => theme.spacing(1),
                }}>
                {getFormattedValue(props.value, props.valueType)}
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: 1.5,
                        textAlign: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        height: (theme) => theme.spacing(3),
                        gap: (theme) => theme.spacing(1),
                    }}>
                    {props.subTitleValue !== undefined && (
                        <>
                            <Box>
                                {getFormattedValue(
                                    props.subTitleValue,
                                    props.subTitleType
                                )}{' '}
                                (
                                {getFormattedValue(
                                    props.valueInBrackets,
                                    props.valueInBracketsType
                                )}
                                )
                            </Box>
                            {props.isArrowVisible ? (
                                props.value > 0 ? (
                                    <NorthEastOutlinedIcon
                                        sx={{ color: '#00A426', width: 16 }}
                                    />
                                ) : props.value < 0 ? (
                                    <SouthWestOutlinedIcon
                                        sx={{ color: 'red', width: 16 }}
                                    />
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CampaignSummaryBox;
