import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { OutcomeState } from '../../features/ssp/OutcomeState';
import axiosInstance from '../../features/axios/axiosInstance';
import { OptimizationResponse } from '../../features/ssp/contracts/outcome/OptimizationResponse';
import OutcomeRoot from '../../components/ouotcome-root/OutcomeRoot';
import DownloadOutcomeButton from '../../components/download-outcome-button/DownloadOutcomeButton';
import axios, { HttpStatusCode } from 'axios';

const OptimizationLog = () => {
    const params = useParams();
    const [isLoaded, setLoaded] = useState(false);
    const [outcomeState, setOutcomeState] = useState<OutcomeState>({
        output: '',
        message: '',
        optimizedBids: [],
        usedPriceFloors: [],
        dateCreated: new Date(),
        dateStart: '',
        dateEnd: '',
        inventoryDailyLogsList: [],
        outcomeSummarySupply: undefined,
        outcomeSummaryDemand: undefined,
        outcomeSummaryDemandCampaigns: [],
        outcomeSummaryNetworks: [],
    });
    useEffect(() => {
        const fetchOutcome = async () => {
            try {
                const response = await axiosInstance.get<OptimizationResponse>(
                    `/api/v1/optimizations/${params.id}`,
                    {
                        headers: {
                            ContentType: 'application/json',
                        },
                    }
                );
                const result: OutcomeState = {
                    output: '',
                    message: '',
                    optimizedBids: [],
                    usedPriceFloors: [],
                    dateCreated: response.data.dateCreated,
                    dateStart: response.data.dateStart,
                    dateEnd: response.data.dateEnd,
                    inventoryDailyLogsList:
                        response.data.inventoryDailyLogsList,
                    outcomeSummarySupply: response.data.summarySupply,
                    outcomeSummaryDemand: response.data.summaryDemand,
                    outcomeSummaryDemandCampaigns:
                        response.data.summaryDemandCampaigns,
                    outcomeSummaryNetworks: response.data.summaryNetworks,
                };
                setOutcomeState(result);
                setLoaded(true);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === HttpStatusCode.Unauthorized) {
                        return;
                    }
                }
            }
        };
        setLoaded(false);
        fetchOutcome();
    }, [params.id]);

    return (
        <Box>
            <Box sx={{ my: 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                    }}>
                    <Typography variant="h4" component="h1">
                        Optimization Log
                    </Typography>
                    <DownloadOutcomeButton
                        outcomeState={outcomeState}
                        isLoaded={isLoaded}></DownloadOutcomeButton>
                </Box>
                <Box sx={{ my: 4 }}>
                    <Link to={'/optimization-logs'}>Back</Link>
                    {!isLoaded && <div>Loading...</div>}
                    <OutcomeRoot
                        outcomeState={outcomeState}
                        isLoaded={isLoaded}></OutcomeRoot>
                </Box>
            </Box>
        </Box>
    );
};

export default OptimizationLog;
