import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import axios, { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import axiosInstance from '../../features/axios/axiosInstance';
import { UserAccount } from '../../features/userAccount/UserAccount';
import { UserRoles } from '../../features/authentication/UserRoles';
import { UserAccountResponse } from '../../features/userAccount/contracts/UserAccountResponse';
import { UserAccountResponseMapper } from '../../features/userAccount/mappers/UserAccountResponseMapper';
import AccountSettingsDrawer from '../../components/account-settings-drawer/AccountSettingsDrawer';

const ChangeAccountName = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [user, setUser] = useState<UserAccount>({
        id: 0,
        displayName: '',
        email: '',
        role: UserRoles.Unknown,
        dateCreated: new Date(),
        dateModified: new Date(),
        isActive: false,
        isEdited: false,
    });
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<boolean>();

    const inputWidth = 182;
    useEffect(() => {
        const fetchUserAsync = async () => {
            try {
                const response = await axiosInstance.get<UserAccountResponse>(
                    `/api/v1/user-accounts/`
                );
                const userAccountResponseMapper =
                    new UserAccountResponseMapper();
                const userAccount =
                    userAccountResponseMapper.mapAccountResponseToUserAccount(
                        response.data
                    );
                if (typeof userAccount !== 'undefined') {
                    setUser(userAccount);
                    setName(userAccount.displayName);
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (
                        error.response?.status === HttpStatusCode.Unauthorized
                    ) {
                        return;
                    }
                    return;
                }
                return;
            }
        };
        fetchUserAsync();
    }, []);

    const changeDisplayNameAsync = async (): Promise<boolean | undefined> => {
        try {
            const body = {
                displayName: name,
            };
            const response = await axiosInstance.put(
                `/api/v1/user-accounts/${user.id}/display-name`,
                body
            );

            return response.status === HttpStatusCode.Ok;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === HttpStatusCode.Unauthorized) {
                    return false;
                }
                return false;
            }
            return false;
        }
    };

    const isButtonsDisabled = () => {
        return name === user.displayName && !isLoading;
    };

    const saveAsync = async () => {
        setIsLoading(true);

        if (name !== user.displayName) {
            const isDisplayNameChangeRequestSuccessful =
                await changeDisplayNameAsync();
            if (isDisplayNameChangeRequestSuccessful) {
                setUser({ ...user, displayName: name });
            }
        }

        setIsLoading(false);
    };

    return (
        <Box sx={{ my: 1 }}>
            <Box
                sx={{
                    mb: 2,
                }}>
                <Typography variant="h4" component="h4">
                    Account Settings
                </Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiTextField-root': {
                        mr: 1,
                        mt: 0.5,
                        width: inputWidth,
                    },
                    my: 4,
                    width: 'max-content',
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <AccountSettingsDrawer />
                <Box
                    sx={{
                        ml: 2,
                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <InputLabel error={nameError}>
                            {nameError && name.length === 0
                                ? 'Name is required'
                                : nameError
                                ? 'Name too long'
                                : 'Name'}
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                                if (event.target.value.length < 512) {
                                    setNameError(false);
                                } else {
                                    setNameError(true);
                                }
                            }}
                            autoComplete={'new-name'}
                            error={nameError}
                        />
                        <InputLabel error={nameError}>Role</InputLabel>
                        <TextField
                            variant="outlined"
                            disabled
                            value={user.role}
                        />
                        <InputLabel error={nameError}>Email</InputLabel>
                        <TextField
                            variant="outlined"
                            disabled
                            value={user.email}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            my: 2,
                        }}>
                        <Button
                            onClick={() => {
                                setName(user.displayName);
                            }}
                            disabled={isButtonsDisabled()}>
                            Cancel
                        </Button>
                        <Button
                            onClick={async () => await saveAsync()}
                            disabled={isButtonsDisabled()}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default ChangeAccountName;
