export class NetworkColorHelper {
    private colors = ['#B4CFFF', '#C3FFA3', '#FFEDA3', '#F1CBFF'];
    private networkColorMap: Map<string, string> = new Map();

    private static instance: NetworkColorHelper;

    private constructor() {}

    public static getInstance(): NetworkColorHelper {
        if (!NetworkColorHelper.instance) {
            NetworkColorHelper.instance = new NetworkColorHelper();
        }
        return NetworkColorHelper.instance;
    }

    getColor(networkName: string): string {
        if (this.networkColorMap.has(networkName)) {
            return this.networkColorMap.get(networkName)!;
        }
        const colorIndex = this.networkColorMap.size % this.colors.length;
        const assignedColor = this.colors[colorIndex];
        this.networkColorMap.set(networkName, assignedColor);

        return assignedColor;
    }
}
