import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectAuthenticatedRole, selectIsAuthenticated } from '../../features/authentication/authenticationSlice';

const RequireAuth = ({
    children,
    roles
}: {
    children: JSX.Element;
    roles?: string | string[];
}) => {
    const role = useAppSelector(selectAuthenticatedRole);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const location = useLocation();
    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    const isVisible = roles?.includes(role);
    if(isVisible === false){
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
};

export default RequireAuth;
