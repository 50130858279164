import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const AccountSettingsDrawer = () => {
    const navigateTexts = ['Change name', 'Change password'];
    const clickRoute = ['/account/change-name', '/account/change-password'];
    const navigate = useNavigate();
    const location = useLocation();
    const activeStepIndex = Math.max(clickRoute.indexOf(location.pathname), 0);

    const handleStep = (index: number) => {
        navigate(clickRoute[index]);
    };
    return (
        <Drawer
            variant="permanent"
            sx={{
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    boxSizing: 'border-box',
                    position: 'unset',
                },
            }}>
            <Box>
                <List>
                    {navigateTexts.map((navigateText, i) => (
                        <ListItem key={i} disablePadding>
                            <ListItemButton
                                selected={i === activeStepIndex}
                                onClick={() => handleStep(i)}>
                                <ListItemText primary={navigateText} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};

export default AccountSettingsDrawer;
