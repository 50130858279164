export class MoneyFormatter {
    format(amount: number | undefined, currencySymbol: string = '$') {
        if (typeof amount === 'undefined' || amount === null) {
            return '';
        }
        const formattedAmount = amount.toFixed(2);

        if (formattedAmount.endsWith('.00') || formattedAmount.endsWith('.0')) {
            return `${currencySymbol}${parseInt(
                formattedAmount
            ).toLocaleString()}`;
        }

        return `${currencySymbol}${parseFloat(formattedAmount).toLocaleString(
            'en-US',
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        )}`;
    }
}
