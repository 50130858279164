import { Box, Button, Collapse, Typography } from '@mui/material';
import AdminUsersTable from '../../components/admin-users-table/AdminUsersTable';
import AdminCreateUserPanel from '../../components/admin-create-user-panel/AdminCreateUserPanel';
import { useEffect, useState } from 'react';
import { UserRolesResponseMapper } from '../../features/userAccount/mappers/UserRolesResponseMapper';
import { UserAccount } from '../../features/userAccount/UserAccount';
import { UserAccountResponse } from '../../features/userAccount/contracts/UserAccountResponse';
import axiosInstance from '../../features/axios/axiosInstance';
import axios, { HttpStatusCode } from 'axios';

const UserAccounts = () => {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [users, setUsers] = useState<UserAccount[]>([]);

    const getAllUsers = async (): Promise<UserAccount[]> => {
        try {
            const response = await axiosInstance.get<UserAccountResponse[]>(
                '/api/v1/user-accounts/all',
                {
                    headers: {
                        ContentType: 'application/json',
                    },
                }
            );
            const data: UserAccountResponse[] = response.data;
            const userRolesResponseMapper = new UserRolesResponseMapper();
            return data.map((userAccountResponse) => {
                return {
                    id: userAccountResponse.id,
                    role: userRolesResponseMapper.mapUserRolesResponseToUserRoles(
                        userAccountResponse.role
                    ),
                    email: userAccountResponse.email,
                    displayName: userAccountResponse.displayName,
                    isActive: userAccountResponse.isActive,
                    dateModified: new Date(userAccountResponse.dateModified),
                    dateCreated: new Date(userAccountResponse.dateCreated),
                    isEdited: false,
                };
            });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === HttpStatusCode.Unauthorized) {
                    return [];
                }
            }
            return [];
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const users: UserAccount[] = await getAllUsers();
            const sortedUsers = users.sort(
                (a, b) =>
                    new Date(b.dateModified).getTime() -
                    new Date(a.dateModified).getTime()
            );
            setUsers(sortedUsers);
        };

        fetchUsers();
    }, []);

    const addNewUser = (userAccount: UserAccount) => {
        setUsers((prevUsers) => {
            const newUsers = [...prevUsers, userAccount];
            return newUsers.sort(
                (a, b) =>
                    new Date(b.dateModified).getTime() -
                    new Date(a.dateModified).getTime()
            );
        });
    };

    return (
        <Box>
            <Box sx={{ my: 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                    }}>
                    <Typography variant="h4" component="h1">
                        User Accounts
                    </Typography>
                </Box>
                <Box sx={{ my: 4 }}>
                    <Button
                        onClick={() => {
                            setIsShowing(!isShowing);
                        }}>
                        Create New User
                    </Button>
                    <Collapse in={isShowing}>
                        <AdminCreateUserPanel
                            setIsShowing={setIsShowing}
                            addNewUser={addNewUser}
                        />
                    </Collapse>
                    <AdminUsersTable userAccounts={users} setUserAccounts={setUsers} />
                </Box>
            </Box>
        </Box>
    );
};

export default UserAccounts;
