import { Box, Button, Typography } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { store } from '../../app/store';
import { useAppDispatch } from '../../app/hooks';
import { setSspState } from '../../features/ssp/slice-extensions/ssp-slice';
import { ExportStoreFormat } from './ExportStoreFormat';

const SettingsRoot = () => {
    const importInputRef = useRef<HTMLInputElement>(null);

    const handleImportButtonClick = () => {
        if (importInputRef.current) {
            importInputRef.current.click();
        }
    };

    const dispatch = useAppDispatch();

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file && file.type === 'application/json') {
            try {
                const fileContents = await file.text();
                const jsonData: ExportStoreFormat = JSON.parse(fileContents);
                if (jsonData.ssp) {
                    dispatch(setSspState({ newSspState: jsonData.ssp }));
                    alert('The data has been successfully imported.');
                } else {
                    alert(`${file.name} file does not contains an ssp object.`);
                }
            } catch (error) {
                alert('Error parsing JSON file.');
            }
        } else {
            if (file) {
                alert('Please upload a JSON file.');
            }
        }
    };

    const handleExportButtonClick = () => {
        const sspState = store.getState().ssp;
        const exportData: ExportStoreFormat = { ssp: sspState };
        const jsonString = JSON.stringify(exportData, null, 2);

        const blob = new Blob([jsonString], { type: 'application/json' });
        const href = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = href;
        link.download = 'dtvStore.json';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Settings
            </Typography>
            <Box>
                <Button
                    variant="text"
                    component="span"
                    onClick={handleExportButtonClick}>
                    Export
                </Button>
                <Button
                    sx={{ ml: 1 }}
                    variant="text"
                    component="span"
                    onClick={handleImportButtonClick}>
                    Import
                </Button>
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept="application/json"
                    ref={importInputRef}
                    onChange={handleFileChange}
                />
            </Box>
        </>
    );
};

export default SettingsRoot;
