import { useAppSelector } from '../../app/hooks';
import { selectAuthenticatedRole } from '../../features/authentication/authenticationSlice';

const RequireRoles = ({
    children,
    roles,
}: {
    children: JSX.Element;
    roles: string | string[];
}) => {
    const role = useAppSelector(selectAuthenticatedRole);
    const isVisible = roles.includes(role);
    if (isVisible) {
        return children;
    }
    return <></>;
};

export default RequireRoles;
