import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import HomeMaxIcon from '@mui/icons-material/HomeMax';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    logoutAsync,
    selectAuthenticatedRole,
    selectIsAuthenticated,
} from '../../features/authentication/authenticationSlice';
import mountainImage from './mountain.png';
import { Copyright } from '@mui/icons-material';
import { UserRoles } from '../../features/authentication/UserRoles';
import { setStateToDefault } from '../../features/ssp/slice-extensions/ssp-slice';
import NavigationTabControls from '../navigation-tab-controls/NavigationTabControls';
import { useMediaQuery } from '@mui/material';

const userPages: Array<string> = [];
const adminPages: Array<string> = [
    'Home',
    'User Account Management',
    'Input Management',
    'Data Archiving',
];
const userPagesUrls: Array<string> = [];
const adminPagesUrls: Array<string> = [
    '/',
    '/user-accounts',
    '/seller',
    '/optimization-logs',
];
const logoutText = 'Logout';
const settingsText = 'Settings';
const accountEditText = 'Account';

const Layout = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const role = useAppSelector(selectAuthenticatedRole);

    const isAdmin = UserRoles.Admin === role;

    const pages = isAdmin ? adminPages : userPages;
    const pageUrls = isAdmin ? adminPagesUrls : userPagesUrls;

    const settings =
        role === UserRoles.Admin
            ? [settingsText, accountEditText, logoutText]
            : [accountEditText, logoutText];
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (event: any) => {
        setAnchorElNav(null);
    };

    const handleMenuClick = (pageIndex: number, event: any) => {
        handleCloseNavMenu(event);
        navigate(pageUrls[pageIndex]);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleSettingClick = (setting: string) => {
        if (setting === logoutText) {
            dispatch(setStateToDefault());
            dispatch(logoutAsync());
        }
        if (setting === settingsText) {
            navigate('/settings');
        }
        if (setting === accountEditText) {
            navigate('/account/change-name');
        }
        handleCloseUserMenu();
    };
    const sideContainerWidthInThemeScaling: number = 25;
    const isMobile = useMediaQuery('(max-width:920px)');
    const heightInThemeScaling: number = 9;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}>
            <AppBar
                sx={{
                    height: theme => theme.spacing(9),
                    justifyContent: 'center',
                    alignContent: 'center',
                    boxShadow: 'none',
                }}
                position="static">
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: theme => theme.spacing(heightInThemeScaling),
                        p: 2,
                        px: 5,
                    }}
                    disableGutters>
                    <Box
                        component={'a'}
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            width: theme => theme.spacing(sideContainerWidthInThemeScaling),
                            padding: 0.8,
                        }}
                        onClick={() => {
                            navigate('/');
                        }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="119px"
                            height="11px">
                            <path
                                fillRule="evenodd"
                                fill="rgb(255, 255, 255)"
                                d="M112.777,10.844 L109.373,10.844 L103.246,0.149 L107.331,0.149 L111.075,6.685 L114.820,0.149 L118.905,0.149 L112.777,10.844 ZM97.455,10.844 L93.710,10.844 L93.710,2.822 L88.774,2.822 L88.774,0.149 L102.391,0.149 L102.391,2.822 L97.455,2.822 L97.455,10.844 ZM79.478,0.149 L83.222,0.149 L83.222,10.844 L79.478,10.844 L79.478,0.149 ZM65.444,0.149 L69.188,0.149 L69.188,8.170 L77.699,8.170 L77.699,10.844 L65.444,10.844 L65.444,0.149 ZM59.124,9.655 L52.533,9.655 L51.862,10.844 L47.763,10.844 L53.770,0.149 L57.865,0.149 L63.879,10.844 L59.789,10.844 L59.124,9.655 ZM55.811,3.792 L54.009,6.982 L57.595,6.982 L55.811,3.792 ZM35.314,4.494 L35.314,10.844 L31.570,10.844 L31.570,0.149 L35.655,0.149 L42.463,6.833 L42.463,0.149 L46.208,0.149 L46.208,10.844 L41.782,10.844 L35.314,4.494 ZM16.719,0.149 L29.314,0.149 L29.314,2.822 L20.464,2.822 L20.464,4.159 L28.634,4.159 L28.634,6.833 L20.464,6.833 L20.464,8.170 L29.314,8.170 L29.314,10.844 L16.719,10.844 L16.719,0.149 ZM7.179,10.844 L0.030,10.844 L0.030,0.149 L7.179,0.149 C12.055,0.149 14.660,1.556 14.660,5.496 C14.660,9.718 12.055,10.844 7.179,10.844 ZM7.179,2.822 L3.775,2.822 L3.775,8.170 L7.179,8.170 C9.450,8.170 10.923,7.748 10.923,5.496 C10.923,3.245 9.491,2.822 7.179,2.822 Z"
                            />
                        </svg>
                        <img
                            src={mountainImage}
                            width={32}
                            alt="mountain-logo"></img>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}>
                        {isAuthenticated ? (
                            <>
                                {' '}
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit">
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: {
                                            xs: 'block',
                                            md: 'none',
                                        },
                                    }}>
                                    {pages.map((page, index) => (
                                        <MenuItem
                                            key={page}
                                            onClick={(event) =>
                                                handleMenuClick(index, event)
                                            }>
                                            <Typography textAlign="center">
                                                {page}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        ) : (
                            []
                        )}
                    </Box>
                    <HomeMaxIcon
                        sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
                    />
                    <Typography
                        variant="h5"
                        noWrap
                        component="span"
                        onClick={() => {
                            navigate('/');
                        }}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                        DENALI TV
                    </Typography>
                    {isAuthenticated && !isMobile && <NavigationTabControls />}

                    {isAuthenticated ? (
                        <Box
                            sx={{
                                flexGrow: 0,
                                width: theme => theme.spacing(sideContainerWidthInThemeScaling),
                                height: (theme) => theme.spacing(4),
                            }}>
                            <Tooltip title="Open settings">
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0, float: 'right' }}>
                                    <Avatar
                                        sx={{
                                            width: (theme) => theme.spacing(4),
                                            height: (theme) => theme.spacing(4),
                                            backgroundColor: '#FFFFFF',
                                            color: 'primary.dark',
                                            fontSize: 16,
                                        }}>
                                        R
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}>
                                {settings.map((setting) => (
                                    <MenuItem
                                        key={setting}
                                        onClick={() =>
                                            handleSettingClick(setting)
                                        }>
                                        <Typography textAlign="center">
                                            {setting}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    ) : (
                        []
                    )}
                </Toolbar>
            </AppBar>
            <Container sx={{ px: theme => `${theme.spacing(5)} !important`, maxWidth: '100% !important' }}>
                <Box sx={{ my: 4, }}>
                    <Outlet />
                </Box>
            </Container>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                }}>
                <Container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Copyright />
                    {new Date().getFullYear()} Denali TV Inc. All rights
                    reserved.
                </Container>
            </Box>
        </Box>
    );
};

export default Layout;
