import { Box, useMediaQuery } from '@mui/material';
import { Daypart } from '../../features/ssp/Daypart';
import TitleLabelAndText from './TitleAndText';

interface GeneralKeyMetricsProps {
    dateOfOptimization: string | Date;
    numberOfNetworks: number;
    numberOfCampaigns: number;
    dateStart: string | Date;
    dateEnd: string | Date;
    daypart: string | Daypart;
    hoursStart: string | Date;
    hoursEnd: string | Date;
}

const GeneralKeyMetrics = ({
    dateOfOptimization,
    numberOfNetworks,
    numberOfCampaigns,
    dateStart,
    dateEnd,
    daypart,
    hoursStart,
    hoursEnd,
}: GeneralKeyMetricsProps) => {
    const isMobile = useMediaQuery('(max-width:920px)');

    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.secondary.dark,
                minWidth: (theme) => (isMobile ? 'auto' : theme.spacing(53)),
                height: (theme) => (isMobile ? 'auto' : theme.spacing(23)),
                borderRadius: (theme) => theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                gap: (theme) => theme.spacing(2),
                padding: (theme) => theme.spacing(2),
            }}>
            <Box>
                <TitleLabelAndText
                    title={'Date of Optimization'}
                    text={dateOfOptimization.toString()}
                />
            </Box>
            <Box sx={{ display: 'flex', gap: (theme) => theme.spacing(2) }}>
                <TitleLabelAndText
                    title={'# of Networks'}
                    text={numberOfNetworks.toString()}
                />
                <TitleLabelAndText
                    title={'# of Campaigns'}
                    text={numberOfCampaigns.toString()}
                />
                <TitleLabelAndText title={'Daypart'} text={daypart.toString()} />
            </Box>
            <Box sx={{ display: 'flex', gap: (theme) => theme.spacing(2) }}>
                <TitleLabelAndText
                    title={'Date'}
                    text={`${dateStart.toString()} - ${dateEnd.toString()}`}
                />
                <TitleLabelAndText
                    title={'Hours'}
                    text={`${hoursStart.toString()} - ${hoursEnd.toString()}`}
                />
            </Box>
        </Box>
    );
};
export default GeneralKeyMetrics;
