// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { OutcomeSummaryDemand } from '../../features/ssp/OutcomeSummaryDemand';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import { InventoryDailyLogsService } from '../../features/ssp/InventoryDailyLogsService';
import { StringsExtension } from '../../features/ssp/extensions/StringsExtension';
import axiosInstance from '../../features/axios/axiosInstance';
import { OptimizationResponse } from '../../features/ssp/contracts/outcome/OptimizationResponse';
import { OutcomeState } from '../../features/ssp/OutcomeState';
import RequireRoles from '../require-roles/RequireRoles';
import { selectAuthenticatedRole } from '../../features/authentication/authenticationSlice';
import { useAppSelector } from '../../app/hooks';
import { UserRoles } from '../../features/authentication/UserRoles';
import axios, { HttpStatusCode } from 'axios';
import { CloudUploadOutlined } from '@mui/icons-material';
import { OneDigitAfterDotFormatter } from '../../features/ssp/OneDigitAfterDotFormatter';

interface OutcomeSummaryDemandViewProps {
    outcomeSummaryDemand: OutcomeSummaryDemand;
    isSeller: boolean;
    campaignId: number;
}

const OutcomeSummaryDemandView = (props: OutcomeSummaryDemandViewProps) => {
    const moneyFormatter = new MoneyFormatter();
    const stringsExtension = new StringsExtension();
    const serializer = new InventoryDailyLogsService();
    const role = useAppSelector(selectAuthenticatedRole);
    const oneDigitAfterDotFormatter = new OneDigitAfterDotFormatter();

    const getBuyerOutcomeAsync = async (
        buyerId: number
    ): Promise<OutcomeState | undefined> => {
        let responseData: OptimizationResponse | string = '';
        try {
            const response = await axiosInstance.get<OptimizationResponse>(
                `/api/v1/optimizations/buyer/${buyerId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            responseData = response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === HttpStatusCode.Unauthorized) {
                    return;
                }
            }
        }

        if (typeof responseData === 'string') {
            return undefined;
        }
        let buyerOutcome: OutcomeState = {
            inventoryDailyLogsList: [],
            outcomeSummaryDemand: undefined,
            outcomeSummarySupply: undefined,
            outcomeSummaryDemandCampaigns: undefined,
            outcomeSummaryNetworks: [],
            optimizedBids: [],
            usedPriceFloors: [],
            dateCreated: new Date(),
            dateStart: '',
            dateEnd: '',
            message: '',
            output: '',
        };

        buyerOutcome.dateCreated = responseData.dateCreated;
        buyerOutcome.dateStart = responseData.dateStart;
        buyerOutcome.dateEnd = responseData.dateEnd;
        buyerOutcome.inventoryDailyLogsList =
            responseData.inventoryDailyLogsList;
        buyerOutcome.outcomeSummaryDemand = responseData.summaryDemand;
        buyerOutcome.outcomeSummarySupply = responseData.summarySupply;
        buyerOutcome.outcomeSummaryNetworks = responseData.summaryNetworks;
        return buyerOutcome;
    };

    const downloadBuyerReportAsync = async (
        ownerId: number,
        agency: string
    ) => {
        const buyerOutcome = await getBuyerOutcomeAsync(ownerId);
        if (typeof buyerOutcome !== 'undefined') {
            const showKpiAndPerformanceEstimatesRoles: string[] = [
                UserRoles.Admin,
                UserRoles.Buyer,
            ];
            const showPriceFloorColumn: boolean = false;
            const showKpiAndPerformanceEstimates =
                showKpiAndPerformanceEstimatesRoles.includes(role);
            const cells = serializer.serializeBuyer(
                buyerOutcome,
                showPriceFloorColumn,
                showKpiAndPerformanceEstimates
            );
            const ws = XLSX.utils.aoa_to_sheet(cells);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Optimization');
            XLSX.writeFile(wb, `${agency} Optimization.xlsx`);
        }
    };

    const getOutcomeSummaryDemandRows = () => {
        const grandTotalRow = props.outcomeSummaryDemand.rows.find(
            (x) => x.product === 'Grand Total'
        );
        const filteredRows = props.outcomeSummaryDemand.rows.filter(
            (x) => x.campaignId === props.campaignId
        );
        const totalBudget = filteredRows.reduce(
            (acc, row) => acc + row.budget,
            0
        );
        const totalBidAmount = filteredRows.reduce(
            (acc, row) => acc + row.totalBidAmount,
            0
        );
        const totalRemainingBudget = filteredRows.reduce(
            (acc, row) => acc + row.remainingBudget,
            0
        );
        if (grandTotalRow) {
            filteredRows.push({
                ...grandTotalRow,
                budget: totalBudget,
                totalBidAmount: totalBidAmount,
                remainingBudget: totalRemainingBudget,
            });
        }

        return filteredRows;
    };

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.background.paper,
                p: 2,
                borderRadius: 2,
            }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {props.isSeller ? 'Summary - Demand ' : 'Allocation Summary'}
            </Typography>
            {getOutcomeSummaryDemandRows().length > 0 && (
                <Box>
                    <TableContainer>
                        <Table aria-label="Outcome summary demand" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Agency
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Brand
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Product
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        # Ads Allocated
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Avg Bid
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Budget
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Allocated Budget
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Remaining Budget
                                    </TableCell>
                                    {!props.isSeller && (
                                        <TableCell
                                            sx={{
                                                pr: 0.5,
                                                pl: 0.5,
                                            }}>
                                            Avg Perf. Est. /Ad
                                        </TableCell>
                                    )}
                                    {!props.isSeller && (
                                        <TableCell
                                            sx={{
                                                pr: 0.5,
                                                pl: 0.5,
                                            }}>
                                            KPI
                                        </TableCell>
                                    )}
                                    <RequireRoles
                                        roles={[
                                            UserRoles.Seller,
                                            UserRoles.Admin,
                                        ]}>
                                        <TableCell
                                            sx={{
                                                pr: 0.5,
                                                pl: 0.5,
                                                width: (theme) =>
                                                    theme.spacing(15),
                                            }}>
                                            Buyer Report
                                        </TableCell>
                                    </RequireRoles>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getOutcomeSummaryDemandRows().map(
                                    (row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                '& .MuiTableCell-body': {
                                                    fontWeight:
                                                        row.product ===
                                                        'Grand Total'
                                                            ? 'bold'
                                                            : '',
                                                },
                                                height: 40,
                                            }}>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.agency}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.brand}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.product}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.noOfAdsAllocated}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.avgBidAmount
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.budget
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.totalBidAmount
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.remainingBudget
                                                )}
                                            </TableCell>
                                            {!props.isSeller && (
                                                <TableCell
                                                    sx={{
                                                        pr: 0.5,
                                                        pl: 0.5,
                                                    }}>
                                                    {oneDigitAfterDotFormatter.format(
                                                        row.avgPerformanceEstimatePerAd
                                                    )}
                                                </TableCell>
                                            )}

                                            {!props.isSeller && (
                                                <TableCell
                                                    sx={{
                                                        pr: 0.5,
                                                        pl: 0.5,
                                                    }}>
                                                    {moneyFormatter.format(
                                                        row.kpi
                                                    )}
                                                </TableCell>
                                            )}
                                            <RequireRoles
                                                roles={[
                                                    UserRoles.Seller,
                                                    UserRoles.Admin,
                                                ]}>
                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        padding: '0 !important',
                                                    }}>
                                                    {!stringsExtension.stringsEqual(
                                                        row.product,
                                                        'Grand Total'
                                                    ) &&
                                                        typeof row.campaignOwnerId !==
                                                            'undefined' && (
                                                            <Button
                                                                sx={{
                                                                    gap: (
                                                                        theme
                                                                    ) =>
                                                                        theme.spacing(
                                                                            1
                                                                        ),
                                                                    minWidth:
                                                                        'auto',
                                                                    width: 'max-content',
                                                                    color: (
                                                                        theme
                                                                    ) =>
                                                                        theme
                                                                            .palette
                                                                            .primary
                                                                            .main,
                                                                    fontSize: 16,
                                                                    fontWeight: 600,
                                                                    lineHeight: 1,
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                                onClick={() =>
                                                                    downloadBuyerReportAsync(
                                                                        row.campaignOwnerId!,
                                                                        row.agency
                                                                    )
                                                                }>
                                                                Download
                                                                <CloudUploadOutlined
                                                                    sx={{
                                                                        minHeight:
                                                                            'unset',
                                                                        width: (
                                                                            theme
                                                                        ) =>
                                                                            theme.spacing(
                                                                                2.5
                                                                            ),
                                                                    }}
                                                                />
                                                            </Button>
                                                        )}
                                                </TableCell>
                                            </RequireRoles>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};
export default OutcomeSummaryDemandView;
