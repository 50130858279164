import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { CampaignAdSpaceValue } from '../CampaignAdSpaceValue';
import { CampaignBudgets } from '../CampaignBudgets';
import { AvailabilityStatus } from '../AvailabilityStatus';
import { DailyLog } from '../DailyLog';
import { InventoryDailyLogs } from '../InventoryDailyLogs';
import { AdvertisedCampaign } from '../AdvertisedCampaign';
import { CampaignToBuyerNameMap } from '../CampaignToBuyerNameMap';
import axiosInstance from '../../axios/axiosInstance';
import { CampaignNetworkValue } from '../CampaignNetworkValue';
import { OutcomeSummaryNetwork } from '../OutcomeSummaryNetwork';
import { OptimizationResponse } from '../contracts/outcome/OptimizationResponse';
import { AxiosError } from 'axios';
import { ProblemDetail } from '../contracts/ProblemDetail';
import { setStatusMessage } from './ssp-slice';
import { StringsExtension } from '../extensions/StringsExtension';

export const loadOptimizationsStateAsync = createAsyncThunk(
    'ssp/loadOptimizationsState',
    async (userId?: string) => {
        const stringsExtension = new StringsExtension();
        const response = await axiosInstance.get<OptimizationResponse>(
            `/api/v1/optimizations` +
                stringsExtension.returnIfNotEmpty(userId, `?userId=${userId}`),
            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );
        return response.data;
    }
);

export const rebuildInventoryLogsPrivate = (
    optimizedBids: CampaignAdSpaceValue[],
    usedPriceFloors: CampaignAdSpaceValue[],
    dailyLogsList: DailyLog[],
    advertisedCampaigns: AdvertisedCampaign[],
    campaignToBuyerNameMap: CampaignToBuyerNameMap[]
) => {
    const inventoryDailyLogsList = dailyLogsList.map((dailyLogs) => {
        const networkOptimizedBids = optimizedBids.filter(
            (b) => b.networkName === dailyLogs.networkName
        );
        const networkPriceFloors = usedPriceFloors.filter(
            (b) => b.networkName === dailyLogs.networkName
        );

        const result: InventoryDailyLogs = {
            networkName: dailyLogs.networkName,
            daypart: dailyLogs.daypart,
            hoursStart: dailyLogs.hoursStart,
            hoursEnd: dailyLogs.hoursEnd,
            logs: dailyLogs.logs.map((log) => {
                const optimizedBid = networkOptimizedBids?.find(
                    (nb) =>
                        nb.hourBreak === log.hourBreak &&
                        nb.timeOffset === log.offset &&
                        log.status === AvailabilityStatus.Available &&
                        nb.date === log.date
                );
                const usedPriceFloor = networkPriceFloors?.find(
                    (nb) =>
                        nb.hourBreak === log.hourBreak &&
                        nb.timeOffset === log.offset &&
                        log.status === AvailabilityStatus.Available &&
                        nb.date === log.date
                );
                const advertisedCampaign =
                    typeof optimizedBid !== 'undefined'
                        ? advertisedCampaigns.find(
                              (c) => c.id === optimizedBid.campaignId
                          )
                        : undefined;
                const buyerName =
                    typeof optimizedBid !== 'undefined'
                        ? campaignToBuyerNameMap.find(
                              (m) => m.campaignId === optimizedBid?.campaignId
                          )?.buyerName
                        : '';
                return {
                    ...log,
                    campaignId: optimizedBid?.campaignId,
                    campaignName: advertisedCampaign?.name,
                    buyerName: buyerName,
                    brandName: advertisedCampaign?.brandName,
                    productName: advertisedCampaign?.productName,
                    bidAmount: optimizedBid?.value,
                    priceFloor: usedPriceFloor?.value,
                };
            }),
        };
        return result;
    });
    return inventoryDailyLogsList;
};

export const selectPriceFloors = (state: RootState): CampaignAdSpaceValue[] => {
    const result: CampaignAdSpaceValue[] = [];
    state.ssp.sellersState.individualSellers.forEach((sellersPriceFloor) => {
        sellersPriceFloor.priceFloors.forEach((campaignsPriceFloor) => {
            const campaign = campaignsPriceFloor.advertisedCampaign;
            const campaignCode = `${campaign.name.replace(/ /g, '_')}_${
                campaign.id
            }`;

            campaignsPriceFloor.priceFloorSchedules.forEach((schedule) => {
                sellersPriceFloor.dailyLogsList
                    .filter(
                        (dailyLog) =>
                            dailyLog.networkName ===
                            campaignsPriceFloor.networkName
                    )
                    .forEach((dailyLog) => {
                        dailyLog.logs
                            .filter(
                                (log) =>
                                    log.hourBreak === schedule.time &&
                                    log.status === AvailabilityStatus.Available
                            )
                            .forEach((log) => {
                                result.push({
                                    date: log.date,
                                    timeOffset: log.offset,
                                    hourBreak: log.hourBreak,
                                    campaignId: campaign.id,
                                    time: log.hourBreak + '_' + log.offset,
                                    value: schedule.price ?? 0,
                                    campaignCode: campaignCode,
                                    networkName:
                                        campaignsPriceFloor.networkName,
                                });
                            });
                    });
            });
        });
    });
    return result;
};

export const selectBidAmounts = (state: RootState): CampaignAdSpaceValue[] => {
    const result: CampaignAdSpaceValue[] = [];
    state.ssp.buyersState.buyerCampaigns.forEach((buyerCampaign) => {
        buyerCampaign.campaigns.forEach((campaign) => {
            const campaignCode = `${campaign.name.replace(/ /g, '_')}_${
                campaign.id
            }`;
            campaign.performanceEstimates.forEach((performanceEstimate) => {
                performanceEstimate.estimateSchedules.forEach((schedule) => {
                    state.ssp.sellersState.individualSellers
                        .flatMap((sellers) => sellers.dailyLogsList)
                        .filter(
                            (dailyLog) =>
                                dailyLog.networkName ===
                                performanceEstimate.networkName
                        )
                        .forEach((dailyLog) => {
                            dailyLog.logs
                                .filter(
                                    (log) =>
                                        log.hourBreak === schedule.time &&
                                        log.status ===
                                            AvailabilityStatus.Available
                                )
                                .forEach((log) => {
                                    result.push({
                                        date: log.date,
                                        timeOffset: log.offset,
                                        hourBreak: log.hourBreak,
                                        campaignId: campaign.id,
                                        time: log.hourBreak + '_' + log.offset,
                                        value:
                                            (schedule.estimate ?? 0) *
                                            (performanceEstimate.kpiValue ?? 0),
                                        campaignCode: campaignCode,
                                        networkName:
                                            performanceEstimate.networkName,
                                    });
                                });
                        });
                });
            });
        });
    });
    return result;
};

export const selectCampaignBudgets = (state: RootState) => {
    const result: CampaignBudgets = {};
    state.ssp.buyersState.buyerCampaigns.forEach((buyerCampaign) => {
        buyerCampaign.campaigns.forEach((campaign) => {
            result[`${campaign.name.replace(/ /g, '_')}_${campaign.id}`] =
                campaign.budget ?? 0;
        });
    });
    return result;
};
export const selectCampaignNetworkBudgetAllocations = (state: RootState) => {
    const result: CampaignNetworkValue[] = state.ssp.buyersState.buyerCampaigns
        .flatMap((buyerCampaign) => buyerCampaign.campaigns)
        .flatMap((campaign) =>
            campaign.performanceEstimates.map((performanceEstimate) => {
                const campaignNetworkValue: CampaignNetworkValue = {
                    campaignCode: `${campaign.name.replace(/ /g, '_')}_${
                        campaign.id
                    }`,
                    networkName: performanceEstimate.networkName,
                    value: performanceEstimate.budgetPercentage,
                };
                return campaignNetworkValue;
            })
        );

    return result;
};

export const runOptimizationAsync = createAsyncThunk(
    'ssp/runOptimization',
    async (_arg, thunkApi) => {
        try {
            const response = await axiosInstance.post<OptimizationResponse>(
                `/api/v1/optimizations`,
                undefined,

                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.data;
        } catch (err: unknown) {
            const response: OptimizationResponse = {
                inventoryDailyLogsList: new Array<InventoryDailyLogs>(),
                summaryNetworks: new Array<OutcomeSummaryNetwork>(),
                summaryDemand: undefined,
                summaryDemandCampaigns: undefined,
                summarySupply: undefined,
                dateCreated: new Date(),
                dateStart: '',
                dateEnd: '',
            };
            if (err instanceof AxiosError) {
                const error = err as AxiosError<ProblemDetail>;
                thunkApi.dispatch(
                    setStatusMessage({ message: error.response?.data.detail })
                );
                throw err;
            }
            return response;
        }
    }
);

export const selectOutcome = (state: RootState) => state.ssp.outcomeState;
