import { AvailabilityStatus } from '../AvailabilityStatus';
import { AvailabilityStatusRequest } from '../contracts/seller/AvailabilityStatusRequest';

export class AvailabilityStatusRequestMapper {
    mapEnumAvailabilityStatusRequest = (
        availabilityStatus: AvailabilityStatus
    ): AvailabilityStatusRequest => {
        switch (availabilityStatus) {
            case AvailabilityStatus.NotAvailable:
                return AvailabilityStatusRequest.NotAvailable;
            case AvailabilityStatus.Available:
                return AvailabilityStatusRequest.Available;
            case AvailabilityStatus.Unspecified:
                return AvailabilityStatusRequest.Unspecified;
            case AvailabilityStatus.Unknown:
                return AvailabilityStatusRequest.Unknown;
            default:
                return AvailabilityStatusRequest.Unknown;
        }
    };
}
