// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Box, Button } from '@mui/material';
import { useRef } from 'react';
import * as XLSX from 'xlsx';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

export interface UploadDocumentProps {
    onUploaded: (data: (string | number)[][]) => void;
    buttonText: string;
    disabled?: boolean;
    isIconRight?: boolean;
}

const UploadDocument = (props: UploadDocumentProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const handleUploadButtonClick = () => {
        fileInputRef?.current?.click();
    };

    const handleFile = (file?: File) => {
        if (typeof file === 'undefined') {
            return;
        }
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e?.target?.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */

            const result = data.map((row) => {
                const cells = row as Array<string | number>;
                return cells;
            });
            props.onUploaded(result);
            formRef?.current?.reset();
            // setTable(result);
            // setTable(result);
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    };
    return (
        <Box
            sx={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'unset',
                height: 'max-content',
                '& .MuiButtonBase-root': {
                    minHeight: 'unset',
                },
            }}>
            <Button
                variant="text"
                disabled={props.disabled}
                component="span"
                startIcon={
                    !props.isIconRight ? (
                        <CloudUploadOutlinedIcon
                            sx={{ minHeight: 'unset', marginLeft: 0 }}
                        />
                    ) : null
                }
                onClick={handleUploadButtonClick}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: props.isIconRight
                            ? (theme) => theme.spacing(1)
                            : 0,
                    }}>
                    {props.buttonText}
                    {props.isIconRight && (
                        <CloudUploadOutlinedIcon
                            sx={{
                                minHeight: 'unset',
                                width: 'max-content',
                                gap: (theme) => theme.spacing(1),
                                height: (theme) => theme.spacing(2.5),
                            }}
                        />
                    )}
                </Box>
            </Button>
            <form ref={formRef}>
                <input
                    style={{ display: 'none' }}
                    name="fileInput"
                    ref={fileInputRef}
                    type="file"
                    className="form-control"
                    id="file"
                    accept={'.xlsx'}
                    onChange={(e) => handleFile(e?.target?.files?.[0])}
                />
            </form>
        </Box>
    );
};

export default UploadDocument;
