import { InputAdornment, InputLabel, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import NumericThouthandSeparator from './NumericThousandSeparator';
interface NumericThouthandSeparatorProps {
    onChange: (...args: any[]) => void;
    helperText?: string;
    value: number | undefined;
    withSign?: boolean;
    sign?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    error?: boolean;
}

const NumericThousandSeparatedTextField = ({
    onChange,
    helperText,
    value,
    withSign,
    sign,
    disabled,
    fullWidth,
    error,
}: NumericThouthandSeparatorProps) => {
    const moneyFormatter = useMemo(() => new MoneyFormatter(), []);

    const formatInputDecimalValue = useCallback(
        (value: number | undefined): string => {
            return moneyFormatter.format(value, undefined);
        },
        [moneyFormatter]
    );

    const [formattedValue, setFormattedValue] = useState<string>(
        formatInputDecimalValue(value)
    );

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleBlur = () => {
        setFormattedValue(formatInputDecimalValue(Number(value)));
        setIsFocused(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    useEffect(() => {
        if (!isFocused) {
            const formattedValue = formatInputDecimalValue(value);
            setFormattedValue(formattedValue);
        }
    }, [value, isFocused, formatInputDecimalValue]);

    const getInputStyles = () => {
        return {
            '& .MuiInputBase-adornedStart': {
                pl: 1,
            },
            '& .MuiInputBase-adornedEnd': {
                pr: 1.5,
            },
            '& .MuiInputAdornment-positionStart': {
                mr: 1,
            },
            '& .MuiInputAdornment-positionEnd': {
                ml: 1,
            },
            '& .MuiInputBase-inputAdornedStart': {
                pl: '0 !important',
                background: disabled ? 'transparent' : '#ffffff',
            },
            '& .MuiInputBase-inputAdornedEnd': {
                pr: '0 !important',
                background: disabled ? 'transparent' : '#ffffff',
            },
            background: disabled ? 'transparent' : '#ffffff',
            '& .MuiInputBase-input': {
                background: disabled ? 'transparent' : '#ffffff',
                color: '#050D1C !important',
            },
            '& .MuiTypography-root': {
                color: '#050D1C !important',
                opacity: 0.5,
            },
        };
    };

    return (
        <>
            {helperText && <InputLabel>{helperText}</InputLabel>}
            <TextField
                fullWidth={fullWidth}
                variant="outlined"
                type="text"
                disabled={disabled}
                InputProps={{
                    inputComponent: NumericThouthandSeparator as any,
                    startAdornment:
                        withSign && sign === '$' ? (
                            <InputAdornment position="start">
                                {sign}
                            </InputAdornment>
                        ) : undefined,
                    endAdornment:
                        withSign &&
                        (sign === '%' || sign === 'min' || sign === 'mins') ? (
                            <InputAdornment position="end">
                                {sign}
                            </InputAdornment>
                        ) : undefined,
                }}
                sx={getInputStyles()}
                onChange={(event) => {
                    onChange(event);
                }}
                onFocus={handleFocus}
                error={error}
                onBlur={handleBlur}
                value={formattedValue}
                onKeyDown={(event) =>
                    ['e', 'E', '+', '-'].includes(event.key) &&
                    event.preventDefault()
                }
            />
        </>
    );
};

export default NumericThousandSeparatedTextField;
