import { Alert, Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import {
    selectSspStatus,
    selectStatusMessage,
} from '../../features/ssp/slice-extensions/ssp-slice';
import { useAppSelector } from '../../app/hooks';
import OptimizedBidsInventoryDetails from '../optimized-bids-inventory-detail/OptimizedBidsInventoryDetail';
import { InventoryDailyLogsService } from '../../features/ssp/InventoryDailyLogsService';
import OutcomeSummarySupplyView from '../outcome-summary-supply-view/OutcomeSummarySupplyView';
import OutcomeSummaryDemandView from '../outcome-summary-demand-view/OutcomeSummaryDemandView';
import OutcomeSummaryNetworkView from '../outcome-summary-network-view/OutcomeSummaryNetworkView';
import { LoadingStatus } from '../../features/ssp/LoadingStatus';
import OutcomeSummaryDemandCampaignView from '../outcome-summary-demand-campaign-view/OutcomeSummaryDemandCampaignView';
import { OutcomeState } from '../../features/ssp/OutcomeState';
import GeneralKeyMetrics from '../general-key-metrics/GeneralKeyMetrics';
import NetworkSummaryBox from '../network-summary-box/NetworkSummaryBox';
import { selectAuthenticatedRole } from '../../features/authentication/authenticationSlice';
import { UserRoles } from '../../features/authentication/UserRoles';
import CampaignSummaryBoxes from '../campaign-summary-boxes/CampaignSummaryBosex';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
export interface OutcomeRootProps {
    outcomeState: OutcomeState;
    isLoaded: boolean;
    parmsRole?: UserRoles;
}

const OutcomeRoot = (props: OutcomeRootProps) => {
    const status = useAppSelector(selectSspStatus);
    const statusMessage = useAppSelector(selectStatusMessage);
    const outcome = props.outcomeState;

    const serializer = new InventoryDailyLogsService();
    const isMobile = useMediaQuery('(max-width:920px)');

    const outcomeSummarySupply = outcome.outcomeSummarySupply;
    const outcomeSummaryDemand = outcome.outcomeSummaryDemand;
    const outcomeSummaryDemandCampaigns = outcome.outcomeSummaryDemandCampaigns;
    const outcomeSummaryNetworks = outcome.outcomeSummaryNetworks;
    const location = useLocation();
    const stateRole = useAppSelector(selectAuthenticatedRole);
    const role = props.parmsRole ?? stateRole;
    const isSellerView = role === UserRoles.Seller;
    const isBuyerView = role === UserRoles.Buyer;
    const navigate = useNavigate();
    const [selectedNetwork, setSelectedNetwork] = useState<string>();

    const handleNetworkChange = (_: any, newValue: string) => {
        setSelectedNetwork(newValue);
    };
    const [selectedCampaignId, setSelectedCampaignId] = useState<number>();

    const handleCampaignChange = (_: any, newValue: number) => {
        setSelectedCampaignId(newValue);
    };

    const getSummaryNetwork = () => {
        const networks = outcome.outcomeSummaryNetworks;
        if (networks) {
            const networkNames = networks.map((x) => x.networkName);
            if (!selectedNetwork && networkNames.length > 0) {
                setSelectedNetwork(networkNames.find((x) => x));
            }
            return networkNames;
        }
        return [];
    };
    useEffect(() => {
        if (stateRole === UserRoles.Admin && location.pathname === '/outcome') {
            navigate('/seller-outcome');
        }
        if (
            (stateRole === UserRoles.Seller || stateRole === UserRoles.Buyer) &&
            (location.pathname === '/seller-outcome' ||
                location.pathname === '/buyer-outcome')
        ) {
            navigate('/outcome');
        }
    }, [navigate, stateRole, location.pathname]);

    const getCampaigns = () => {
        const campaigns = outcome.outcomeSummaryDemandCampaigns;
        if (campaigns) {
            const campaignNames = campaigns.map((x) => {
                return {
                    id: x.campaignId,
                    name: x.campaignName,
                };
            });
            if (!selectedCampaignId && campaignNames.length > 0) {
                setSelectedCampaignId(campaignNames.find((x) => x)?.id);
            }
            return campaignNames;
        }
        return [];
    };

    const campaignIds =
        outcome.inventoryDailyLogsList?.flatMap((m) =>
            m.logs
                .filter(
                    (l) =>
                        typeof l.campaignId !== 'undefined' &&
                        l.campaignId !== null
                )
                .map((l) => l.campaignId ?? 0)
        ) ?? [];
    const totalCampaigns = serializer.distinct(campaignIds).length;

    const dayparts = serializer
        .distinct(
            outcome.inventoryDailyLogsList?.map((d) =>
                serializer.getDaypartString(d.daypart)
            )
        )
        .join(', ');

    const hoursStarts = serializer
        .distinct(outcome.inventoryDailyLogsList?.map((d) => d.hoursStart))
        .join(', ');

    const hoursEnds = serializer
        .distinct(outcome.inventoryDailyLogsList?.map((d) => d.hoursEnd))
        .join(', ');
    return (
        <Box>
            {outcome.message === '' &&
                props.isLoaded &&
                !outcome.inventoryDailyLogsList?.length && (
                    <p>
                        The outcome will appear once the optimization is
                        performed.
                    </p>
                )}
            {status === LoadingStatus.Loading && 'Loading...'}
            {status === LoadingStatus.Idle && outcome.message && (
                <p key={'message'}>{outcome.message}</p>
            )}
            {status === LoadingStatus.Idle &&
                outcome.inventoryDailyLogsList?.length > 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 5,
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                gap: 3,
                            }}>
                            <GeneralKeyMetrics
                                dateOfOptimization={serializer.formatDate(
                                    outcome.dateCreated
                                )}
                                numberOfNetworks={
                                    serializer.distinct(
                                        outcome.inventoryDailyLogsList.map(
                                            (b) => b.networkName
                                        )
                                    ).length
                                }
                                numberOfCampaigns={totalCampaigns}
                                dateStart={outcome.dateStart}
                                dateEnd={outcome.dateEnd}
                                daypart={dayparts}
                                hoursStart={hoursStarts}
                                hoursEnd={hoursEnds}></GeneralKeyMetrics>{' '}
                            {isSellerView && outcomeSummarySupply ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 3,
                                        flexWrap: 'wrap',
                                        justifyContent: 'flex-end',
                                        width: '100%',
                                    }}>
                                    {outcomeSummarySupply.rows.map((row, i) => (
                                        <NetworkSummaryBox
                                            key={i}
                                            networkName={row.network}
                                            unitRate={row.percentIncrease}
                                            totalRevenue={
                                                row.totalRevenue
                                            }></NetworkSummaryBox>
                                    ))}{' '}
                                </Box>
                            ) : (
                                <></>
                            )}
                            {isBuyerView && outcomeSummaryDemand && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 3,
                                        flexWrap: 'wrap',
                                        width: '100%',
                                    }}>
                                    <CampaignSummaryBoxes
                                        campaignId={selectedCampaignId ?? 0}
                                        outcomeSummaryDemand={
                                            outcomeSummaryDemand
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    '& .MuiTabs-root': {
                                        minHeight: (theme) =>
                                            theme.spacing(3.75),
                                        alignItems: 'flex-end',
                                    },
                                    '& .MuiButtonBase-root': {
                                        textTransform: 'none',
                                        color: (theme) =>
                                            theme.palette.primary.dark,
                                        minHeight: (theme) =>
                                            theme.spacing(3.75),
                                    },
                                    '& .Mui-selected': {
                                        color: (theme) =>
                                            `${theme.palette.primary.main} !important`,
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                }}>
                                <Tabs
                                    sx={{
                                        '& .MuiTabs-flexContainer': {
                                            overflow: 'auto',
                                        },
                                        '& .MuiTab-root ': {
                                            padding: (theme) =>
                                                `${theme.spacing(
                                                    0.5
                                                )} ${theme.spacing(1.5)}`,
                                            fontSize: (theme) =>
                                                theme.spacing(2),
                                            whiteSpace: 'nowrap',
                                        },
                                        '& .MuiTab-root:not(.Mui-selected)': {
                                            borderBottom: '1px solid #00000026',
                                        },
                                    }}
                                    value={selectedCampaignId}
                                    onChange={handleCampaignChange}>
                                    {getCampaigns().map((campaign) => (
                                        <Tab
                                            key={campaign.id}
                                            value={campaign.id}
                                            label={campaign.name}
                                        />
                                    ))}
                                </Tabs>
                            </Box>
                            {isSellerView && outcomeSummarySupply && (
                                <OutcomeSummarySupplyView
                                    outcomeSummarySupply={
                                        outcomeSummarySupply
                                    }></OutcomeSummarySupplyView>
                            )}
                            {outcomeSummaryDemand && isBuyerView && (
                                <OutcomeSummaryDemandView
                                    campaignId={selectedCampaignId ?? 0}
                                    isSeller={isSellerView}
                                    outcomeSummaryDemand={
                                        outcomeSummaryDemand
                                    }></OutcomeSummaryDemandView>
                            )}
                            {isBuyerView &&
                                outcomeSummaryDemandCampaigns?.map(
                                    (outcomeSummaryDemandCampaign, index) => {
                                        return (
                                            outcomeSummaryDemandCampaign.campaignId ===
                                                selectedCampaignId && (
                                                <OutcomeSummaryDemandCampaignView
                                                    key={index}
                                                    isSeller={isSellerView}
                                                    outcomeSummaryDemandCampaign={
                                                        outcomeSummaryDemandCampaign
                                                    }></OutcomeSummaryDemandCampaignView>
                                            )
                                        );
                                    }
                                )}

                            {isSellerView &&
                                outcomeSummaryNetworks.length > 0 && (
                                    <>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                '& .MuiTabs-root': {
                                                    minHeight: (theme) =>
                                                        theme.spacing(3.75),
                                                    alignItems: 'flex-end',
                                                },
                                                '& .MuiButtonBase-root': {
                                                    textTransform: 'none',
                                                    color: (theme) =>
                                                        theme.palette.primary
                                                            .dark,
                                                    minHeight: (theme) =>
                                                        theme.spacing(3.75),
                                                },
                                                '& .Mui-selected': {
                                                    color: (theme) =>
                                                        `${theme.palette.primary.main} !important`,
                                                },
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 2,
                                            }}>
                                            <Tabs
                                                sx={{
                                                    '& .MuiTabs-flexContainer':
                                                        {
                                                            overflow: 'auto',
                                                        },
                                                    '& .MuiTab-root ': {
                                                        padding: (theme) =>
                                                            `${theme.spacing(
                                                                0.5
                                                            )} ${theme.spacing(
                                                                1.5
                                                            )}`,
                                                        fontSize: (theme) =>
                                                            theme.spacing(2),
                                                        whiteSpace: 'nowrap',
                                                    },
                                                    '& .MuiTab-root:not(.Mui-selected)':
                                                        {
                                                            borderBottom:
                                                                '1px solid #00000026',
                                                        },
                                                }}
                                                value={selectedNetwork}
                                                onChange={handleNetworkChange}>
                                                {getSummaryNetwork().map(
                                                    (network) => (
                                                        <Tab
                                                            key={network}
                                                            value={network}
                                                            label={network}
                                                        />
                                                    )
                                                )}
                                            </Tabs>
                                        </Box>
                                        {outcomeSummaryNetworks.map(
                                            (outcomeSummaryNetwork, i) =>
                                                outcomeSummaryNetwork.networkName ===
                                                    selectedNetwork && (
                                                    <OutcomeSummaryNetworkView
                                                        key={i}
                                                        outcomeSummaryNetwork={
                                                            outcomeSummaryNetwork
                                                        }></OutcomeSummaryNetworkView>
                                                )
                                        )}
                                    </>
                                )}
                            {outcome.inventoryDailyLogsList.length > 0 && (
                                <>
                                    {isBuyerView && (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                '& .MuiTabs-root': {
                                                    minHeight: (theme) =>
                                                        theme.spacing(3.75),
                                                    alignItems: 'flex-end',
                                                    fontSize: (theme) =>
                                                        theme.spacing(2),
                                                },
                                                '& .MuiButtonBase-root': {
                                                    textTransform: 'none',
                                                    color: (theme) =>
                                                        theme.palette.primary
                                                            .dark,
                                                    minHeight: (theme) =>
                                                        theme.spacing(3.75),
                                                },
                                                '& .Mui-selected': {
                                                    color: (theme) =>
                                                        `${theme.palette.primary.main} !important`,
                                                },
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 2,
                                            }}>
                                            <Tabs
                                                sx={{
                                                    '& .MuiTabs-flexContainer':
                                                        {
                                                            overflow: 'auto',
                                                        },
                                                    '& .MuiTab-root ': {
                                                        padding: (theme) =>
                                                            `${theme.spacing(
                                                                0.5
                                                            )} ${theme.spacing(
                                                                1.5
                                                            )}`,
                                                        whiteSpace: 'nowrap',
                                                    },
                                                    '& .MuiTab-root:not(.Mui-selected)':
                                                        {
                                                            borderBottom:
                                                                '1px solid #00000026',
                                                        },
                                                }}
                                                value={selectedNetwork}
                                                onChange={handleNetworkChange}>
                                                {getSummaryNetwork().map(
                                                    (network) => (
                                                        <Tab
                                                            key={network}
                                                            value={network}
                                                            label={network}
                                                        />
                                                    )
                                                )}
                                            </Tabs>
                                        </Box>
                                    )}
                                    {outcome.inventoryDailyLogsList.map(
                                        (inventoryDailyLogs, index) => {
                                            return (
                                                (!selectedNetwork ||
                                                    inventoryDailyLogs.networkName ===
                                                        selectedNetwork) && (
                                                    <OptimizedBidsInventoryDetails
                                                        key={index}
                                                        showAllocatedOnly={
                                                            role ===
                                                            UserRoles.Buyer
                                                        }
                                                        showPriceFloor={
                                                            role !==
                                                            UserRoles.Buyer
                                                        }
                                                        inventoryDailyLogs={
                                                            inventoryDailyLogs
                                                        }></OptimizedBidsInventoryDetails>
                                                )
                                            );
                                        }
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                )}
            {status === LoadingStatus.Failed && (
                <Alert severity="error">
                    {outcome.output === ''
                        ? !statusMessage
                            ? 'Unexpected error has occurred.'
                            : statusMessage
                        : outcome.output}
                </Alert>
            )}
        </Box>
    );
};

export default OutcomeRoot;
