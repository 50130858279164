import { PriceFloor } from '../PriceFloor';
import { Daypart } from '../Daypart';
import { IdGenerator } from '../IdGenerator';
import { PriceFloorSchedule } from '../PriceFloorSchedule';
import { TimeExtensions } from '../extensions/TimeExtensions';
import { ParseExtension } from '../extensions/ParseExtension';
import { StringsExtension } from '../extensions/StringsExtension';
import { ObjectState } from '../../ObjectState';

export class PriceFloorsParser {
    private networkNameMarker: string = 'Network';
    private daypartMarker: string = 'Daypart';
    private hoursStartMarker: string = 'Hours Start';
    private hoursEndMarker: string = 'Hours End';
    private parseExtensions = new ParseExtension(this.cells);
    private stringsExtension = new StringsExtension();
    private timeExtensions = new TimeExtensions();
    constructor(private cells: (string | number)[][]) {}

    parse(): PriceFloor[] {
        const networkName = this.stringsExtension.trimWhiteSpaces(
            this.parseExtensions.getHeadValue(this.networkNameMarker).toString()
        );
        const daypart = this.stringsExtension.stringsEqual(
            this.parseExtensions.getHeadValue(this.daypartMarker),
            'Daytime'
        )
            ? Daypart.Daytime
            : Daypart.Unknown;
        const hoursStart = this.parseExtensions.parseHoursStartEnd(
            this.parseExtensions.getHeadValue(this.hoursStartMarker)
        );
        const hoursEnd = this.parseExtensions.parseHoursStartEnd(
            this.parseExtensions.getHeadValue(this.hoursEndMarker)
        );
        const startIndex =
            this.parseExtensions.getHeadIndex(this.hoursEndMarker) ?? 0;

        const lastRowIndex = this.cells.length - 1;
        if (lastRowIndex <= 0) return [];

        const times = this.parseTimes(startIndex + 1, lastRowIndex + 1);

        if (times.length === 0) {
            return [];
        }
        const campaigns = this.parseCampaignsByRowIndex(times[0].index);

        const result: PriceFloor[] = [];
        if (campaigns.length === 0 || times.length === 0) return result;

        for (const column of campaigns) {
            let priceFloorSchedules: PriceFloorSchedule[] = [];

            for (const row of times) {
                let price: number = 0;
                const currentCell = this.cells[row.index][column.index];
                if (typeof currentCell !== 'undefined') {
                    price =
                        this.parseExtensions.parseValueToNumber(currentCell) ??
                        0;
                }

                const scheduleTime = row.value ?? '';
                priceFloorSchedules.push({
                    time: scheduleTime,
                    price: price,
                    isAvailable: true,
                });
            }
            result.push({
                id: IdGenerator.generate(),
                networkName,
                daypart,
                hoursStart,
                hoursEnd,
                advertisedCampaign: {
                    id: IdGenerator.generate(),
                    name: column.value,
                    brandName: '',
                    buyerDisplayName: '',
                    productName: '',
                    minMinutesBetweenAds: undefined,
                    budget: undefined,
                },
                priceFloorSchedules: priceFloorSchedules,
                objectState: ObjectState.Added,
            });
        }
        return result;
    }

    private parseTimes(
        startIndex: number,
        endIndex: number
    ): { index: number; value: string }[] {
        const times: { index: number; value: string }[] = [];

        for (let i = startIndex; i < endIndex; i++) {
            if (this.parseExtensions.isEmptyRow(this.cells[i])) {
                continue;
            }
            const currentCell = this.cells[i]?.[0];
            if (typeof currentCell === 'undefined') {
                continue;
            }
            if (this.timeExtensions.isUSTimeFormat(currentCell.toString()))
                times.push({ index: i, value: this.cells[i][0].toString() });
        }
        return times;
    }

    private parseCampaignsByRowIndex(
        rowIndex: number
    ): { index: number; value: string }[] {
        const campaigns: { index: number; value: string }[] = [];

        if (rowIndex < 0 || rowIndex >= this.cells.length) return campaigns;
        const previousRowIndex = rowIndex - 1;
        const row = this.cells[previousRowIndex];
        if (typeof row === 'undefined' || row.length === 0) return campaigns;

        for (let i = 0; i < row.length; i++) {
            const currentCell = this.cells[previousRowIndex][i];
            if (typeof currentCell === 'undefined') {
                continue;
            }
            const value = currentCell.toString();
            if (typeof value === 'string') {
                campaigns.push({ index: i, value });
            }
        }

        for (const campaign of campaigns) {
            campaign.value = this.stringsExtension.trimWhiteSpaces(
                campaign.value
            );
        }

        return campaigns;
    }
}
