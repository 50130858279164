import { PerformanceEstimate } from '../../features/ssp/PerformanceEstimate';
import { PerformanceEstimatesParser } from '../../features/ssp/PerformanceEstimatesParser';
import UploadDocument from '../upload-document/UploadDocument';
export interface UploadBuyerCampaingPerformanceEstimateProps {
    onUploaded: (performanceEstimates: PerformanceEstimate[]) => void;
}
const UploadBuyerCampaingPerformanceEstimates = ({
    onUploaded,
}: UploadBuyerCampaingPerformanceEstimateProps) => {
    const onDocumentUploaded = (cells: (string | number)[][]) => {
        try {
            const performanceEstimates = new PerformanceEstimatesParser(
                cells
            ).parse();
            onUploaded(performanceEstimates);
        } catch (error) {
            alert(error);
        }
    };
    return (
        <>
            <UploadDocument
                onUploaded={onDocumentUploaded}
                buttonText="Upload Performance Estimate"
            />
        </>
    );
};
export default UploadBuyerCampaingPerformanceEstimates;
