export class StringsExtension {
    trimIfString(value: number | string | undefined) {
        if (typeof value === 'string') {
            return value.trim();
        }

        return value;
    }

    stringsEqual(
        value: string | number | undefined,
        expected: string | number | undefined
    ): boolean {
        const result =
            this.trimIfString(value?.toString().toLowerCase()) ===
            this.trimIfString(expected?.toString().toLowerCase());
        return result;
    }

    returnIfNotEmpty(id: any, value: string): string {
        if (id === null || typeof id === 'undefined' || id === 0 || id === '') {
            return '';
        }
        return value;
    }

    removePlusNegativeSign = (value: string): string => {
        if (value !== undefined || value !== '') {
            value = value.toString().replace('-', '');
            value = value.toString().replace('+', '');
            return value;
        }
        return value;
    };

    trimWhiteSpaces(str: string): string {
        return str.trim().replace(/\s+/g, ' ');
    }
}
