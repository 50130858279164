import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    TextField,
} from '@mui/material';
import { UserRoles } from '../../features/authentication/UserRoles';
import React, { useState } from 'react';
import axios, { HttpStatusCode } from 'axios';
import axiosInstance from '../../features/axios/axiosInstance';

interface AdminChangePasswordDialogProps {
    isOpen: boolean;
    id: number;
    role: UserRoles;
    displayName: string;
    email: string;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const AdminChangePasswordDialog = ({
    isOpen,
    id,
    role,
    displayName,
    email,
    setIsOpen,
}: AdminChangePasswordDialogProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<boolean>();

    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [repeatPasswordError, setRepeatPasswordError] = useState<boolean>();

    const [isRequestSuccessful, setIsRequestSuccessful] =
        useState<boolean>(true);

    const areFieldsValid = (): boolean => {
        let isValid = true;

        if (password.length < 8 || passwordError) {
            setPasswordError(true);
            isValid = false;
        }

        if (
            repeatPassword.length < 8 ||
            repeatPassword !== password ||
            repeatPasswordError
        ) {
            setRepeatPasswordError(true);
            isValid = false;
        }

        return isValid;
    };

    const changeUserPasswordAsync = async (): Promise<boolean | undefined> => {
        if (!areFieldsValid()) {
            return;
        }
        try {
            const body = {
                newPassword: password,
            };
            const response = await axiosInstance.put(
                `/api/v1/user-accounts/${id}/password`,
                body
            );
            return response.status === HttpStatusCode.Ok;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === HttpStatusCode.Unauthorized) {
                    return false;
                }
                return false;
            }
            return false;
        }
    };

    const handleSaveAsync = async () => {
        setIsLoading(true);
        const success = await changeUserPasswordAsync();
        if (typeof success !== 'undefined') {
            setIsRequestSuccessful(success);
            if (success) {
                clearAllStates();
                setIsOpen(false);
            }
        }
        setIsLoading(false);
    };

    const handleCancel = () => {
        clearAllStates();
        setIsOpen(false);
    };

    const clearAllStates = () => {
        setPassword('');
        setRepeatPassword('');
        setPasswordError(false);
        setRepeatPasswordError(false);
        setIsRequestSuccessful(true);
    };

    return (
        <Dialog
            open={isOpen}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                elevation: 0,
            }}
            onClose={handleCancel}>
            <DialogTitle>{'Change Password'}</DialogTitle>
            <DialogContent
                sx={{
                    '& .MuiTextField-root': {
                        mr: 1,
                        mb: 1,
                    },
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <InputLabel>Name</InputLabel>
                <TextField
                    variant="outlined"
                    value={displayName}
                    InputProps={{
                        readOnly: true,
                    }}
                />{' '}
                <InputLabel>Role</InputLabel>
                <TextField
                    variant="outlined"
                    value={role}
                    InputProps={{
                        readOnly: true,
                    }}
                />{' '}
                <InputLabel>Email</InputLabel>
                <TextField
                    variant="outlined"
                    value={email}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <InputLabel error={passwordError}>
                    {passwordError && password.length === 0
                        ? 'Password is required'
                        : passwordError
                        ? 'Invalid password'
                        : 'Password'}
                </InputLabel>
                <TextField
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(event) => {
                        const value = event.target.value;
                        setPassword(value);
                        if (value.length >= 8) {
                            setPasswordError(false);
                        } else {
                            setPasswordError(true);
                        }
                        setIsRequestSuccessful(true);
                    }}
                    error={passwordError}
                />
                <InputLabel error={repeatPasswordError}>
                    {repeatPasswordError && repeatPassword.length === 0
                        ? 'Repeat Password is required'
                        : repeatPasswordError
                        ? 'Passwords do not match'
                        : 'Repeat Password'}
                </InputLabel>
                <TextField
                    type="password"
                    variant="outlined"
                    value={repeatPassword}
                    onChange={(event) => {
                        const value = event.target.value;
                        setRepeatPassword(value);
                        if (value !== password) {
                            setRepeatPasswordError(true);
                        } else {
                            setRepeatPasswordError(false);
                        }
                        setIsRequestSuccessful(true);
                    }}
                    error={repeatPasswordError}
                />
                {!isRequestSuccessful && (
                    <DialogContentText sx={{ color: '#d32f2f' }}>
                        Something went wrong. Please try again.
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} disabled={isLoading}>
                    Cancel
                </Button>
                <Button
                    onClick={async () => await handleSaveAsync()}
                    disabled={isLoading}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdminChangePasswordDialog;
