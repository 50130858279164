import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    addBuyerCampaign,
    selectBuyerCampaigns,
} from '../../features/ssp/slice-extensions/ssp-slice';
import { BuyerCampaign } from '../../features/ssp/BuyerCampaign';
import { UserRoles } from '../../features/authentication/UserRoles';
import { ObjectState } from '../../features/ObjectState';
import RequireRoles from '../../components/require-roles/RequireRoles';
import BuyerCampaignForm from '../../components/buyer-campaign-form/BuyerCampaignForm';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectAuthenticatedRole } from '../../features/authentication/authenticationSlice';
import { loadBuyersStateAsync } from '../../features/ssp/slice-extensions/buyers/buyer-state-slice-extensions';
import BuyerCancelSaveButtons from '../../components/buyer-cancel-save-buttons/BuyerCancelSaveButtons';
import { SyncOutlined } from '@mui/icons-material';

const BuyerHome = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const role = useAppSelector(selectAuthenticatedRole);
    const isMobile = useMediaQuery('(max-width:920px)');

    useEffect(() => {
        if (
            role === UserRoles.Admin &&
            params.id !== '' &&
            typeof params.id !== 'undefined'
        ) {
            dispatch(loadBuyersStateAsync(params.id));
        }
    }, [dispatch, role, params.id]);
    const buyerCampaigns: BuyerCampaign[] =
        useAppSelector(selectBuyerCampaigns) ?? [];

    const newBuyerCampaign: BuyerCampaign = {
        buyer: {
            name: `Buyer ${buyerCampaigns.length + 1}`,
            id: new Date().getTime(),
            objectState: ObjectState.Added,
        },
        campaigns: [
            {
                id: new Date().getTime(),
                name: 'Campaign 1',
                brandName: 'Brand 1',
                productName: 'Product 1',
                budget: 3000,
                performanceEstimates: [],
                minMinutesBetweenAds: 1,
                objectState: ObjectState.Added,
            },
            {
                id: new Date().getTime() + 1,
                name: 'Campaign 2',
                brandName: 'Brand 2',
                productName: 'Product 2',
                budget: 5000,
                performanceEstimates: [],
                minMinutesBetweenAds: 1,
                objectState: ObjectState.Added,
            },
        ],
    };

    const createNewBuyer = () => {
        dispatch(addBuyerCampaign(newBuyerCampaign));
    };

    const minDate = new Date(0, 0, 0);
    const maxDateModified = buyerCampaigns
        .flatMap((bc) => bc.campaigns)
        .map((c) =>
            typeof c.dateModified !== 'undefined' && c.dateModified !== ''
                ? new Date(c.dateModified)
                : minDate
        )
        .reduce(
            (previous, current) =>
                (previous ?? minDate) > (current ?? minDate)
                    ? previous
                    : current,
            minDate
        );
    return (
        <Box sx={{ my: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 2,
                }}>
                <Typography
                    variant="h4"
                    component="h1"
                    sx={{ fontSize: (theme) => theme.spacing(4) }}>
                    DenaliTV Ad Campaign Optimization
                </Typography>
                {typeof params.id === 'undefined' && (
                    <RequireRoles roles={UserRoles.Admin}>
                        <Button
                            sx={{
                                fontWeight: 600,
                                fontSize: (theme) => theme.spacing(2),
                            }}
                            onClick={createNewBuyer}
                            disabled={buyerCampaigns.length >= 5}
                            startIcon={<AddCircleOutlineIcon />}>
                            Add New Buyer
                        </Button>
                    </RequireRoles>
                )}
            </Box>
            <Box sx={{ my: 5 }}>
                {buyerCampaigns.length > 0 && buyerCampaigns ? (
                    <>
                        {buyerCampaigns.map((buyerCampaign, index) => (
                            <Box sx={{ my: 2 }} key={index}>
                                <BuyerCampaignForm
                                    isSaveBarVisible={index === 0}
                                    buyerCampaign={buyerCampaign}
                                    maxDateModified={maxDateModified}
                                    minDate={minDate}
                                />
                            </Box>
                        ))}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                flexDirection: isMobile ? 'column' : 'row',
                                gap: 1,
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}></Box>
                        </Box>
                    </>
                ) : (
                    <Typography variant="h5">No buyers available</Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: isMobile ? 'flex-end' : 'center',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'flex-end',
                        gap: 1,
                    }}>
                    {maxDateModified > minDate ? (
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            sx={{
                                mb: 0,
                                fontSize: (theme) => theme.spacing(1.75),
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                            }}>
                            <SyncOutlined
                                fontSize="small"
                                sx={{
                                    width: (theme) => theme.spacing(1.75),
                                }}
                            />
                            Last saved:{' '}
                            {maxDateModified.toLocaleString('en-US')}
                        </Typography>
                    ) : (
                        <div></div>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                        <BuyerCancelSaveButtons />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BuyerHome;
