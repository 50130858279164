import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { OutcomeSummarySupply } from '../../features/ssp/OutcomeSummarySupply';
import { MoneyFormatter } from '../../features/ssp/MoneyFormatter';
import { PercentFormatter } from '../../features/ssp/PercentFormatter';
import { NetworkColorHelper } from '../../features/ssp/NetworkColorHelper';

interface OutcomeSummarySupplyViewProps {
    outcomeSummarySupply: OutcomeSummarySupply;
}

const OutcomeSummarySupplyView = (props: OutcomeSummarySupplyViewProps) => {
    const moneyFormatter = new MoneyFormatter();
    const percentFormatter = new PercentFormatter();
    const isMobile = useMediaQuery('(max-width:920px)');
    const colorHelper = NetworkColorHelper.getInstance();

    return (
        <Box
            sx={{
                background: (theme) => theme.palette.background.paper,
                p: 2,
                borderRadius: 2,
            }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Summary
            </Typography>
            {props.outcomeSummarySupply.rows.length > 0 && (
                <Box sx={{ width: isMobile ? 'auto' : 'fit-content' }}>
                    <TableContainer>
                        <Table aria-label="Outcome summary supply" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Network
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        # Ads Listed
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        # Ads Sold
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Avg Sale Price
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Avg Price Floor
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Avg Sale Price
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            pr: 0.5,
                                            pl: 0.5,
                                        }}>
                                        Total Revenue
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.outcomeSummarySupply.rows.map(
                                    (row, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                <Box
                                                    key={row.network}
                                                    sx={{
                                                        pl: 1,
                                                        pr: 1,
                                                        borderRadius: 1,
                                                        background:
                                                            colorHelper.getColor(
                                                                row.network
                                                            ),
                                                        border: '1px solid #00000026',
                                                        width: 'fit-content',
                                                        height: (theme) =>
                                                            theme.spacing(3),
                                                    }}>
                                                    {row.network}
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.noOfAds}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {row.noOfAdsSold}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.avgSalePrice
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.avgPriceFloor
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {percentFormatter.format(
                                                    row.percentIncrease
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    pr: 0.5,
                                                    pl: 0.5,
                                                }}>
                                                {moneyFormatter.format(
                                                    row.totalRevenue
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};
export default OutcomeSummarySupplyView;
