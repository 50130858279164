export class SspDefaultSettings {
    static times: string[] = [
        '6AM',
        '7AM',
        '8AM',
        '9AM',
        '10AM',
        '11AM',
        '12noon',
        '1PM',
        '2PM',
    ];
    static maxTimes: string[] = [
        '6AM',
        '7AM',
        '8AM',
        '9AM',
        '10AM',
        '11AM',
        '12noon',
        '1PM',
        '2PM',
    ];
    static networkNames: string[] = ['Reelz', 'Lifestyle TV'];
    static sellerNames: string[] = ['Seller 1', 'Seller 2'];
    static undefinedSellerName: string = 'Seller';
    static defaultPriceFloorPrice: number = 3000;
}
