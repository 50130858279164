import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import axiosInstance from '../../../axios/axiosInstance';
import { ObjectState } from '../../../ObjectState';
import { BuyerCampaign } from '../../BuyerCampaign';
import { UpdateBuyerRequest } from '../../contracts/buyers/UpdateBuyerRequest';
import { AddBuyerRequest } from '../../contracts/buyers/AddBuyerRequest';
import { AddBuyerResponse } from '../../contracts/buyers/AddBuyerResponse';
import { CreateCampaignRequest } from '../../contracts/buyers/CreateCampaignRequest';
import { CreateCampaignResponse } from '../../contracts/buyers/CreateCampaignResponse';
import { Campaign } from '../../Campaign';
import { UpdateCampaignRequest } from '../../contracts/buyers/UpdateCampaignRequest';
import { CreatePerformanceEstimateReqeuest } from '../../contracts/buyers/CreatePerformanceEstimateRequest';
import { PerformanceEstimateResponse } from '../../contracts/buyers/PerformanceEstimateResponse';
import { PerformanceEstimate } from '../../PerformanceEstimate';
import { UpdatePerformanceEstimateRequest } from '../../contracts/buyers/UpdatePerformanceEstimateRequest';
import { ProblemDetail } from '../../contracts/ProblemDetail';
import { AxiosError } from 'axios';
import { setStatusMessage } from '../ssp-slice';

export const saveBuyersStateAsync = createAsyncThunk(
    'ssp/saveBuyersState',
    async (_, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        try {
            let buyerCampaigns = await deletePerformanceEstimatesAsync(
                state.ssp.buyersState.buyerCampaigns
            );
            buyerCampaigns = await deleteCampaignsAsync(buyerCampaigns);
            buyerCampaigns = await deleteBuyersAsync(buyerCampaigns);

            buyerCampaigns = await createBuyersAsync(buyerCampaigns);
            buyerCampaigns = await updateBuyersAsync(buyerCampaigns);

            buyerCampaigns = await createCampaignsAsync(buyerCampaigns);
            buyerCampaigns = await updateCampaignsAsync(buyerCampaigns);

            buyerCampaigns = await createPerformanceEstimatesAsync(
                buyerCampaigns
            );
            buyerCampaigns = await updatePerformanceEstimatesAsync(
                buyerCampaigns
            );

            return { ...state.ssp.buyersState, buyerCampaigns: buyerCampaigns };
        } catch (err) {
            const error = err as AxiosError<ProblemDetail>;
            thunkApi.dispatch(
                setStatusMessage({ message: error.response?.data.detail })
            );
            throw err;
        }
    }
);

const createBuyersAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const newBuyers =
        buyerCampaigns
            ?.map((buyer) => buyer.buyer)
            .filter((buyer) => buyer.objectState === ObjectState.Added) ?? [];
    if (newBuyers.length <= 0) {
        return buyerCampaigns;
    }
    const addBuyersRequests: AddBuyerRequest[] = newBuyers.map((buyer) => {
        return {
            name: buyer.name,
        };
    });
    for (let i = 0; i < addBuyersRequests.length; i++) {
        const addBuyersRequest = addBuyersRequests[i];
        const response = await axiosInstance.post<AddBuyerResponse>(
            `/api/v1/buyers`,
            addBuyersRequest,
            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );
        buyerCampaigns = buyerCampaigns.map((buyerCampaign) =>
            buyerCampaign.buyer.name === response.data.name
                ? ({
                      ...buyerCampaign,
                      buyer: {
                          ...buyerCampaign.buyer,
                          id: response.data.id,
                          name: response.data.name,
                          objectState: ObjectState.Original,
                      },
                  } as BuyerCampaign)
                : buyerCampaign
        );
    }

    return buyerCampaigns;
};

const updateBuyersAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const updatedBuyers =
        buyerCampaigns
            ?.map((buyer) => buyer.buyer)
            .filter((buyer) => buyer.objectState === ObjectState.Modified) ??
        [];
    if (updatedBuyers.length <= 0) {
        return buyerCampaigns;
    }

    const updateRequests = updatedBuyers.map(async (buyer) => {
        const updateBuyersRequest: UpdateBuyerRequest = {
            name: buyer.name,
        };
        return axiosInstance.put(
            `/api/v1/buyers/${buyer.id}`,
            updateBuyersRequest,
            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );
    });
    await Promise.all(updateRequests);
    buyerCampaigns = buyerCampaigns.map((buyerCampaign) => {
        if (buyerCampaign.buyer.objectState !== ObjectState.Modified) {
            return buyerCampaign;
        }
        return {
            ...buyerCampaign,
            buyer: {
                ...buyerCampaign.buyer,
                objectState: ObjectState.Original,
            },
        };
    });
    return buyerCampaigns;
};

const deleteBuyersAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const deletedBuyers =
        buyerCampaigns
            ?.map((buyer) => buyer.buyer)
            .filter((buyer) => buyer.objectState === ObjectState.Deleted) ?? [];
    if (deletedBuyers.length <= 0) {
        return buyerCampaigns;
    }

    const deleteRequests = deletedBuyers.map(async (buyer) => {
        return axiosInstance.delete(
            `/api/v1/buyers/${buyer.id}`,

            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );
    });
    await Promise.all(deleteRequests);
    buyerCampaigns = buyerCampaigns.filter(
        (buyerCampaign) =>
            buyerCampaign.buyer.objectState !== ObjectState.Deleted
    );
    return buyerCampaigns;
};

const substituteCampaign = (
    buyerCampaigns: BuyerCampaign[],
    substitute: (c: Campaign) => Campaign,
    selector: (c: Campaign) => boolean
) => {
    const result = buyerCampaigns.map((buyerCampaign) =>
        buyerCampaign.campaigns.some(selector)
            ? {
                  ...buyerCampaign,
                  campaigns: buyerCampaign.campaigns.map((element) =>
                      selector(element) ? substitute(element) : element
                  ),
              }
            : buyerCampaign
    );
    return result;
};

const substitutePerformanceEstimate = (
    buyerCampaigns: BuyerCampaign[],
    substitute: (p: PerformanceEstimate) => PerformanceEstimate,
    selector: (c: Campaign, p: PerformanceEstimate) => boolean
) => {
    const result = buyerCampaigns.map((buyerCampaign) =>
        buyerCampaign.campaigns.some((c) =>
            c.performanceEstimates.some((p) => selector(c, p))
        )
            ? {
                  ...buyerCampaign,
                  campaigns: buyerCampaign.campaigns.map((element) =>
                      element.performanceEstimates.some((p) =>
                          selector(element, p)
                      )
                          ? {
                                ...element,
                                performanceEstimates:
                                    element.performanceEstimates.map((p) =>
                                        selector(element, p) ? substitute(p) : p
                                    ),
                            }
                          : element
                  ),
              }
            : buyerCampaign
    );
    return result;
};

const createCampaignsAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const newCampaigns =
        buyerCampaigns
            ?.flatMap((buyerCampaign) =>
                buyerCampaign.campaigns.map((campaign) => {
                    return { ...campaign, ownerId: buyerCampaign.buyer.id };
                })
            )
            .filter((campaign) => campaign.objectState === ObjectState.Added) ??
        [];
    if (newCampaigns.length <= 0) {
        return buyerCampaigns;
    }
    const createCampaignRequests: CreateCampaignRequest[] = newCampaigns.map(
        (campaign) => {
            return {
                ownerId: campaign.ownerId,
                name: campaign.name,
                brandName: campaign.brandName,
                productName: campaign.productName,
                budget:
                    campaign.budget && !Number.isNaN(campaign.budget)
                        ? campaign.budget
                        : 0,
                minSecondsBetweenAds: 60 * campaign.minMinutesBetweenAds,
            };
        }
    );

    for (let i = 0; i < createCampaignRequests.length; i++) {
        const createCampaignRequest = createCampaignRequests[i];
        const response = await axiosInstance.post<CreateCampaignResponse>(
            `/api/v1/campaigns`,
            createCampaignRequest,
            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );
        buyerCampaigns = substituteCampaign(
            buyerCampaigns,
            (c) => {
                return {
                    ...c,
                    id: response.data.id,
                    brandName: response.data.brandName,
                    budget: response.data.budget,
                    minMinutesBetweenAds:
                        response.data.minSecondsBetweenAds / 60,
                    name: response.data.name,
                    productName: response.data.productName,
                    dateModified: response.data.dateModified,
                    objectState: ObjectState.Original,
                };
            },
            (c) => c.name === response.data.name
        );
    }

    return buyerCampaigns;
};

const updateCampaignsAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const updatedCampaigns =
        buyerCampaigns
            ?.flatMap((buyerCampaign) => buyerCampaign.campaigns)
            .filter(
                (campaign) => campaign.objectState === ObjectState.Modified
            ) ?? [];
    if (updatedCampaigns.length <= 0) {
        return buyerCampaigns;
    }

    const updateRequests = updatedCampaigns.map(async (campaign) => {
        const updateBuyersRequest: UpdateCampaignRequest = {
            name: campaign.name,
            brandName: campaign.brandName,
            productName: campaign.productName,
            budget:
                campaign.budget && !Number.isNaN(campaign.budget)
                    ? campaign.budget
                    : 0,
            minSecondsBetweenAds: 60 * campaign.minMinutesBetweenAds,
        };
        return axiosInstance.put(
            `/api/v1/campaigns/${campaign.id}`,
            updateBuyersRequest,
            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );
    });
    await Promise.all(updateRequests);
    buyerCampaigns = substituteCampaign(
        buyerCampaigns,
        (campaign) => {
            return { ...campaign, objectState: ObjectState.Original };
        },
        (campaign) => campaign.objectState === ObjectState.Modified
    );
    return buyerCampaigns;
};

const deleteCampaignsAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const deletedCampaigns =
        buyerCampaigns
            ?.flatMap((buyerCampaign) => buyerCampaign.campaigns)
            .filter(
                (campaign) => campaign.objectState === ObjectState.Deleted
            ) ?? [];
    if (deletedCampaigns.length <= 0) {
        return buyerCampaigns;
    }

    const deleteRequests = deletedCampaigns.map(async (campaign) => {
        return axiosInstance.delete(
            `/api/v1/campaigns/${campaign.id}`,

            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );
    });
    await Promise.all(deleteRequests);
    buyerCampaigns = buyerCampaigns.map((buyerCampaign) => {
        if (
            buyerCampaign.campaigns.some(
                (campaign) => campaign.objectState === ObjectState.Deleted
            )
        ) {
            return {
                ...buyerCampaign,
                campaigns: buyerCampaign.campaigns.filter(
                    (campaign) => campaign.objectState !== ObjectState.Deleted
                ),
            };
        }
        return buyerCampaign;
    });
    return buyerCampaigns;
};

const createPerformanceEstimatesAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const newPerformanceEstimates =
        buyerCampaigns
            ?.flatMap((buyerCampaign) =>
                buyerCampaign.campaigns.flatMap((campaign) => {
                    return campaign.performanceEstimates.map(
                        (performanceEstimate) => {
                            return {
                                ...performanceEstimate,
                                ownerId: buyerCampaign.buyer.id,
                                campaignId: campaign.id,
                            };
                        }
                    );
                })
            )
            .filter(
                (performanceEstimate) =>
                    performanceEstimate.objectState === ObjectState.Added
            ) ?? [];
    if (newPerformanceEstimates.length <= 0) {
        return buyerCampaigns;
    }
    const createPerformanceEstimateRequests: CreatePerformanceEstimateReqeuest[] =
        newPerformanceEstimates.map((performanceEstimate) => {
            return {
                campaignId: performanceEstimate.campaignId,
                ownerId: performanceEstimate.ownerId,
                kpiValue: performanceEstimate.kpiValue,
                budgetPercentage: performanceEstimate.budgetPercentage,
                kpi: performanceEstimate.kpi,
                client: performanceEstimate.client,
                hours: performanceEstimate.hours,
                daypart: performanceEstimate.daypart,
                networkName: performanceEstimate.networkName,
                timeEstimates: performanceEstimate.estimateSchedules.map(
                    (schedule) => {
                        return {
                            time: schedule.time,
                            estimate: schedule.estimate,
                        };
                    }
                ),
            };
        });
    for (let i = 0; i < createPerformanceEstimateRequests.length; i++) {
        const createPerformanceEstimateRequest =
            createPerformanceEstimateRequests[i];
        const response = await axiosInstance.post<PerformanceEstimateResponse>(
            `/api/v1/campaign-performance-estimates`,
            createPerformanceEstimateRequest,
            {
                headers: {
                    ContentType: 'application/json',
                },
            }
        );
        buyerCampaigns = substitutePerformanceEstimate(
            buyerCampaigns,
            (p) => {
                return {
                    ...p,
                    id: response.data.id,
                    networkName: response.data.networkName,
                    hours: response.data.hours,
                    client: response.data.client,
                    kpi: response.data.kpi,
                    kpiValue: response.data.kpiValue,
                    budgetPercentage: response.data.budgetPercentage,
                    estimateSchedules: response.data.timeEstimates.map((e) => {
                        return { time: e.time, estimate: e.estimate };
                    }),
                    objectState: ObjectState.Original,
                };
            },
            (c, p) =>
                c.id === response.data.campaignId &&
                p.networkName === response.data.networkName
        );
    }

    return buyerCampaigns;
};

const updatePerformanceEstimatesAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const updatedPerformanceEstimates =
        buyerCampaigns
            ?.flatMap((buyerCampaign) =>
                buyerCampaign.campaigns.flatMap(
                    (campaign) => campaign.performanceEstimates
                )
            )
            .filter(
                (campaign) => campaign.objectState === ObjectState.Modified
            ) ?? [];
    if (updatedPerformanceEstimates.length <= 0) {
        return buyerCampaigns;
    }

    const updateRequests = updatedPerformanceEstimates.map(
        async (performanceEstimate) => {
            const updatePerformanceEstimateRequest: UpdatePerformanceEstimateRequest =
                {
                    kpiValue: performanceEstimate.kpiValue,
                    budgetPercentage: performanceEstimate.budgetPercentage,
                    kpi: performanceEstimate.kpi,
                    client: performanceEstimate.client,
                    hours: performanceEstimate.hours,
                    daypart: performanceEstimate.daypart,
                    networkName: performanceEstimate.networkName,
                    timeEstimates: performanceEstimate.estimateSchedules.map(
                        (schedule) => {
                            return {
                                time: schedule.time,
                                estimate: schedule.estimate,
                            };
                        }
                    ),
                };
            return axiosInstance.put(
                `/api/v1/campaign-performance-estimates/${performanceEstimate.id}`,
                updatePerformanceEstimateRequest,
                {
                    headers: {
                        ContentType: 'application/json',
                    },
                }
            );
        }
    );
    await Promise.all(updateRequests);
    buyerCampaigns = substitutePerformanceEstimate(
        buyerCampaigns,
        (p) => {
            return { ...p, objectState: ObjectState.Original };
        },
        (_, p) => p.objectState === ObjectState.Modified
    );
    return buyerCampaigns;
};

const deletePerformanceEstimatesAsync = async (
    buyerCampaigns: BuyerCampaign[]
): Promise<BuyerCampaign[]> => {
    const deletedPerformanceEstimates =
        buyerCampaigns
            ?.flatMap((buyerCampaign) =>
                buyerCampaign.campaigns.flatMap(
                    (campaign) => campaign.performanceEstimates
                )
            )
            .filter(
                (campaign) => campaign.objectState === ObjectState.Deleted
            ) ?? [];
    if (deletedPerformanceEstimates.length <= 0) {
        return buyerCampaigns;
    }

    const deleteRequests = deletedPerformanceEstimates.map(
        async (performanceEsimate) => {
            return axiosInstance.delete(
                `/api/v1/campaign-performance-estimates/${performanceEsimate.id}`,

                {
                    headers: {
                        ContentType: 'application/json',
                    },
                }
            );
        }
    );
    await Promise.all(deleteRequests);
    buyerCampaigns = substituteCampaign(
        buyerCampaigns,
        (c) => {
            if (
                c.performanceEstimates.some(
                    (p) => p.objectState === ObjectState.Deleted
                )
            ) {
                return {
                    ...c,
                    performanceEstimates: c.performanceEstimates.filter(
                        (performanceEstimate) =>
                            performanceEstimate.objectState !==
                            ObjectState.Deleted
                    ),
                };
            }
            return c;
        },
        (c) =>
            c.performanceEstimates.some(
                (performanceEstimate) =>
                    performanceEstimate.objectState === ObjectState.Deleted
            )
    );
    return buyerCampaigns;
};
