import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authenticationReducer from '../features/authentication/authenticationSlice';
import sspSlice from '../features/ssp/slice-extensions/ssp-slice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        authentication: authenticationReducer,
        ssp: sspSlice,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
