import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const AdminHome = () => {
    return (
        <Box sx={{ my: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 2,
                }}>
                <Typography variant="h4" component="h1">
                    Admin
                </Typography>
            </Box>
            <Box sx={{ my: 4 }}>
                <ul>
                    <li>
                        <Link to={'user-accounts'}>
                            User Account Management
                        </Link>
                    </li>
                    <li>
                        <Link to={'seller'}>Input Management</Link>
                    </li>
                    <li>
                    <Link to={'optimization-logs'}>Data Archiving (log files)</Link></li>
                </ul>
            </Box>
        </Box>
    );
};

export default AdminHome;
