import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import axiosInstance from '../../features/axios/axiosInstance';
import { useEffect, useState } from 'react';
import { OptimizationDescriptionResponse } from '../../features/ssp/contracts/outcome/OptimizationDescriptionResponse';
import { OptimizationDescription } from './OptimizationDescription';
import axios, { HttpStatusCode } from 'axios';

const OptimizationsHistoryTable = () => {
    const [optimizationDescriptions, setOptimizationDescriptions] = useState<
        OptimizationDescription[]
    >([]);
    const getOptimizationDescriptions = async (): Promise<
        OptimizationDescription[]
    > => {
        try {
            const response = await axiosInstance.get<
                OptimizationDescriptionResponse[]
            >('/api/v1/optimization-descriptions', {
                headers: {
                    ContentType: 'application/json',
                },
            });
            return response.data.map((d) => {
                return {
                    id: d.id,
                    createdByName: d.createdByName,
                    dateCreated: new Date(d.dateCreated),
                };
            });
        } catch (error) {
            if (
                axios.isAxiosError(error) &&
                error.response?.status === HttpStatusCode.Unauthorized
            ) {
                return [];
            }
            return [];
        }
    };

    useEffect(() => {
        const fetchOptimizationDescriptions = async () => {
            const list: OptimizationDescription[] =
                await getOptimizationDescriptions();

            setOptimizationDescriptions(list);
        };

        fetchOptimizationDescriptions();
    }, []);

    return (
        <TableContainer sx={{ maxHeight: '70vh' }}>
            <Table
                stickyHeader
                aria-label="admin optimizations history"
                size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Created By</TableCell>

                        <TableCell>Date Created</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {optimizationDescriptions.map((description, i) => (
                        <TableRow key={i}>
                            <TableCell>{description.createdByName}</TableCell>

                            <TableCell>
                                {description.dateCreated.toLocaleString(
                                    'en-US'
                                )}
                            </TableCell>
                            <TableCell>
                                <Link
                                    to={'../optimization-log/' + description.id}
                                    relative="path">
                                    View
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OptimizationsHistoryTable;
