import { AvailabilityStatus } from './AvailabilityStatus';
import { DailyLog } from './DailyLog';
import { TimeExtensions } from './extensions/TimeExtensions';

export class DailyLogsExtensions {
    getAvailableTimes(dailyLogs: DailyLog): string[] {
        const allAvailableTimes = dailyLogs.logs
            .filter((log) => log.status === AvailabilityStatus.Available)
            .map((log) => log.hourBreak);
        const uniqueSortedTimes = new TimeExtensions().sort(allAvailableTimes);
        return uniqueSortedTimes;
    }
}
