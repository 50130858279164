import { SspState } from '../../SspState';
import { Campaign } from '../../Campaign';
import { BuyerCampaign } from '../../BuyerCampaign';

import { SspDefaultSettings } from '../../SspDefaultSettings';
import { TimeExtensions } from '../../extensions/TimeExtensions';
import { BuyersState } from '../../BuyersState';
import { IdGenerator } from '../../IdGenerator';
import { PerformanceEstimate } from '../../PerformanceEstimate';
import { ObjectState } from '../../../ObjectState';
import { StringsExtension } from '../../extensions/StringsExtension';
import { AvailableNetwork } from '../../AvailableNetwork';

const getBuyerCampaignsByBuyerIdPrivate = (
    state: SspState,
    buyerId: number
): BuyerCampaign | undefined => {
    return state.buyersState.buyerCampaigns.find(
        (buyer) => buyer.buyer.id === buyerId
    );
};

export const updatePerformanceEstimateTimesPrivate = (
    times: string[],
    networkName: string,
    buyerCampaigns: BuyerCampaign[]
) => {
    buyerCampaigns
        ?.flatMap((b) => b.campaigns || [])
        .flatMap((campaign) => campaign.performanceEstimates || [])
        .forEach((performanceEstimate) => {
            if (performanceEstimate.networkName !== networkName) {
                return;
            }

            const existingTimes =
                performanceEstimate.estimateSchedules?.map((s) => s.time) || [];

            const sortedTimes = new TimeExtensions().sort([
                ...times,
                ...existingTimes,
            ]);

            const updatedSchedules = sortedTimes.map((time) => {
                const schedule = performanceEstimate.estimateSchedules?.find(
                    (s) => s.time === time
                );
                if (typeof schedule !== 'undefined') {
                    return schedule;
                }
                return {
                    time,
                };
            });

            performanceEstimate.estimateSchedules = updatedSchedules;
        });
};

export const addBuyerCampaignPrivate = (
    state: SspState,
    buyerCampaign: BuyerCampaign
) => {
    if (!Array.isArray(state.buyersState.buyerCampaigns)) {
        state.buyersState.buyerCampaigns = [];
    }
    state.buyersState.buyerCampaigns.push(buyerCampaign);
};

export const addCampaignPrivate = (
    state: SspState,
    newCampaign: Campaign,
    buyerId: number
) => {
    const buyerCampaign = getBuyerCampaignsByBuyerIdPrivate(state, buyerId);
    if (buyerCampaign) {
        if (!Array.isArray(buyerCampaign.campaigns)) {
            buyerCampaign.campaigns = [];
        }
        buyerCampaign.campaigns.push(newCampaign);
    }
};

export const updateBuyerCampaignBuyerNamePrivate = (
    state: SspState,
    buyerId: number,
    newBuyerName: string
) => {
    const buyerCampaign = state.buyersState.buyerCampaigns.find(
        (buyer) => buyer.buyer.id === buyerId
    );
    if (typeof buyerCampaign !== 'undefined') {
        buyerCampaign.buyer.name = newBuyerName;
        if (buyerCampaign.buyer.objectState === ObjectState.Original) {
            buyerCampaign.buyer.objectState = ObjectState.Modified;
        }
    }
};

export const updateCampaignDetailsPrivate = (
    state: SspState,
    campaignId: number,
    name: string,
    brandName: string,
    productName: string,
    budget: number | undefined,
    minMinutesBetweenAds: number
) => {
    const campaign = state.buyersState.buyerCampaigns
        .flatMap((b) => b.campaigns)
        .find((c) => c.id === campaignId);
    if (typeof campaign !== 'undefined') {
        campaign.name = name;
        campaign.brandName = brandName;
        campaign.productName = productName;
        campaign.budget = budget && !Number.isNaN(budget) ? budget : 0;
        campaign.minMinutesBetweenAds = minMinutesBetweenAds;
        if (campaign.objectState === ObjectState.Original) {
            campaign.objectState = ObjectState.Modified;
        }
    }
};

export const removeCampaignFromPrivate = (
    state: SspState,
    campaignId: number
) => {
    state.buyersState.buyerCampaigns.forEach((buyerCampaign) => {
        buyerCampaign.campaigns = buyerCampaign.campaigns.filter(
            (campaign) =>
                !(
                    campaign.id === campaignId &&
                    campaign.objectState === ObjectState.Added
                )
        );
        const campaign = buyerCampaign.campaigns.find(
            (campaign) => campaign.id === campaignId
        );
        if (typeof campaign !== 'undefined') {
            campaign.objectState = ObjectState.Deleted;
            campaign.performanceEstimates?.forEach((performanceEstimate) => {
                performanceEstimate.objectState = ObjectState.Deleted;
            });
        }
    });
};

export const removeBuyerPrivate = (state: SspState, buyerId: number) => {
    state.buyersState.buyerCampaigns = state.buyersState.buyerCampaigns.filter(
        (buyerCampaign) =>
            !(
                buyerCampaign.buyer.id === buyerId &&
                buyerCampaign.buyer.objectState === ObjectState.Added
            )
    );
    state.buyersState.buyerCampaigns.forEach((buyerCampaign) => {
        if (buyerCampaign.buyer.id === buyerId) {
            buyerCampaign.buyer.objectState = ObjectState.Deleted;
        }
    });
};

export const addPerformanceEstimatePrivate = (
    state: BuyersState,
    campaignId: number,
    networkName: string
) => {
    const buyer = state.buyerCampaigns.find(
        (b) =>
            typeof b?.campaigns.find((c) => c.id === campaignId) !== 'undefined'
    );
    if (typeof buyer !== 'undefined') {
        const campaign = buyer?.campaigns?.find((c) => c.id === campaignId);
        if (typeof campaign !== 'undefined') {
            const stringsExtension = new StringsExtension();
            const times = SspDefaultSettings.maxTimes;
            campaign.performanceEstimates.forEach((performanceEstimate) => {
                if (
                    stringsExtension.stringsEqual(
                        performanceEstimate.networkName,
                        networkName
                    )
                ) {
                    if (
                        performanceEstimate.objectState === ObjectState.Deleted
                    ) {
                        performanceEstimate = {
                            id: performanceEstimate.id,
                            networkName: networkName,
                            daypart: 'Daytime',
                            hours: '6am - 3pm',
                            client: 'xyz',
                            kpi: 'Responses',
                            kpiValue: 100,
                            budgetPercentage: 100,
                            objectState: ObjectState.Modified,
                            estimateSchedules: times.map((t) => {
                                return { time: t, estimate: 30 };
                            }),
                        };
                        return;
                    }
                }
            });

            campaign.performanceEstimates.push({
                id: IdGenerator.generate(),
                networkName: networkName,
                daypart: 'Daytime',
                hours: '6am - 3pm',
                client: 'xyz',
                kpi: 'Responses',
                kpiValue: 100,
                budgetPercentage: 100,
                objectState: ObjectState.Added,
                estimateSchedules: times.map((t) => {
                    return { time: t, estimate: 30 };
                }),
            });
        }
    }
};

export const setPerformanceEstimatePrivate = (
    state: SspState,
    performanceEstimateId: number,
    time: string,
    estimate?: string
) => {
    const estimateNumber = isNaN(parseFloat(estimate ?? ''))
        ? undefined
        : parseFloat(estimate ?? '');
    const buyer = getBuyerCampaignByPerformanceEstimateIdPrivate(
        state,
        performanceEstimateId
    );
    if (typeof buyer !== 'undefined') {
        const performanceEstimate = buyer?.campaigns
            ?.find(
                (c) =>
                    typeof c.performanceEstimates.find(
                        (p) => p.id === performanceEstimateId
                    ) !== 'undefined'
            )
            ?.performanceEstimates?.find((p) => p.id === performanceEstimateId);
        if (typeof performanceEstimate !== 'undefined') {
            const scheduleTime = performanceEstimate.estimateSchedules.find(
                (schedule) => schedule.time === time
            );

            if (typeof scheduleTime !== 'undefined') {
                scheduleTime.estimate = estimateNumber;
            }
            if (performanceEstimate.objectState === ObjectState.Original) {
                performanceEstimate.objectState = ObjectState.Modified;
            }
        }
    }
};

export const setPerformanceEstimateDetailsPrivate = (
    state: SspState,
    performanceEstimateId: number,
    networkName: string,
    daypart: string,
    hours: string,
    client: string,
    kpi: string,
    kpiValue: number | undefined,
    budgetPercentage: number
) => {
    const buyer = getBuyerCampaignByPerformanceEstimateIdPrivate(
        state,
        performanceEstimateId
    );
    if (typeof buyer !== 'undefined') {
        const performanceEstimate = buyer?.campaigns
            ?.find(
                (c) =>
                    typeof c.performanceEstimates.find(
                        (p) => p.id === performanceEstimateId
                    ) !== 'undefined'
            )
            ?.performanceEstimates?.find((p) => p.id === performanceEstimateId);
        if (typeof performanceEstimate !== 'undefined') {
            performanceEstimate.networkName = networkName;
            performanceEstimate.daypart = daypart;
            performanceEstimate.hours = hours;
            performanceEstimate.client = client;
            performanceEstimate.kpi = kpi;
            performanceEstimate.kpiValue = kpiValue;
            performanceEstimate.budgetPercentage = budgetPercentage;
            if (performanceEstimate.objectState === ObjectState.Original) {
                performanceEstimate.objectState = ObjectState.Modified;
            }
        }
    }
};

export const importCampaignPerformanceEstimatePrivate = (
    state: SspState,
    campaignId: number,
    uploadedPerformanceEstimates: PerformanceEstimate[],
    networkNames: string[]
) => {
    const buyer = getBuyerCampaiongByCampaignIdPrivate(state, campaignId);
    if (typeof buyer !== 'undefined') {
        const campaign = buyer?.campaigns?.find((c) => c.id === campaignId);
        if (typeof campaign !== 'undefined') {
            const distinctUploadedPerformanceEstimates =
                getDistinctPerformanceEstimates(uploadedPerformanceEstimates);
            const stringsExtension = new StringsExtension();
            distinctUploadedPerformanceEstimates.forEach(
                (uploadedPerformanceEstimate) => {
                    networkNames.forEach((currentNetworkName) => {
                        const existingPerformanceEstimate =
                            findPerformanceEstimateByNetworkNamePrivate(
                                campaign,
                                uploadedPerformanceEstimate.networkName,
                                currentNetworkName
                            );
                        if (
                            typeof existingPerformanceEstimate !== 'undefined'
                        ) {
                            updatePerformanceEstimatePrivate(
                                existingPerformanceEstimate,
                                uploadedPerformanceEstimate
                            );
                        } else {
                            if (
                                stringsExtension.stringsEqual(
                                    currentNetworkName,
                                    uploadedPerformanceEstimate.networkName
                                )
                            ) {
                                campaign.performanceEstimates.push(
                                    uploadedPerformanceEstimate
                                );
                            }
                        }
                    });
                }
            );
        }
    }
};

export const removePerformanceEstimatePrivate = (
    state: SspState,
    performanceEstimateId: number
) => {
    const buyer = getBuyerCampaignByPerformanceEstimateIdPrivate(
        state,
        performanceEstimateId
    );
    if (typeof buyer !== 'undefined') {
        const campaign = buyer?.campaigns?.find(
            (c) =>
                typeof c.performanceEstimates.find(
                    (p) => p.id === performanceEstimateId
                ) !== 'undefined'
        );
        if (typeof campaign !== 'undefined') {
            campaign.performanceEstimates =
                campaign.performanceEstimates?.filter(
                    (p) =>
                        !(
                            p.id === performanceEstimateId &&
                            p.objectState === ObjectState.Added
                        )
                );
            const performanceEstimate = campaign.performanceEstimates.find(
                (performanceEstimate) =>
                    performanceEstimate.id === performanceEstimateId
            );
            if (typeof performanceEstimate !== 'undefined') {
                performanceEstimate.objectState = ObjectState.Deleted;
            }
        }
    }
};

export const removePerformanceEstimateByCampaignIdAndNetworkNamePrivate = (
    buyerCampaigns: BuyerCampaign[],
    campaignId: number,
    networkName: string
) => {
    const campaign = buyerCampaigns
        .flatMap((b) => b.campaigns)
        .find((c) => c.id === campaignId);
    if (typeof campaign !== 'undefined') {
        const stringsExtension = new StringsExtension();
        const performanceEstimate = campaign.performanceEstimates?.find(
            (performanceEstimate) =>
                stringsExtension.stringsEqual(
                    performanceEstimate.networkName,
                    networkName
                ) && performanceEstimate.objectState !== ObjectState.Deleted
        );
        if (typeof performanceEstimate !== 'undefined') {
            if (performanceEstimate.objectState === ObjectState.Added) {
                campaign.performanceEstimates =
                    campaign.performanceEstimates.filter(
                        (campaignPerformanceEstimate) =>
                            !(
                                campaignPerformanceEstimate.id ===
                                    performanceEstimate.id &&
                                campaignPerformanceEstimate.objectState ===
                                    ObjectState.Added
                            )
                    );
            } else {
                performanceEstimate.objectState = ObjectState.Deleted;
            }
        }
    }
};

const getBuyerCampaignByPerformanceEstimateIdPrivate = (
    state: SspState,
    performanceEstimateId: number
): BuyerCampaign | undefined => {
    return state.buyersState.buyerCampaigns.find(
        (b) =>
            typeof b?.campaigns.find(
                (c) =>
                    typeof c.performanceEstimates.find(
                        (p) => p.id === performanceEstimateId
                    ) !== 'undefined'
            ) !== 'undefined'
    );
};

const getBuyerCampaiongByCampaignIdPrivate = (
    state: SspState,
    campaignId: number
): BuyerCampaign | undefined => {
    return state.buyersState.buyerCampaigns.find(
        (b) =>
            typeof b?.campaigns.find((c) => c.id === campaignId) !== 'undefined'
    );
};

const getDistinctPerformanceEstimates = (
    performanceEstimates: PerformanceEstimate[]
) => {
    return performanceEstimates.filter(
        (performanceEstimate, index, self) =>
            index ===
            self.findIndex(
                (t) => t.networkName === performanceEstimate.networkName
            )
    );
};

const updatePerformanceEstimatePrivate = (
    performanceEstimate: PerformanceEstimate,
    updatedPerformanceEstimate: PerformanceEstimate
) => {
    performanceEstimate.estimateSchedules =
        updatedPerformanceEstimate.estimateSchedules;
    performanceEstimate.kpi = updatedPerformanceEstimate.kpi;
    performanceEstimate.kpiValue = updatedPerformanceEstimate.kpiValue;
    performanceEstimate.daypart = updatedPerformanceEstimate.daypart;
    performanceEstimate.hours = updatedPerformanceEstimate.hours;
    performanceEstimate.client = updatedPerformanceEstimate.client;
    performanceEstimate.networkName = updatedPerformanceEstimate.networkName;
    if (performanceEstimate.objectState === ObjectState.Original) {
        performanceEstimate.objectState = ObjectState.Modified;
    }
};

const findPerformanceEstimateByNetworkNamePrivate = (
    campain: Campaign,
    networkName: string,
    currentNetworkName: string
): PerformanceEstimate | undefined => {
    const stringsExtension = new StringsExtension();
    return campain.performanceEstimates.find(
        (estimate) =>
            stringsExtension.stringsEqual(estimate.networkName, networkName) &&
            stringsExtension.stringsEqual(
                estimate.networkName,
                currentNetworkName
            )
    );
};

export const setBuyerCampaingNetworksPrivate = (
    buyerCampaigns: BuyerCampaign[],
    availableNetworks: AvailableNetwork[],
    campaignId: number
) => {
    const campaign = buyerCampaigns
        .flatMap((b) => b.campaigns)
        .find((c) => c.id === campaignId);
    if (typeof campaign !== 'undefined') {
        campaign.currentNetworks = availableNetworks;
    }
};
