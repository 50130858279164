import { UserAccountResponse } from '../contracts/UserAccountResponse';
import { UserAccount } from '../UserAccount';
import { UserRolesResponseMapper } from './UserRolesResponseMapper';

export class UserAccountResponseMapper {
    private userRolesResponseMapper = new UserRolesResponseMapper();

    mapAccountResponseToUserAccount = (
        userAccountResponse: UserAccountResponse
    ): UserAccount => {
        return {
            id: userAccountResponse.id,
            role: this.userRolesResponseMapper.mapUserRolesResponseToUserRoles(
                userAccountResponse.role
            ),
            email: userAccountResponse.email,
            displayName: userAccountResponse.displayName,
            isActive: userAccountResponse.isActive,
            dateModified: new Date(userAccountResponse.dateModified),
            dateCreated: new Date(userAccountResponse.dateCreated),
            isEdited: false,
        };
    };
}
