import axios, { HttpStatusCode } from 'axios';
import { logoutAsync } from '../authentication/authenticationSlice';
import { API_BASE_URL } from '../../app/config';
import { store } from '../../app/store';

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
    (requestConfig) => {
        const token = store.getState().authentication.authorizationToken;
        if (token) {
            requestConfig.headers['Authorization'] = `Bearer ${token}`;
        }

        return requestConfig;
    },
    async (error) => {
        const { response } = error;
        if (response.status === HttpStatusCode.Unauthorized) {
            store.dispatch(logoutAsync());
        }
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { response } = error;
        if (response.status === HttpStatusCode.Unauthorized) {
            store.dispatch(logoutAsync());
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
