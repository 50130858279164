import { ObjectState } from '../../ObjectState';
import { AdvertisedCampaign } from '../AdvertisedCampaign';
import { AvailabilityStatus } from '../AvailabilityStatus';
import { PriceFloor } from '../PriceFloor';
import { DailyLogEntry } from '../DailyLogEntry';
import { DailyLogType } from '../DailyLogType';
import { DailyLog } from '../DailyLog';
import { Daypart } from '../Daypart';
import { PriceFloorSchedule } from '../PriceFloorSchedule';
import { Seller } from '../Seller';

import { DailyLogEntryResponse } from '../contracts/seller/DailyLogEntryResponse';
import { DailyLogResponse } from '../contracts/seller/DailyLogResponse';
import { PriceFloorResponse } from '../contracts/seller/PriceFloorResponse';
import { PriceFloorScheduleResponse } from '../contracts/seller/PriceFloorScheduleResponse';
import { SellerResponse } from '../contracts/seller/SellerResponse';

import { AvailabilityStatusResponseMapper } from './AvailabilityStatusResponseMapper';
import { DailyLogTypeResponseMapper } from './DailyLogTypeResponseMapper';
import { DaypartResponseMapper } from './DaypartResponseMapper';
import { CreatePriceFloorScheduleResponse } from '../contracts/seller/CreatePriceFloorScheduleResponse';
import { CreatePriceFloorResponse } from '../contracts/seller/CreatePriceFloorResponse';
import { UpdatePriceFloorResponse } from '../contracts/seller/UpdatePriceFloorResponse';
import { UpdatePriceFloorScheduleResponse } from '../contracts/seller/UpdatePriceFloorScheduleResponse';
import { CampaignBid } from '../CampaignBid';
import { CampaignBidResponse } from '../contracts/seller/CampaignBidResponse';
import { TimeExtensions } from '../extensions/TimeExtensions';

export class SellerResponseMapper {
    private daypartResponseMapper = new DaypartResponseMapper();
    private enumAvailabilityStatusResponseMapper =
        new AvailabilityStatusResponseMapper();
    private dailyLogTypeResponseMapper = new DailyLogTypeResponseMapper();

    toSellerModel = (sellerResponse: SellerResponse): Seller => {
        const id: number = sellerResponse.id;
        const name: string = sellerResponse.name;
        const objectState = ObjectState.Original;
        return {
            id: id,
            name: name,
            objectState: objectState,
        };
    };

    toCampaignBidModel(campaignBidResponse: CampaignBidResponse): CampaignBid {
        return {
            id: campaignBidResponse.id,
            brandName: campaignBidResponse.brandName,
            buyerDisplayName: campaignBidResponse.buyerDisplayName,
            name: campaignBidResponse.name,
            productName: campaignBidResponse.productName,
            minMinutesBetweenAds: campaignBidResponse.minSecondsBetweenAds / 60,
            networkBudgets: campaignBidResponse.networkBudgets,
        };
    }

    toPriceFloorsModel = (
        priceFloorsResponses: PriceFloorResponse[],
        campaignBids: CampaignBid[]
    ): PriceFloor[] => {
        return priceFloorsResponses.map((priceFloorsResponse) =>
            this.toPriceFloorModel(priceFloorsResponse, campaignBids)
        );
    };

    toDailyLogsModel = (dailyLogsResponses: DailyLogResponse[]): DailyLog[] => {
        return dailyLogsResponses.map((dailyLogsResponse) =>
            this.toDailyLogModel(dailyLogsResponse)
        );
    };

    toDailyLogModel = (dailyLogResponse: DailyLogResponse): DailyLog => {
        const id: number = dailyLogResponse.id;
        const networkName: string = dailyLogResponse.networkName;
        const daypart = this.daypartResponseMapper.mapEnumDaypartResponse(
            dailyLogResponse.daypart
        );
        const hoursStart: string = dailyLogResponse.hoursStart;
        const hoursEnd: string = dailyLogResponse.hoursEnd;
        const dateStart: string = dailyLogResponse.dateStart;
        const dateEnd: string = dailyLogResponse.dateEnd;
        const logs: DailyLogEntry[] = dailyLogResponse.logs.map((log) =>
            this.toDailyLogEntryModel(log)
        );
        return {
            id: id,
            networkName: networkName,
            daypart: daypart,
            hoursStart: hoursStart,
            hoursEnd: hoursEnd,
            dateStart: dateStart,
            dateEnd: dateEnd,
            logs: logs,
            objectState: ObjectState.Original,
        };
    };

    toDailyLogResponses = (
        createDailyLogsResponses: DailyLogResponse[]
    ): DailyLog[] => {
        return createDailyLogsResponses.map((createDailyLogsResponse) =>
            this.toDailyLogModel(createDailyLogsResponse)
        );
    };

    toDailyLogEntryResponse = (
        addDailyLogReponse: DailyLogEntryResponse
    ): DailyLogEntry => {
        const date: string = addDailyLogReponse.date;
        const hourBreak: string = addDailyLogReponse.hourBreak;
        const no: number = addDailyLogReponse.no;
        const logType =
            this.dailyLogTypeResponseMapper.mapEnumDailyLogTypeResponse(
                addDailyLogReponse.logType
            );
        const offset: number = addDailyLogReponse.offset;
        const length: number = addDailyLogReponse.length;
        const name: string = addDailyLogReponse.name;
        const inventoryCode: string = addDailyLogReponse.inventoryCode;
        const status =
            this.enumAvailabilityStatusResponseMapper.mapEnumAvailabilityStatusResponse(
                addDailyLogReponse.status
            );
        return {
            date: date,
            hourBreak: hourBreak,
            no: no,
            logType: logType,
            offset: offset,
            length: length,
            name: name,
            inventoryCode: inventoryCode,
            status: status,
        };
    };

    toDailyLogModels = (dailyLogsResponse: DailyLogResponse[]): DailyLog[] => {
        return dailyLogsResponse.map((updateDailyLogsResponse) =>
            this.toDailyLogModel(updateDailyLogsResponse)
        );
    };

    private toPriceFloorScheduleModel = (
        priceFloorScheduleResponse: PriceFloorScheduleResponse
    ): PriceFloorSchedule => {
        const time: string = priceFloorScheduleResponse.time;
        const price: number = priceFloorScheduleResponse.price;
        const isAvailable: boolean = priceFloorScheduleResponse.isAvailable;
        return {
            time: time,
            price: price,
            isAvailable: isAvailable,
        };
    };

    private toPriceFloorModel = (
        priceFloorResponse: PriceFloorResponse,
        campaignBids: CampaignBid[]
    ): PriceFloor => {
        const id: number = priceFloorResponse.id;
        const networkName: string = priceFloorResponse.networkName;
        const daypart: Daypart =
            this.daypartResponseMapper.mapEnumDaypartResponse(
                priceFloorResponse.daypart
            );
        const hoursStart: string = priceFloorResponse.hoursStart;
        const hoursEnd: string = priceFloorResponse.hoursEnd;
        const campaignBid = campaignBids.find(
            (campaign) =>
                campaign.id === priceFloorResponse.advertisedCampaignId
        );
        const advertisedCampaign: AdvertisedCampaign = {
            id: campaignBid?.id ?? priceFloorResponse.id,
            brandName: campaignBid?.brandName ?? '',
            buyerDisplayName: campaignBid?.buyerDisplayName ?? '',
            name: campaignBid?.name ?? '',
            productName: campaignBid?.productName ?? '',
            minMinutesBetweenAds: campaignBid?.minMinutesBetweenAds,
            budget: campaignBid?.networkBudgets[priceFloorResponse.networkName],
        };
        const timeExtensions = new TimeExtensions();
        const priceFloorSchedules: PriceFloorSchedule[] =
            priceFloorResponse.priceFloorSchedules
                .map(this.toPriceFloorScheduleModel)
                .sort((a, b) => timeExtensions.compareTimes(a.time, b.time));
        return {
            id: id,
            networkName: networkName,
            daypart: daypart,
            hoursStart: hoursStart,
            hoursEnd: hoursEnd,
            advertisedCampaign: advertisedCampaign,
            priceFloorSchedules: priceFloorSchedules,
            objectState: ObjectState.Original,
        };
    };

    private toDailyLogEntryModel = (
        dailyLogResponse: DailyLogEntryResponse
    ): DailyLogEntry => {
        const date: string = dailyLogResponse.date;
        const hourBreak: string = dailyLogResponse.hourBreak;
        const no: number = dailyLogResponse.no;
        const logType: DailyLogType =
            this.dailyLogTypeResponseMapper.mapEnumDailyLogTypeResponse(
                dailyLogResponse.logType
            );
        const offset: number = dailyLogResponse.offset;
        const length: number = dailyLogResponse.length;
        const name: string = dailyLogResponse.name;
        const inventoryCode: string = dailyLogResponse.inventoryCode;
        const status: AvailabilityStatus =
            this.enumAvailabilityStatusResponseMapper.mapEnumAvailabilityStatusResponse(
                dailyLogResponse.status
            );
        return {
            date: date,
            hourBreak: hourBreak,
            no: no,
            logType: logType,
            offset: offset,
            length: length,
            name: name,
            inventoryCode: inventoryCode,
            status: status,
        };
    };

    mapCreatePriceFloorScheduleResponseToModel = (
        priceFloorScheduleResponse: CreatePriceFloorScheduleResponse
    ): PriceFloorSchedule => {
        const time: string = priceFloorScheduleResponse.time;
        const price: number = priceFloorScheduleResponse.price;
        const isAvailable: boolean = priceFloorScheduleResponse.isAvailable;
        return {
            time: time,
            price: price,
            isAvailable: isAvailable,
        };
    };

    mapUpdatePriceFloorScheduleResponseToModel = (
        priceFloorScheduleResponse: UpdatePriceFloorScheduleResponse
    ): PriceFloorSchedule => {
        const time: string = priceFloorScheduleResponse.time;
        const price: number = priceFloorScheduleResponse.price;
        const isAvailable: boolean = priceFloorScheduleResponse.isAvailable;
        return {
            time: time,
            price: price,
            isAvailable: isAvailable,
        };
    };

    mapCreatePriceFloorResponseToPriceFloor = (
        createPriceFloorResponse: CreatePriceFloorResponse,
        campaignBids: CampaignBid[]
    ): PriceFloor => {
        const id: number = createPriceFloorResponse.id;
        const networkName = createPriceFloorResponse.networkName;
        const daypart = this.daypartResponseMapper.mapEnumDaypartResponse(
            createPriceFloorResponse.daypart
        );
        const hoursStart = createPriceFloorResponse.hoursStart;
        const hoursEnd = createPriceFloorResponse.hoursEnd;
        const campaignBid = campaignBids.find(
            (campaign) =>
                campaign.id === createPriceFloorResponse.advertisedCampaignId
        );
        const advertisedCampaign: AdvertisedCampaign = {
            id: campaignBid?.id ?? createPriceFloorResponse.id,
            brandName: campaignBid?.brandName ?? '',
            buyerDisplayName: campaignBid?.buyerDisplayName ?? '',
            name: campaignBid?.name ?? '',
            productName: campaignBid?.productName ?? '',
            minMinutesBetweenAds: campaignBid?.minMinutesBetweenAds,
            budget: campaignBid?.networkBudgets[
                createPriceFloorResponse.networkName
            ],
        };
        const priceFloorSchedules =
            createPriceFloorResponse.priceFloorSchedules.map(
                this.mapCreatePriceFloorScheduleResponseToModel
            );
        return {
            id: id,
            networkName: networkName,
            daypart: daypart,
            hoursStart: hoursStart,
            advertisedCampaign: advertisedCampaign,
            hoursEnd: hoursEnd,
            priceFloorSchedules: priceFloorSchedules,
            objectState: ObjectState.Original,
        };
    };

    mapUpdatePriceFloorResponseToPriceFloor = (
        updatePriceFloorResponse: UpdatePriceFloorResponse,
        campaignBids: CampaignBid[]
    ): PriceFloor => {
        const id: number = updatePriceFloorResponse.id;
        const networkName = updatePriceFloorResponse.networkName;
        const daypart = this.daypartResponseMapper.mapEnumDaypartResponse(
            updatePriceFloorResponse.daypart
        );
        const hoursStart = updatePriceFloorResponse.hoursStart;
        const hoursEnd = updatePriceFloorResponse.hoursEnd;
        const campaignBid = campaignBids.find(
            (campaign) =>
                campaign.id === updatePriceFloorResponse.advertisedCampaignId
        );
        const advertisedCampaign: AdvertisedCampaign = {
            id: campaignBid?.id ?? updatePriceFloorResponse.id,
            brandName: campaignBid?.brandName ?? '',
            buyerDisplayName: campaignBid?.buyerDisplayName ?? '',
            name: campaignBid?.name ?? '',
            productName: campaignBid?.productName ?? '',
            minMinutesBetweenAds: campaignBid?.minMinutesBetweenAds,
            budget: campaignBid?.networkBudgets[
                updatePriceFloorResponse.networkName
            ],
        };
        const priceFloorSchedules =
            updatePriceFloorResponse.priceFloorSchedules.map(
                this.mapUpdatePriceFloorScheduleResponseToModel
            );
        return {
            id: id,
            networkName: networkName,
            advertisedCampaign: advertisedCampaign,
            priceFloorSchedules: priceFloorSchedules,
            hoursEnd: hoursEnd,
            hoursStart: hoursStart,
            daypart: daypart,
            objectState: ObjectState.Original,
        };
    };
}
