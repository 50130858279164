import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../axios/axiosInstance';
import { PerformanceEstimateResponse } from '../../contracts/buyers/PerformanceEstimateResponse';
import { CampaignResponse } from '../../contracts/buyers/CampaignResponse';
import { BuyersState } from '../../BuyersState';
import { BuyerResponseMapper } from '../../mappers/BuyerResponseMapper';
import { BuyerCampaign } from '../../BuyerCampaign';
import { BuyerResponse } from '../../contracts/buyers/BuyerResponse';
import { AvailableNetworkResponse } from '../../contracts/buyers/AvailableNetworkResponse';
import { AvailableNetwork } from '../../AvailableNetwork';
import { StringsExtension } from '../../extensions/StringsExtension';

export const loadBuyersStateAsync = createAsyncThunk(
    'ssp/loadBuyersState',
    async (userId: string | undefined, thunkApi) => {
        const buyersResponse = await loadBuyersAsync(userId);
        let buyerState: BuyersState = {
            buyerCampaigns: [],
            availableNetworks: [],
        };
        if (typeof buyersResponse !== 'undefined') {
            const availableNetworksResponse = await loadAvailableNetworksAsync(
                userId
            );
            const availableNetworks: AvailableNetwork[] =
                availableNetworksResponse.map((availableNetwork) => ({
                    id: availableNetwork.id,
                    networkName: availableNetwork.networkName,
                }));

            buyerState.availableNetworks = availableNetworks;

            const campaignsResponse = await loadCampaignsAsync(userId);
            const buyerResponseMapper = new BuyerResponseMapper();

            const campaignPerformanceEstimatesResponse =
                await loadCampaignsPerformanceEstimatesAsync(userId);

            buyersResponse.forEach(async (buyer) => {
                if (typeof campaignsResponse !== 'undefined') {
                    let buyerCampaignsResponse =
                        getAllCampaignsByBuyerIdPrivate(
                            buyer.id,
                            campaignsResponse
                        );

                    const buyerCampaign: BuyerCampaign = {
                        buyer: buyerResponseMapper.mapBuyerResponse(buyer),
                        campaigns:
                            buyerResponseMapper.mapCampaignsResponse(
                                buyerCampaignsResponse,
                                campaignPerformanceEstimatesResponse
                            ) ?? [],
                    };
                    buyerState.buyerCampaigns.push(buyerCampaign);
                }
            });
        }
        return buyerState;
    }
);

export const loadBuyersAsync = async (userId: string | undefined) => {
    const response = await axiosInstance.get<BuyerResponse[]>(
        `/api/v1/buyers` +
            new StringsExtension().returnIfNotEmpty(
                userId,
                `?userId=${userId}`
            ),
        {
            headers: {
                ContentType: 'application/json',
            },
        }
    );

    return response.data;
};

export const loadCampaignsAsync = async (userId: string | undefined) => {
    const response = await axiosInstance.get<CampaignResponse[]>(
        `/api/v1/campaigns` +
            new StringsExtension().returnIfNotEmpty(
                userId,
                `?userId=${userId}`
            ),
        {
            headers: {
                ContentType: 'application/json',
            },
        }
    );

    return response.data;
};

export const loadCampaignsPerformanceEstimatesAsync = async (
    userId: string | undefined
) => {
    const response = await axiosInstance.get<PerformanceEstimateResponse[]>(
        `/api/v1/campaign-performance-estimates` +
            new StringsExtension().returnIfNotEmpty(
                userId,
                `?userId=${userId}`
            ),
        {
            headers: {
                ContentType: 'application/json',
            },
        }
    );

    return response.data;
};

export const loadAvailableNetworksAsync = async (
    userId: string | undefined
): Promise<AvailableNetworkResponse[]> => {
    const response = await axiosInstance.get<AvailableNetworkResponse[]>(
        `/api/v1/available-networks` +
            new StringsExtension().returnIfNotEmpty(
                userId,
                `?userId=${userId}`
            ),
        {
            headers: {
                ContentType: 'application/json',
            },
        }
    );

    return response.data;
};

const getAllCampaignsByBuyerIdPrivate = (
    buyerId: number,
    campaignsResponses: CampaignResponse[]
): CampaignResponse[] => {
    const result: CampaignResponse[] = campaignsResponses.filter(
        (campaign) => campaign.ownerId === buyerId
    );

    return result;
};

export const loadAvailableNetworksAsyncThunk = createAsyncThunk(
    'ssp/loadAvailableNetworks',
    async (userId: string | undefined) => {
        const availableNetworksResponse = await loadAvailableNetworksAsync(
            userId
        );
        const availableNetworks: AvailableNetwork[] =
            availableNetworksResponse.map((availableNetwork) => ({
                id: availableNetwork.id,
                networkName: availableNetwork.networkName,
            }));
        return availableNetworks;
    }
);
