import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { authenticate } from './authenticationApi';
import { AuthenticationState } from './AuthenticationState';
import localforage from 'localforage';
import { jwtDecode } from 'jwt-decode';

const initialState: AuthenticationState = {
    isAuthenticated: false,
    authorizationToken: '',
    userId: 0,
    userRole: '',
    email: '',
    status: 'idle',
    initializationStatus: 'not-initialized',
};
const authorizationTokenKey = 'x-authorization-token';
export const authenticateAsync = createAsyncThunk(
    'authentication/authenticate',
    async (credentials: { email: string; password: string }) => {
        const response = await authenticate(
            credentials.email,
            credentials.password
        );

        await localforage.setItem(authorizationTokenKey, response.data);

        return response.data;
    }
);

export const loadInitialAuthenticationStateAsync = createAsyncThunk(
    'authentication/loadInitialAuthenticationState',
    async () => {
        const authorizationToken: string | null = await localforage.getItem(
            authorizationTokenKey
        );

        return authorizationToken;
    }
);

export const logoutAsync = createAsyncThunk(
    'authentication/logout',
    async () => {
        await localforage.removeItem(authorizationTokenKey);
    }
);

const setStateToDefault = (state: AuthenticationState) => {
    state = { ...initialState };
};

const setAuthorizationToken = (
    token: string | null,
    state: AuthenticationState
) => {
    state.authorizationToken = token ?? '';
    state.isAuthenticated =
        typeof state.authorizationToken === 'string' &&
        state.authorizationToken.length > 0;
    if (state.isAuthenticated) {
        try {
            const user = jwtDecode(state.authorizationToken) as any;
            state.userRole =
                user[
                    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                ];
            state.email =
                user[
                    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email'
                ];
            state.userId = parseInt(
                user[
                    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
                ]
            );
        } catch {
            state.isAuthenticated = false;
        }
    }
    state.status = 'idle';
    state.initializationStatus = 'initialized';
};

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(authenticateAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(authenticateAsync.fulfilled, (state, action) => {
                setAuthorizationToken(action.payload, state);
                setStateToDefault(state);
            })
            .addCase(authenticateAsync.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(logoutAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(logoutAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.isAuthenticated = false;
                state.authorizationToken = '';
            })
            .addCase(logoutAsync.rejected, (state) => {
                state.status = 'failed';
            })

            .addCase(loadInitialAuthenticationStateAsync.pending, (state) => {
                state.initializationStatus = 'initializing';
                state.status = 'loading';
            })
            .addCase(
                loadInitialAuthenticationStateAsync.fulfilled,
                (state, action) => {
                    setAuthorizationToken(action.payload, state);
                }
            )
            .addCase(loadInitialAuthenticationStateAsync.rejected, (state) => {
                state.status = 'failed';
                state.initializationStatus = 'initialized';
            });
    },
});

export const selectIsAuthenticated = (state: RootState) =>
    state.authentication.isAuthenticated;

export const selectInitializationStatus = (state: RootState) =>
    state.authentication.initializationStatus;

export const selectAuthorizationToken = (state: RootState) =>
    state.authentication.authorizationToken;

export const selectAuthenticationStatus = (state: RootState) =>
    state.authentication.status;

export const selectAuthenticatedRole = (state: RootState) =>
    state.authentication.userRole;

export default authenticationSlice.reducer;
